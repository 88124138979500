import { useEffect, useMemo, useState } from "react";

import { Column } from "@material-table/core";
import { useTranslation } from "react-i18next";
import { Switch, Route, Redirect } from "react-router-dom";
import { AppBar, Box, createStyles, Grid, makeStyles, Tab, Tabs, Theme, Typography, Paper } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import i18n from "../../../i18n";
import { it, enGB } from "date-fns/locale";

import { daysInMonth, getDateYYYYMMDD } from '../../../utils/utilfunctions';
import { PDFExtraData } from '../../../models/Utils';
import { MonthsList, TurniColors, WeekList } from "../../../utils/utildata";
import { notFoundPath, presenzeUOPath } from "../../../utils/utilconst";

import ReadOnlyMaterialTable from '../tables/ReadOnly/ReadOnlyMaterialTable';
import SimpleIdSelects from "../selects/SimpleIdSelects";

import { Presenza, PresenzaKeys } from '../../../models/Presenze';
import { Fields } from "../../../models/Fields";
import {
  allUOEsternaFields,
  allPresenzeUOFields,
  allRiepilogoFields,
  allPrestazioneEsterneUOFields,
  PresenzeUO,
} from "../../../models/PresenzeUO";

import {
  fetchAllBetweenDates,
  reset as resetPresenze
} from "../../../store/slices/presenzeSlice";
import {
  lookup as fetchStruttureLookup,
  reset as resetStrutture
} from "../../../store/slices/struttureSlice";
import {
  lookupFiltered as fetchDipartimentiLookup,
  cleanLookup as resetDipartimentiLookup,
  reset as resetDipartimentiFiltered
} from "../../../store/slices/dipartimentiFilteredSlice";
import {
  lookupFiltered as fetchUnitaOperativeLookup,
  cleanLookup as resetUOLookup,
  reset as resetUnitaOperativeFiltered
} from "../../../store/slices/unitaOperativeFilteredSlice";
import TabPanel from "../tabs/TabPanel/TabPanel";
import { calculateMTableHeight } from "../../../utils/styleconst";
import { getDaysInMonth } from "date-fns";

const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    appbar: {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    },
    indicator: {
      backgroundColor: "#fff",
    },
    legendTopOffset: {
      marginTop: -75,
    },
    maxWidth: {
      maxWidth: 'calc(' + document.body.clientWidth + 'px - 328px)',
    }
  }),
);

enum TabIndex {
  PRESENZE,
  UO_ESTERNA,
  PRESTAZIONE_ESTERNE,
  RIEPILOGO,
}

const PresenzeUOW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const logoUri = useAppSelector((state) => state.authInfo.logoUri);
  const classes = useStyles1();

  const strSelectName = useMemo(() => t("structuresTitle"), [t]);
  const strSelectLabel = useMemo(() => t("structureSelect"), [t]);
  const dipSelectName = useMemo(() => t("departmentsTitle"), [t]);
  const dipSelectLabel = useMemo(() => t("departmentSelect"), [t]);
  const unitOpSelectName = useMemo(() => t("operatingUnitTitle"), [t]);
  const unitOpSelectLabel = useMemo(() => t("operatingUnitSelect"), [t]);
  const dateStartName = useMemo(() => t("startDateTitle"), [t]);
  const dateEndName = useMemo(() => t("endDateTitle"), [t]);
  const monthSelectLabel = useMemo(() => t("monthSelect"), [t]);

  useEffect(() => {
    dispatch(resetDipartimentiLookup());
    dispatch(resetUOLookup());
  }, [dispatch]);

  const [states, setStates] = useState<{
    [selectName: string]: number | string | null;
  }>({
    [strSelectName]: null,
    [dipSelectName]: null,
    [unitOpSelectName]: null,
    [dateStartName]: null,
    [dateEndName]: null,
  });

  const fixedProps = useMemo(() => {
    return {
      idStruttura: states[strSelectName],
      idDipartimento: states[dipSelectName],
      idUnitaOperativa: states[unitOpSelectName],
      dateFrom: states[dateStartName],
      dateTo: states[dateEndName],
    };
  }, [dateEndName, dateStartName, dipSelectName, states, strSelectName, unitOpSelectName]);

  const [isFixedPropsChanged, setIsFixedPropsChanged] = useState(false)
  useEffect(() => {
    setIsFixedPropsChanged(true);
  }, [fixedProps.idStruttura, fixedProps.idDipartimento, fixedProps.idUnitaOperativa, fixedProps.dateFrom, fixedProps.dateTo]);

  const idAnagraficaSoggettoConst = "idAnagraficaSoggetto";
  const idAnagraficaSoggettoInternaConst = "idAnagraficaSoggetto int";
  const idAnagraficaSoggettoEsternaConst = "idAnagraficaSoggetto est";

  const lookupStr = useAppSelector((state) => state.strutture.lookup);
  const lookupDip = useAppSelector((state) => state.dipartimentiFiltered.lookup);
  const lookupUnitOp = useAppSelector((state) => state.unitaOperativeFiltered.lookup);
  const lookupTurnoAssenza = useAppSelector((state) => state.assenze.lookupValidDescrizione);
  const lookupTurni = useAppSelector(state => state.turni.lookupDescrizione);
  const lookupQualifiche = useAppSelector(state => state.qualifiche.lookupDescrizione);
  const lookupAnagraficaSoggettoPerUnitaOperativa = useAppSelector((state) => state.anagraficaSoggettiUnitaOperative.lookup);

  const lookupArticoloLeggeTemp = useAppSelector((state) => state.articoliLegge.lookup);
  const lookupArticoloLegge = useMemo(() => {
    return { null: t("fieldNullSelect"), ...lookupArticoloLeggeTemp };
  }, [lookupArticoloLeggeTemp, t]);

  /**
   * Tabs
   */
  const [tabValue, setTabValue] = useState<TabIndex | null>(TabIndex.PRESENZE);
  const handleTabIndexChange = (event: React.ChangeEvent<{}>, newValue: TabIndex) => {
    setTabValue(newValue);
  };
  const handleTabChange = (index: TabIndex) => {
    return {
      id: 'tab-' + index,
      'aria-controls': 'tabpanel-' + index,
    };
  }

  /**
   * Data fetch
   */
  useEffect(() => {
    if (fixedProps.idStruttura && fixedProps.idDipartimento && fixedProps.idUnitaOperativa && fixedProps.dateFrom && fixedProps.dateTo && isFixedPropsChanged)
      dispatch(fetchAllBetweenDates({
        idStruttura: Number(fixedProps.idStruttura),
        idDipartimento: Number(fixedProps.idDipartimento),
        idUnitaOperativa: Number(fixedProps.idUnitaOperativa),
        da: fixedProps.dateFrom.toString(),
        a: fixedProps.dateTo.toString(),
      }));
    isFixedPropsChanged && setIsFixedPropsChanged(false);
  }, [dispatch, fixedProps, isFixedPropsChanged]);

  /**
   * update allfields on tabValue change
   */
  useEffect(() => {
    switch (tabValue) {
      case TabIndex.PRESENZE:
        setAllFieldsState(allPresenzeUOFields);
        break;
      case TabIndex.UO_ESTERNA:
        setAllFieldsState(allUOEsternaFields);
        break;
      case TabIndex.RIEPILOGO:
        const newAllRiepilogoFields = [];
        newAllRiepilogoFields.push(...allRiepilogoFields);
        const validWeekDays = WeekList.slice(1);

        const dateFrom = new Date(fixedProps.dateFrom as string)

        const month = dateFrom.getMonth() + 1;   // [1,12]
        const year = dateFrom.getFullYear();

        const first = new Date(year, month - 1, 1)

        const maxDays = daysInMonth(month, year);
        const weekDay = first.getDay() - 1 < 0
          ? validWeekDays.length - 1
          : first.getDay() - 1;       // [0,6] - first day of the week: monday


        for (let i = 0; i < maxDays; i++) {
          newAllRiepilogoFields.push({
            field: '_' + (i + 1).toString(),
            titleKey: (i + 1) + ' ' + t(validWeekDays[((i) + weekDay) % validWeekDays.length]).substring(0, 2),
            required: false,
            removable: false,
            show: true,
            render: (rowData: Riepilogo) => {
              let color: string | undefined = undefined;
              let data = '';


              const getColor = (descrizioneBreve: string, isTurno: boolean) => {
                let color: string | undefined = undefined;

                if (descrizioneBreve == null || descrizioneBreve === '') return undefined;

                if (!isTurno) {
                  color = TurniColors.Assenza;
                } else if (descrizioneBreve.startsWith('M')) {
                  color = TurniColors.Mattina;
                }
                else if (descrizioneBreve.startsWith('P')) {
                  color = TurniColors.Pomeriggio;
                }
                else if (descrizioneBreve.startsWith('N')) {
                  color = TurniColors.Notte;
                }
                else if (descrizioneBreve.startsWith('SN')) {
                  color = TurniColors.SmontoNotte;
                }
                else if (descrizioneBreve.startsWith('R')) {
                  color = TurniColors.Riposo;
                } else {
                  color = TurniColors.AltriTurni;
                }

                return color;
              }

              rowData.turniAssenze[i].forEach(turno => {
                data += turno.descrizioneBreve + ' ';
                color = getColor(data, turno.isTurno);
              })

              return <Box
                bgcolor={color}
                minHeight={'32px'}
                fontWeight={'bold'}
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                {data}
              </Box>
            }
          });
        }

        setAllFieldsState(newAllRiepilogoFields);
        break;
      case TabIndex.PRESTAZIONE_ESTERNE:
        setAllFieldsState(allPrestazioneEsterneUOFields);
        break;
    }
  }, [fixedProps.dateFrom, isFixedPropsChanged, t, tabValue]);

  /**
   * fetch lookups (strutture, dipartimenti, unità operative)
  */
  useEffect(() => {
    if (fixedProps.idStruttura && fixedProps.idDipartimento) {
      dispatch(
        fetchUnitaOperativeLookup({
          idStruttura: fixedProps.idStruttura as number,
          idDipartimento: fixedProps.idDipartimento as number,
        })
      );
    }
    if (fixedProps.idStruttura) {
      dispatch(fetchDipartimentiLookup({ idStruttura: fixedProps.idStruttura as number, }));
    } else {
      dispatch(fetchStruttureLookup());
    }
  }, [dispatch, fixedProps]);

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allPresenzeUOFields);
  const [columns, setColumns] = useState<Array<Column<Presenza>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<any> = {
          title: f.titleKey ? t(f.titleKey) : "",
          field: f.field === idAnagraficaSoggettoInternaConst ? idAnagraficaSoggettoConst : f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          emptyValue: tabValue === TabIndex.RIEPILOGO ? undefined : f.defaultValue ?? 'N/A',
          align: 'left',
          defaultSort: f.sort,
          sorting: ['presenzaData', 'nominativo'].includes(f.field)
        };

        if (tabValue === TabIndex.RIEPILOGO) {
          if (['nominativo'].includes(f.field)) {
            obj.width = 310;
            obj.cellStyle = {
              position: 'sticky',
              zIndex: 20,
              left: 0,
              backgroundColor: '#fff',
              padding: 0,
              paddingLeft: '16px',
              fontSize: '0.75em'
            }
            obj.headerStyle = {
              position: 'sticky',
              zIndex: 20,
              left: 0,
              backgroundColor: '#fff',
            }
          } else if (['qualificaDescrizione'].includes(f.field)) {
            obj.headerStyle = {
              padding: 0,
              paddingLeft: '16px',
            };
          } else {  // Tutte le date
            obj.width = 30;
            obj.align = 'center';
            obj.cellStyle = {
              borderStyle: 'solid',
              borderColor: '#e0e0e0',
              borderWidth: 1,
              padding: 0,
              fontSize: '0.75em'
            };
            obj.headerStyle = {
              padding: 0,
              paddingLeft: 12,
              paddingRight: 12,
              lineHeight: '1.25em',
              textAlign: 'center'
            };
          }
        }

        if (f.validate2) {
          obj.validate = (rowData) => {
            let resp: boolean | { isValid: boolean, helperText: string } = false;
            let resp2: boolean | { isValid: boolean, helperText: string } = false;

            if (f.validate2) {
              resp2 = f.validate2(
                rowData[f.field as PresenzaKeys],
                rowData[f.field2Validation as PresenzaKeys],
                f.keyTradValidation2 ? t(f.keyTradValidation2) : ''
              );
            }

            if (f.validate) {
              resp = f.validate(
                rowData[f.field as PresenzaKeys],
                f.keyTradValidation ? t(f.keyTradValidation) : ''
              );
            }
            if (resp === true) {
              return resp2;
            } else return resp;
          }
        } else if (f.validate) {
          obj.validate = (rowData) => {
            if (f.validate)
              return f.validate(
                rowData[f.field as PresenzaKeys],
                f.keyTradValidation ? t(f.keyTradValidation) : ''
              );
            return false;
          }
        }

        if (!f.show) {
          obj.hidden = true;
          if (f.field === idAnagraficaSoggettoEsternaConst) obj.hiddenByColumnsButton = true;
          else obj.hiddenByColumnsButton = false;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        if (f.render) {
          obj.render = f.render;
        }

        if (f.defaultGroupOrder != null) obj.defaultGroupOrder = f.defaultGroupOrder;

        return obj;
      })
    );
  }, [allFieldsState, excludedFieldInTable, lookupAnagraficaSoggettoPerUnitaOperativa, lookupArticoloLegge, lookupQualifiche, lookupTurni, lookupTurnoAssenza, lookupUnitOp, t, tabValue]);

  const errorBE = useAppSelector((state) => state.presenze.error);
  const validPresenze: Presenza[] = useAppSelector((state) => state.presenze.validPresenze);
  const statusValidPresenze = useAppSelector((state) => state.presenze.statusValidPresenze);

  const [data, setData] = useState<Array<PresenzeUO | Presenza | Object>>([]);

  /**
   * Prepare data for Material Table
   */
  useEffect(() => {
    switch (tabValue) {
      case TabIndex.PRESENZE:
        const presenzeUO: PresenzeUO[] = validPresenze
          .filter(elem => {
            return elem.idStruttura === fixedProps.idStruttura &&
              elem.idDipartimento === fixedProps.idDipartimento &&
              elem.idUnitaOperativa === fixedProps.idUnitaOperativa
          })
          .map(elem => {
            return {
              presenzaData: elem.presenzaData,
              cognome: elem?.anagraficaSoggetto ? elem?.anagraficaSoggetto[0]?.cognome : '',
              nome: elem?.anagraficaSoggetto ? elem.anagraficaSoggetto[0]?.nome : '',
              nominativo: (elem?.anagraficaSoggetto ? elem?.anagraficaSoggetto[0]?.cognome : '') + ' ' + (elem?.anagraficaSoggetto ? elem.anagraficaSoggetto[0]?.nome : ''),
              turnoDescrizione: elem.turnoDescrizione ?? '',
              turnoDescrizioneBreve: elem.turnoDescrizioneBreve ?? '',
              turnoAssenzaDescrizione: elem.turnoAssenzaDescrizione ?? '',
              turnoAssenzaDescrizioneBreve: elem.turnoAssenzaDescrizioneBreve ?? '',
              unitaOperativaNome: elem?.anagraficaSoggetto ? elem.anagraficaSoggetto[0]?.unitaOperativaNome : '',
              qualificaDescrizione: elem.qualificaDescrizione ?? '',
              qualificaDescrizioneBreve: elem.qualificaDescrizioneBreve ?? '',
              oreTurno: (((elem.oreTurno)?.toString().padStart(2, '0') ?? '00') + ':' + ((elem.minutiTurno)?.toString().padStart(2, '0')) ?? '00') ?? 0,
              approvazioneRichiestaMotivo: elem.approvazioneRichiestaMotivo ?? '',
            };
          });

        setData(presenzeUO);
        break;
      case TabIndex.UO_ESTERNA:
        const filtered = validPresenze.filter(elem => {
          return elem.anagraficaSoggetto && !(elem.anagraficaSoggetto[0].idStruttura === fixedProps.idStruttura &&
            elem.anagraficaSoggetto[0].idDipartimento === fixedProps.idDipartimento &&
            elem.anagraficaSoggetto[0].idUnitaOperativa === fixedProps.idUnitaOperativa);
        });
        const presenzeUOEsterne: PresenzeUO[] = filtered.map(elem => {
          return {
            presenzaData: elem.presenzaData,
            nominativo: (elem?.anagraficaSoggetto ? elem?.anagraficaSoggetto[0]?.cognome : '') + ' ' + (elem?.anagraficaSoggetto ? elem.anagraficaSoggetto[0]?.nome : ''),
            cognome: elem.anagraficaSoggetto[0]?.cognome ?? '',
            nome: elem.anagraficaSoggetto[0]?.nome ?? '',
            turnoDescrizione: elem.turnoDescrizione ?? '',
            turnoDescrizioneBreve: elem.turnoDescrizioneBreve ?? '',
            turnoAssenzaDescrizione: elem.turnoAssenzaDescrizione ?? '',
            turnoAssenzaDescrizioneBreve: elem.turnoAssenzaDescrizioneBreve ?? '',
            unitaOperativaNome: elem.anagraficaSoggetto[0]?.unitaOperativaNome ?? '',
            qualificaDescrizione: elem.qualificaDescrizione ?? '',
            qualificaDescrizioneBreve: elem.qualificaDescrizioneBreve ?? '',
            oreTurno: elem.oreTurno,
            approvazioneRichiestaMotivo: elem.approvazioneRichiestaMotivo,
          };
        });

        setData(presenzeUOEsterne);
        break;
      case TabIndex.PRESTAZIONE_ESTERNE:
        const prestazioniEsterne: PresenzeUO[] = validPresenze
          .filter(elem => {
            return !(elem.idStruttura === fixedProps.idStruttura &&
              elem.idDipartimento === fixedProps.idDipartimento &&
              elem.idUnitaOperativa === fixedProps.idUnitaOperativa)
          })
          .map(elem => {
            return {
              presenzaData: elem.presenzaData,
              nominativo: (elem?.anagraficaSoggetto ? elem?.anagraficaSoggetto[0]?.cognome : '') + ' ' + (elem?.anagraficaSoggetto ? elem.anagraficaSoggetto[0]?.nome : ''),
              cognome: elem?.anagraficaSoggetto ? elem?.anagraficaSoggetto[0]?.cognome : '',
              nome: elem?.anagraficaSoggetto ? elem.anagraficaSoggetto[0]?.nome : '',
              turnoDescrizione: elem.turnoDescrizione ?? '',
              turnoDescrizioneBreve: elem.turnoDescrizioneBreve ?? '',
              turnoAssenzaDescrizione: elem.turnoAssenzaDescrizione ?? '',
              turnoAssenzaDescrizioneBreve: elem.turnoAssenzaDescrizioneBreve ?? '',
              unitaOperativaNome: elem.unitaOperativaNome,
              qualificaDescrizione: elem.qualificaDescrizione ?? '',
              qualificaDescrizioneBreve: elem.qualificaDescrizioneBreve ?? '',
              oreTurno: elem.oreTurno ?? 0,
              approvazioneRichiestaMotivo: elem.approvazioneRichiestaMotivo ?? '',
            };
          });
        setData(prestazioniEsterne);
        break;
      case TabIndex.RIEPILOGO:

        const dateFrom = fixedProps.dateFrom ? new Date(fixedProps.dateFrom) : null

        const month = dateFrom ? dateFrom.getMonth() + 1 : null;   // [1,12]
        const year = dateFrom ? dateFrom.getFullYear() : null;

        const presenzeInterne = validPresenze.filter(elem => {
          return elem.idStruttura === fixedProps.idStruttura
            && elem.idDipartimento === fixedProps.idDipartimento
            && elem.idUnitaOperativa === fixedProps.idUnitaOperativa
        })

        const presenzeEsterne = validPresenze.filter(elem => {
          return !(elem.idStruttura === fixedProps.idStruttura
            && elem.idDipartimento === fixedProps.idDipartimento
            && elem.idUnitaOperativa === fixedProps.idUnitaOperativa)
        })

        const newDataInterno: Riepilogo[] = month && year ? createRiepilogo(presenzeInterne, month, year) : [];
        const newDataEsterno: Riepilogo[] = month && year ? createRiepilogo(presenzeEsterne, month, year) : [];

        const retval = [...newDataInterno, ...newDataEsterno];

        setData(retval);
        break;
    }
  }, [fixedProps.dateFrom, fixedProps.idDipartimento, fixedProps.idStruttura, fixedProps.idUnitaOperativa, lookupAnagraficaSoggettoPerUnitaOperativa, t, tabValue, validPresenze]);

  const handleDateChange = (d: Date | null, field?: string) => {
    if (d) {
      const month = d.getMonth() + 1;   // [1,12]
      const year = d.getFullYear();
      const maxDays = daysInMonth(month, year);

      const firstDate = new Date(year, month - 1, 1)
      const lastDate = new Date(year, month - 1, maxDays);

      setStates(prev => { return { ...prev, [dateStartName]: getDateYYYYMMDD(firstDate) + "T00:00:00", [dateEndName]: getDateYYYYMMDD(lastDate) + "T00:00:00" } });
    }
  };

  const [exportDataExtra, setExportDataExtra] = useState<PDFExtraData>();

  /**
   * set export data (head and additional) based on selected tab
   */
  useEffect(() => {
    setExportDataExtra({
      head: {
        title: [t("structureTitle"), t("departmentTitle"), t("operatingUnitTitle"), t("yearTitle"), t("monthTitle")],
        value: [
          fixedProps.idStruttura ? lookupStr[fixedProps.idStruttura] : '',
          fixedProps.idDipartimento ? lookupDip[fixedProps.idDipartimento] : '',
          fixedProps.idUnitaOperativa ? lookupUnitOp[fixedProps.idUnitaOperativa] : '',
          fixedProps.dateFrom ? (new Date(fixedProps.dateFrom)).getFullYear() : '',
          fixedProps.dateFrom ? t(MonthsList[(new Date(fixedProps.dateFrom)).getMonth()]) : ''
        ]
      }
    });
  }, [fixedProps, lookupDip, lookupStr, lookupUnitOp, t, tabValue, validPresenze]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setStates({});
      setTabValue(null);
      setAllFieldsState([]);
      setColumns([]);
      setData([]);
      setExportDataExtra(undefined);

      dispatch(resetPresenze());
      dispatch(resetStrutture());
      dispatch(resetDipartimentiFiltered());
      dispatch(resetUnitaOperativeFiltered());
    }
  }, [dispatch]);

  return (
    <>
      <Paper elevation={2}>
        <Box p={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={10}>
              <SimpleIdSelects
                selectsArray={[
                  {
                    name: strSelectName,
                    lookup: lookupStr,
                    label: strSelectLabel,
                    disabled: false,
                    breakpoints: { xs: 12, md: 12, sm: 12, lg: 4, }
                  },
                  {
                    name: dipSelectName,
                    lookup: lookupDip,
                    label: dipSelectLabel,
                    disabled: false,
                    breakpoints: { xs: 12, md: 12, sm: 12, lg: 4, },
                    callback: resetDipartimentiLookup,
                  },
                  {
                    name: unitOpSelectName,
                    lookup: lookupUnitOp,
                    label: unitOpSelectLabel,
                    disabled: false,
                    breakpoints: { xs: 12, md: 12, sm: 12, lg: 4, },
                    callback: resetUOLookup,
                  },
                ]}
                states={states}
                setStates={setStates}
              />
            </Grid>
            {states[strSelectName] && states[dipSelectName] && states[unitOpSelectName] &&
              <Grid item xs={12} sm={12} md={12} lg={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === "it-IT" || i18n.language === "it" ? it : enGB}>
                  <DatePicker
                    // variant="inline"
                    label={monthSelectLabel}
                    format={"MMMM yyyy"}
                    onChange={(e) => handleDateChange(e, dateStartName)}
                    // autoOk={true}
                    value={states[dateStartName] ? states[dateStartName] : null}
                    views={['month', 'year',]}
                    okLabel={t('insertLabel')}
                    clearLabel={t('clearLabel')}
                    cancelLabel={t('cancelLabel')}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            }
          </Grid>
        </Box>
      </Paper>
      <Box marginTop={2} >
        {
          states[strSelectName] &&
            states[dipSelectName] &&
            states[unitOpSelectName] &&
            states[dateStartName] &&
            states[dateEndName] ? (
            <>
              <Switch>
                <Route path={presenzeUOPath} exact>
                  <>
                    <AppBar position="static" className={classes.appbar}>
                      <Tabs value={tabValue ?? TabIndex.PRESENZE} onChange={handleTabIndexChange} classes={{ indicator: classes.indicator }}>
                        <Tab label={t("attendanceTabLabel")} {...handleTabChange(TabIndex.PRESENZE)} />
                        <Tab label={t("operationalUnitExternalTabLabel")} {...handleTabChange(TabIndex.UO_ESTERNA)} />
                        <Tab label={t("externalPerformance")} {...handleTabChange(TabIndex.PRESTAZIONE_ESTERNE)} />
                        <Tab label={t("summaryTabLabel")} {...handleTabChange(TabIndex.RIEPILOGO)} />
                      </Tabs>
                    </AppBar>
                    {/* Presenze */}
                    <TabPanel value={tabValue} index={TabIndex.PRESENZE}>
                      <ReadOnlyMaterialTable
                        title={t("attendanceTabLabel")}
                        columns={columns}
                        data={data}
                        fetchAllValid={fetchAllBetweenDates}
                        statusValid={statusValidPresenze}
                        errorBE={errorBE}
                        logoUri={logoUri}
                        fixedProps={fixedProps}
                        exportDataExtra={exportDataExtra}
                        isExportLandscape={true}
                        localizedDatePicker={true}
                        extraOptions={{
                          minBodyHeight: calculateMTableHeight(0, true, true),
                          maxBodyHeight: calculateMTableHeight(0, true, true),
                          defaultExpanded: true
                        }}
                      />
                    </TabPanel>
                    {/* Conteggio Qualifica */}
                    <TabPanel value={tabValue} index={TabIndex.UO_ESTERNA}>
                      <ReadOnlyMaterialTable
                        title={t("operationalUnitExternalTabLabel")}
                        columns={columns}
                        data={data}
                        fetchAllValid={fetchAllBetweenDates}
                        statusValid={statusValidPresenze}
                        errorBE={errorBE}
                        logoUri={logoUri}
                        fixedProps={fixedProps}
                        exportDataExtra={exportDataExtra}
                        isExportLandscape={true}
                        localizedDatePicker={true}
                        extraOptions={{
                          minBodyHeight: calculateMTableHeight(0, true, true),
                          maxBodyHeight: calculateMTableHeight(0, true, true),
                        }}
                      />
                    </TabPanel>
                    {/* Presenze */}
                    <TabPanel value={tabValue} index={TabIndex.PRESTAZIONE_ESTERNE}>
                      <ReadOnlyMaterialTable
                        title={t("attendanceTabLabel")}
                        columns={columns}
                        data={data}
                        fetchAllValid={fetchAllBetweenDates}
                        statusValid={statusValidPresenze}
                        errorBE={errorBE}
                        logoUri={logoUri}
                        fixedProps={fixedProps}
                        exportDataExtra={exportDataExtra}
                        isExportLandscape={true}
                        localizedDatePicker={true}
                        extraOptions={{
                          minBodyHeight: calculateMTableHeight(0, true, true),
                          maxBodyHeight: calculateMTableHeight(0, true, true),
                        }}
                      />
                    </TabPanel>
                    {/* Riepilogo */}
                    <TabPanel value={tabValue} index={TabIndex.RIEPILOGO}>
                      <ReadOnlyMaterialTable
                        title={t("summaryTabLabel")}
                        columns={columns}
                        data={data}
                        fetchAllValid={fetchAllBetweenDates}
                        statusValid={statusValidPresenze}
                        errorBE={errorBE}
                        logoUri={logoUri}
                        fixedProps={fixedProps}
                        exportDataExtra={exportDataExtra}
                        isExportLandscape={true}
                        localizedDatePicker={true}
                        extraOptions={{
                          minBodyHeight: calculateMTableHeight(0, true, true),
                          maxBodyHeight: calculateMTableHeight(0, true, true),
                          defaultExpanded: true,
                          // fixedColumns: { left: 1 },
                          padding: 'dense',
                          pageSize: validPresenze.length >= 20
                            ? 20
                            : validPresenze.length <= 5
                              ? 5
                              : 10,

                        }}
                        pdfOptions={{
                          bodyFontSize: 5
                        }}
                      />
                    </TabPanel>
                  </>
                </Route>
                <Route>
                  <Redirect to={notFoundPath} />
                </Route>
              </Switch>
            </>
          ) : (
            <Switch>
              <Route path={presenzeUOPath} exact>
                <Paper elevation={2}>
                  <Box p={4}>
                    <Typography align="center" variant="h5">
                      {!states[strSelectName]
                        ? strSelectLabel
                        : !states[dipSelectName]
                          ? dipSelectLabel
                          : !states[unitOpSelectName]
                            ? unitOpSelectLabel
                            : monthSelectLabel}
                    </Typography>
                  </Box>
                </Paper>
              </Route>
              <Route><Redirect to={presenzeUOPath} /></Route>
            </Switch>
          )}
      </Box>
    </>
  );
};
export default PresenzeUOW;

interface TurniAssenze {
  id: number;
  descrizione: string;
  descrizioneBreve: string;
  isTurno: boolean;
}

interface Riepilogo {
  idAnagraficaSoggetto: number;
  nominativo: string;
  colore: Array<'Verde' | 'Giallo' | 'Rosso' | undefined>;
  turniAssenze: TurniAssenze[][];
  qualificaDescrizione?: string;
}

function createRiepilogo(presenze: Presenza[], mese: number, anno: number): Riepilogo[] {
  const retval: Riepilogo[] = [];
  const maxDays = getDaysInMonth(new Date(anno, mese - 1, 1));

  /**
   * Collect all anagrafica soggetti
   */
  presenze?.forEach(presenza => {
    const lname = presenza.anagraficaSoggetto ? presenza?.anagraficaSoggetto[0]?.cognome : '';
    const fname = presenza.anagraficaSoggetto ? presenza?.anagraficaSoggetto[0]?.nome : '';
    const idAnagraficaSoggetto = presenza?.idAnagraficaSoggetto;
    const _date = new Date(presenza.presenzaData).getDate() - 1;

    const isTurno = presenza.idTurno != null;
    const _id = presenza.idTurno ?? presenza.idTurnoAssenza;
    const _descrizione = (presenza.turnoDescrizione != null && presenza.turnoDescrizione !== '') ? presenza.turnoDescrizione : presenza.turnoAssenzaDescrizione;
    const _descrizioneBreve = (presenza.turnoDescrizioneBreve != null && presenza.turnoDescrizioneBreve !== '') ? presenza.turnoDescrizioneBreve : presenza.turnoAssenzaDescrizioneBreve;

    if (!retval.some(temp => Number(temp.idAnagraficaSoggetto) === Number(idAnagraficaSoggetto))) {
      retval.push({
        idAnagraficaSoggetto: idAnagraficaSoggetto,
        nominativo: lname + ' ' + fname,
        qualificaDescrizione: presenza.qualificaDescrizione,
        colore: new Array(maxDays).fill(""),
        turniAssenze: new Array(maxDays).fill(null).map(() => []),
      });

      if (_id != null && _descrizione != null && _descrizioneBreve != null) {
        retval[retval.length - 1].turniAssenze[_date].push({
          id: _id,
          descrizione: _descrizione,
          descrizioneBreve: _descrizioneBreve,
          isTurno
        });
      }

      retval[retval.length - 1].colore[_date] = presenza.coloreTipoPresenza;
    } else {
      const currentSoggettoIndex = retval.findIndex(temp => temp.idAnagraficaSoggetto === idAnagraficaSoggetto)!;

      if (_id != null && _descrizione != null && _descrizioneBreve != null) {
        retval[currentSoggettoIndex].turniAssenze[_date].push({
          id: _id,
          descrizione: _descrizione,
          descrizioneBreve: _descrizioneBreve,
          isTurno
        });
      }

      retval[currentSoggettoIndex].colore[_date] = presenza.coloreTipoPresenza;
    }
  });

  return retval;
}