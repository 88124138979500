import { useCallback, useMemo } from 'react';

import { Box, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next'

import { allFieldsNuovoTurno, NuovoTurno, Pianificazione } from '../../../../../../models/Pianificazioni';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { createLookup, getDateYYYYMMDD, } from '../../../../../../utils/utilfunctions';
import GeneralForm, { OptionalArgs } from '../../../../forms/GeneralForm'
import { addTurno, checkTurnoOrTurnoAssenzaPianificabile, refreshSoggettoEQualifica } from '../../../../../../store/slices/pianificazioniSlice';
import { RiepilogoPianificazioneDTO } from '../../../PianificazioniW';

export interface IAnagraficaLookupObject {
  nome: string,
  cognome: string
}

interface InsertTurnoFormProps {
  fixedProps: {
    idStruttura: number;
    idDipartimento: number;
    idUnitaOperativa: number;
    mese: number;
    anno: number;
  };
  currentSelectedAS: RiepilogoPianificazioneDTO;
  currentSelectedDate: Date;
  handleClose?: () => void
}

const InsertTurnoForm = ({ fixedProps, currentSelectedAS, currentSelectedDate, handleClose }: InsertTurnoFormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const errorBE = useAppSelector((state => state.pianificazione.error));
  const idTurno = 'idTurno';
  const idTurnoAssenza = 'idTurnoAssenza';

  const anagraficaLookupObject = useAppSelector(state => state.anagraficaSoggettiUnitaOperative.lookupObject) as Record<string | number, IAnagraficaLookupObject>;

  const turniAbilitatiFiltered = useAppSelector(state => state.anagraficaSoggettiTurnoAbilitato.turniAbilitati);
  const turniDescrizioneLookup = useAppSelector(state => state.turni.lookupDescrizione);
  const turniDescrizioneBreveLookup = useAppSelector(state => state.turni.lookupDescrizioneBreve);

  const assenzaAbilitatiDescrizione = useAppSelector(state => state.assenze.lookupValidDescrizione);
  const validAssenze = useAppSelector(state => state.assenze.validAssenze);
  const assenzaAbilitatiDescrizioneBreve = useAppSelector(state => state.assenze.lookupValidDescrizioneBreve);

  const validPianificazioni = useAppSelector(state => state.pianificazione.validPianificazioni);
  const validUnitaOperativaPianificazioni = useAppSelector(state => state.pianificazione.validUnitaOperativaPianificazioni);
  const statusValidUnitaOperativaPianificazioni = useAppSelector(state => state.pianificazione.statusValidUnitaOperativaPianificazioni);

  const turniAbilitatiLookup = createLookup(turniAbilitatiFiltered, 'idTurno', ['descrizione']);

  const formLookups = useMemo(() => {
    return {
      [idTurno]: turniAbilitatiLookup,
      [idTurnoAssenza]: assenzaAbilitatiDescrizione,
    }
  }, [assenzaAbilitatiDescrizione, turniAbilitatiLookup,])

  const formCallback = useCallback((formObject: NuovoTurno | null, field: string, optionalArgs: OptionalArgs<NuovoTurno>) => {
    const { setInternalObj, setFields } = optionalArgs;

    if (formObject) {
      if (field === idTurno || field === idTurnoAssenza) {
        setFields(state => {
          const newFields = [...state];

          newFields.forEach((elem, index, array) => {
            array[index].required = elem.field === field;
          });

          return newFields;
        });
      }

      if (turniAbilitatiFiltered) {
        const selectedAssenza = validAssenze.find(elem => elem.idTurnoAssenza === Number(formObject[idTurnoAssenza]));

        setInternalObj(state => {
          const newObject = { ...state };
          let oreTurno: number = 0;
          let minutiTurno: number = 0;

          switch (field) {
            case idTurno:
              delete newObject[idTurnoAssenza];

              const _object = turniAbilitatiFiltered.find(elem => Number(elem.idTurno) === Number(formObject[idTurno]));

              oreTurno = _object?.ore ?? 0;
              minutiTurno = _object?.minuti ?? 0;

              break;
            case idTurnoAssenza:
              delete newObject[idTurno];
              oreTurno = selectedAssenza?.ore ?? 0;
              minutiTurno = 0;
              break;
          }

          return {
            ...newObject,
            oreTurno,
            minutiTurno
          }
        })
      }
    }
  }, [turniAbilitatiFiltered, validAssenze]);

  const aggiungi = (nuovoTurno: NuovoTurno) => {
    const nuovaPianificazione: Partial<Pianificazione> = {
      idStruttura: fixedProps?.idStruttura,
      idDipartimento: fixedProps?.idDipartimento,
      idUnitaOperativa: fixedProps?.idUnitaOperativa,

      anno: fixedProps?.anno,
      mese: fixedProps?.mese,
      pianificazioneData: getDateYYYYMMDD(currentSelectedDate) + 'T00:00:00',

      idAnagraficaSoggetto: currentSelectedAS.idAnagraficaSoggetto,
      cognome: currentSelectedAS.cognome ?? anagraficaLookupObject[currentSelectedAS.idAnagraficaSoggetto]['cognome'],
      nome: currentSelectedAS.nome ?? anagraficaLookupObject[currentSelectedAS.idAnagraficaSoggetto]['nome'],

      idQualifica: currentSelectedAS.idQualifica,
      qualificaDescrizione: currentSelectedAS.qualificaDescrizione,
      qualificaDescrizioneBreve: currentSelectedAS.qualificaDescrizioneBreve,

      reperibilita: false,
      tipoInserimento: 'Nuova',

      idTurno: nuovoTurno[idTurno] ? nuovoTurno[idTurno] : undefined,
      turnoDescrizione: nuovoTurno[idTurno] ? turniDescrizioneLookup[nuovoTurno[idTurno]] : undefined,
      turnoDescrizioneBreve: nuovoTurno[idTurno] ? turniDescrizioneBreveLookup[nuovoTurno[idTurno]] : undefined,

      idTurnoAssenza: nuovoTurno[idTurnoAssenza] ? nuovoTurno[idTurnoAssenza] : undefined,
      turnoAssenzaDescrizione: nuovoTurno[idTurnoAssenza] ? assenzaAbilitatiDescrizione[nuovoTurno[idTurnoAssenza]] : undefined,
      turnoAssenzaDescrizioneBreve: nuovoTurno[idTurnoAssenza] ? assenzaAbilitatiDescrizioneBreve[nuovoTurno[idTurnoAssenza]] : undefined,

      oreTurno: nuovoTurno.oreTurno,
      minutiTurno: nuovoTurno.minutiTurno,
      nota: '',
    }

    if ((nuovoTurno[idTurno] || nuovoTurno[idTurnoAssenza]) && validUnitaOperativaPianificazioni) {
      dispatch(checkTurnoOrTurnoAssenzaPianificabile({
        idAnagraficaSoggetto: currentSelectedAS.idAnagraficaSoggetto,
        idStruttura: fixedProps.idStruttura,
        idDipartimento: fixedProps.idDipartimento,
        idUnitaOperativa: fixedProps.idUnitaOperativa,
        idTurno: nuovoTurno[idTurno],
        idTurnoAssenza: nuovoTurno[idTurnoAssenza],
        dataRiferimento: getDateYYYYMMDD(currentSelectedDate) + 'T00:00:00',
      })).then(data => {
        if (data.payload === true) {
          handleClose && handleClose();
          dispatch(addTurno(nuovaPianificazione as Pianificazione));
          dispatch(refreshSoggettoEQualifica({
            unitaOperativePianificazione: validUnitaOperativaPianificazioni,
            pianificazione: validPianificazioni.concat([nuovaPianificazione as Pianificazione]),
          }));
        }
      })
    }
  }

  return (
    <Paper elevation={3}>
      <Box mx='auto' pt={4} px={4}>
        <GeneralForm
          fields={allFieldsNuovoTurno}
          status={statusValidUnitaOperativaPianificazioni}
          localAction={aggiungi}
          translate={t}
          lookups={formLookups}
          checksCallback={formCallback}
          error={errorBE}
          buttonLabel={'save'}
        />
      </Box>
      {
        errorBE &&
        <Box mt={2} p={4}>
          <Typography align="center" variant="h5" color="error">
            {errorBE}
          </Typography>
        </Box>

      }
    </Paper>
  )
}

export default InsertTurnoForm