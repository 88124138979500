import { Typography } from "@material-ui/core";
import { validateDate, validateDateXGreaterThanY, validateDateXSmallerThanY, validateInputNumberMin, validateInputTextMinMaxLen, validateRequired } from "../utils/utilfunctions";
import { Fields } from "./Fields";
import { LookupAnagraficaSoggettoElem } from "./AnagraficaSoggetti";

export type AssenzaProgrammataKeys = keyof AssenzaProgrammata;

export const elementIdProps: AssenzaProgrammataKeys[] = ["idAnagraficaSoggetto", "idTurnoAssenza", "dataInizioAssenza"];
export const elementRenderProps: AssenzaProgrammataKeys[] = [];

export const allFieldsAssenzaProgrammata: Fields[] = [
  // Riga 1
  {
    field: "idAnagraficaSoggetto", required: true, editable: 'onAdd', autocomplete: true, colsNum: 12, showOn: 'form',
    validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation",
    show: true, titleKey: "subjectParam",
  },

  // Riga 2
  // Form
  {
    field: "idTurnoAssenza", required: true, editable: 'onAdd', autocomplete: true, showOn: 'form',
    validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation",
    show: true, titleKey: "absenceShiftParam"
  },
  {
    field: "idArticoloLegge", required: false, autocomplete: true, showOn: 'form',
    show: true, titleKey: "articleParam"
  },

  // Table (decode)
  {
    field: "idAnagraficaSoggetto", required: true, editable: 'onAdd', colsNum: 12, showOn: 'table',
    show: true, titleKey: "subjectParam",
    render: (rowData: AssenzaProgrammata) => {
      return rowData.cognome + ' ' + rowData.nome
    }
  },
  {
    field: "turnoAssenzaDescrizioneBreve", type: 'string', required: false, showOn: 'table',
    show: false, titleKey: "absenceShiftParam"
  },
  {
    field: "turnoAssenzaDescrizione", type: 'string', required: true, showOn: 'table',
    show: true, titleKey: "absenceShiftParam"
  },
  {
    field: "articoloLeggeDescrizione", type: 'string', required: false, showOn: 'table',
    show: false, titleKey: "articleParam"
  },

  // Riga 3
  {
    field: "dataInizioAssenza", type: "date", required: true, editable: 'onAdd',
    validate: (x: string, helperText: string) => validateDate(x, 1970, null, helperText, true), keyTradValidation: "notValidDateValidation",
    show: true, titleKey: "absenceStartDateParam", sort: 'desc'
  },
  {
    field: "dataFineAssenza", type: "date", required: true,
    validate: (x: string, helperText: string) => validateDate(x, 1970, null, helperText, true), keyTradValidation: "notValidDateValidation",
    field2Validation: "dataInizioAssenza",
    validate2: (x: string, y: string, helperText: string) => validateDateXGreaterThanY(x, y, true, helperText, true), keyTradValidation2: "smallerEqualThanAbsenceStartDateValidation",
    show: true, titleKey: "absenceEndDateParam"
  },

  // Riga 4
  {
    field: "oraInizioAssenza", type: "time", required: false, readonly: false, colsNum: 3, showOn: 'hidden',
    validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation",
    show: true, titleKey: "absenceStartTimeParam"
  },
  {
    field: "oraFineAssenza", type: "time", required: false, readonly: false, colsNum: 3, showOn: 'hidden',
    validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation",
    show: true, titleKey: "absenceEndTimeParam"
  },
  {
    field: "oreAssenza", type: "numeric", required: false, readonly: true, colsNum: 3, showOn: 'hidden',
    validate: (x: number, helperText: string) => validateInputNumberMin(x, 0, true, helperText, true), keyTradValidation: "greaterOrEqualThanZeroValidation",
    show: true, titleKey: "hoursAbsenceParam"
  },
  {
    field: "minutiAssenza", type: "numeric", required: false, readonly: true, colsNum: 3, showOn: 'hidden',
    validate: (x: number, helperText: string) => validateInputNumberMin(x, 0, true, helperText, true), keyTradValidation: "greaterOrEqualThanZeroValidation",
    show: true, titleKey: "minutesAbsenceParam"
  },

  // Riga 5
  {
    field: "noteAssenza", type: "string", required: false, colsNum: 12,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 100, false, helperText, false), keyTradValidation: "size3to100ParamValidation",
    show: false, titleKey: "absenceNoteParam"
  },
  // nuovo
  {
    field: "approvazioneUtente", type: "string", required: false, editable: 'never', showOn: 'table',
    show: false, titleKey: "approvalUserParam"
  },
  {
    field: "approvazioneDateTime", type: "datetime", required: false, editable: 'never', showOn: 'table',
    show: true, titleKey: "approvalDateParam"
  },
  {
    field: "approvazioneNegataData", type: "datetime", required: false, editable: 'never', showOn: 'table',
    show: true, titleKey: "approvalDeniedDate"
  }
];

export const allFieldsLungaAssenza: Fields[] = [
  // Riga 1
  {
    field: "idAnagraficaSoggetto", required: true, editable: 'onAdd', autocomplete: true, colsNum: 12, showOn: 'form',
    validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation",
    show: true, titleKey: "subjectParam",
  },

  // Riga 2
  // Form
  {
    field: "idTurnoAssenza", required: true, editable: 'onAdd', autocomplete: true, showOn: 'form',
    validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation",
    show: true, titleKey: "absenceShiftParam"
  },
  {
    field: "idArticoloLegge", required: false, autocomplete: true, showOn: 'form',
    show: true, titleKey: "articleParam"
  },

  // Table (decode)
  {
    field: "idAnagraficaSoggetto", required: true, editable: 'onAdd', colsNum: 12, showOn: 'table',
    show: true, titleKey: "subjectParam",
    render: (rowData: AssenzaProgrammata) => {
      return rowData.cognome + ' ' + rowData.nome
    }
  },
  {
    field: "turnoAssenzaDescrizioneBreve", type: 'string', required: false, showOn: 'table',
    show: false, titleKey: "absenceShiftParam"
  },
  {
    field: "turnoAssenzaDescrizione", type: 'string', required: true, showOn: 'table',
    show: true, titleKey: "absenceShiftParam"
  },
  {
    field: "articoloLeggeDescrizione", type: 'string', required: false, showOn: 'table',
    show: false, titleKey: "articleParam"
  },

  // Riga 3
  {
    field: "dataInizioAssenza", type: "date", required: true, editable: 'onAdd',
    validate: (x: string, helperText: string) => validateDate(x, 1970, null, helperText, true), keyTradValidation: "notValidDateValidation",
    show: true, titleKey: "absenceStartDateParam", sort: 'desc'
  },
  {
    field: "dataFineAssenza", type: "date", required: true, editable: 'always',
    validate: (x: string, helperText: string) => validateDate(x, 1970, null, helperText, true), keyTradValidation: "notValidDateValidation",
    field2Validation: "dataInizioAssenza",
    validate2: (x: string, y: string, helperText: string) => validateDateXGreaterThanY(x, y, true, helperText, true), keyTradValidation2: "smallerEqualThanAbsenceStartDateValidation",
    show: true, titleKey: "absenceEndDateParam"
  },

  // Riga 3
  {
    field: "partoDataPresunta", type: "date", required: false, editable: 'always',
    validate: (x: string, helperText: string) => validateDate(x, 1970, null, helperText, true), keyTradValidation: "notValidDateValidation",
    // field2Validation: "dataFineAssenza",
    // validate2: (x: string, y: string, helperText: string) => validateDateXSmallerThanY(x, y, true, helperText, true), keyTradValidation2: "greaterEqualThanAbsenceEndDateValidation",
    show: false, titleKey: "allegedDeliveryDateParam", sort: 'desc'
  },
  {
    field: "partoDataEffettiva", type: "date", required: false, editable: 'always',
    validate: (x: string, helperText: string) => validateDate(x, 1970, null, helperText, true), keyTradValidation: "notValidDateValidation",
    show: false, titleKey: "actualDeliveryDateParam"
  },

  // Riga 4
  {
    field: "oraInizioAssenza", type: "time", required: false, readonly: false, colsNum: 3, showOn: 'hidden',
    validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation",
    show: true, titleKey: "absenceStartTimeParam"
  },
  {
    field: "oraFineAssenza", type: "time", required: false, readonly: false, colsNum: 3, showOn: 'hidden',
    validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation",
    show: true, titleKey: "absenceEndTimeParam"
  },
  {
    field: "oreAssenza", type: "numeric", required: false, readonly: true, colsNum: 3, showOn: 'hidden',
    validate: (x: number, helperText: string) => validateInputNumberMin(x, 0, true, helperText, true), keyTradValidation: "greaterOrEqualThanZeroValidation",
    show: true, titleKey: "hoursAbsenceParam"
  },
  {
    field: "minutiAssenza", type: "numeric", required: false, readonly: true, colsNum: 3, showOn: 'hidden',
    validate: (x: number, helperText: string) => validateInputNumberMin(x, 0, true, helperText, true), keyTradValidation: "greaterOrEqualThanZeroValidation",
    show: true, titleKey: "minutesAbsenceParam"
  },

  // Riga 5
  {
    field: "noteAssenza", type: "string", required: false, colsNum: 12,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 100, false, helperText, false), keyTradValidation: "size3to100ParamValidation",
    show: false, titleKey: "absenceNoteParam"
  },
  // nuovo
  {
    field: "approvazioneUtente", type: "string", required: false, editable: 'never', showOn: 'table',
    show: false, titleKey: "approvalUserParam"
  },
  {
    field: "approvazioneDateTime", type: "datetime", required: false, editable: 'never', showOn: 'table',
    show: false, titleKey: "approvalDateParam"
  },
  {
    field: "approvazioneNegataData", type: "datetime", required: false, editable: 'never', showOn: 'table',
    show: false, titleKey: "approvalDeniedDate"
  }
];

export const allFieldsRichiesta: Fields[] = [
  // Riga 1
  {
    field: "idTurnoAssenza", required: true, editable: 'onAdd', lookupField: true, show: true, titleKey: "absenceShiftParam", showOn: 'form',
    validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
  },
  {
    field: "idArticoloLegge", required: false, lookupField: true, showOn: 'form',
    show: true, titleKey: "articleParam"
  },

  {
    field: "turnoAssenzaDescrizioneBreve", type: 'string', required: false, showOn: 'table',
    show: false, titleKey: "absenceShiftParam"
  },
  {
    field: "turnoAssenzaDescrizione", type: 'string', required: true, showOn: 'table',
    show: true, titleKey: "absenceShiftParam"
  },
  {
    field: "articoloLeggeDescrizione", type: 'string', required: false, showOn: 'table',
    show: true, titleKey: "articleParam"
  },

  // Riga 2
  {
    field: "dataInizioAssenza", type: "date", required: true, editable: 'onAdd',
    validate: (x: string, helperText: string) => validateDate(x, 1970, null, helperText, true), keyTradValidation: "notValidDateValidation",
    // field2Validation: "dataFineAssenza",
    // validate2: (x: string, y: string, helperText: string) => validateDateXSmallerThanY(x, y, true, helperText, true), keyTradValidation2: "greaterEqualThanAbsenceEndDateValidation",
    show: true, titleKey: "absenceStartDateParam"
  },
  {
    field: "dataFineAssenza", type: "date", required: true,
    validate: (x: string, helperText: string) => validateDate(x, 1970, null, helperText, true), keyTradValidation: "notValidDateValidation",
    field2Validation: "dataInizioAssenza",
    validate2: (x: string, y: string, helperText: string) => validateDateXGreaterThanY(x, y, true, helperText, true), keyTradValidation2: "smallerEqualThanAbsenceStartDateValidation",
    show: true, titleKey: "absenceEndDateParam"
  },

  // Riga 3
  {
    field: "oraInizioAssenza", type: "time", required: false, readonly: false, colsNum: 3, showOn: 'hidden',
    validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation",
    show: true, titleKey: "absenceStartTimeParam"
  },
  {
    field: "oraFineAssenza", type: "time", required: false, readonly: false, colsNum: 3, showOn: 'hidden',
    validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation",
    show: true, titleKey: "absenceEndTimeParam"
  },
  {
    field: "oreAssenza", type: "numeric", required: false, readonly: true, colsNum: 3, showOn: 'hidden',
    validate: (x: number, helperText: string) => validateInputNumberMin(x, 0, true, helperText, true), keyTradValidation: "greaterOrEqualThanZeroValidation",
    show: true, titleKey: "hoursAbsenceParam"
  },
  {
    field: "minutiAssenza", type: "numeric", required: false, readonly: true, colsNum: 3, showOn: 'hidden',
    validate: (x: number, helperText: string) => validateInputNumberMin(x, 0, true, helperText, true), keyTradValidation: "greaterOrEqualThanZeroValidation",
    show: true, titleKey: "minutesAbsenceParam"
  },

  // Riga 4
  {
    field: "noteAssenza", type: "string", required: false, colsNum: 12,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 100, false, helperText, false), keyTradValidation: "size3to100ParamValidation",
    show: true, titleKey: "absenceNoteParam"
  },
  {
    field: "approvazioneDateTime", type: "datetime", required: false, editable: 'never', showOn: 'table',
    show: true, titleKey: "approvalDateParam", sort: 'desc'
  },
  {
    field: "approvazioneNegataData", type: "datetime", required: false, editable: 'never', showOn: 'table',
    show: true, titleKey: "approvalDeniedDate"
  },
  {
    field: "approvazioneUtente", type: "string", required: false, editable: 'never', showOn: 'table',
    show: false, titleKey: "approvalUserParam",
  },
]

export const allFieldsApprovazione: Fields[] = [
  {
    field: "idAnagraficaSoggetto", required: true,
    show: true, titleKey: "nominativeParam", render: (rowData) => {
      return <Typography variant="body1">{rowData.cognome + ' ' + rowData.nome}</Typography>
    }
  },
  {
    field: "idTurnoAssenza", required: true, editable: 'onAdd', showOn: 'form',
    validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation",
    show: true, titleKey: "absenceShiftParam"
  },
  {
    field: "turnoAssenzaDescrizioneBreve", type: 'string', required: false, showOn: 'table',
    show: false, titleKey: "absenceShiftParam"
  },
  {
    field: "turnoAssenzaDescrizione", type: 'string', required: true, showOn: 'table',
    show: true, titleKey: "absenceShiftParam"
  },
  {
    field: "dataInizioAssenza", type: "date", required: true, editable: 'onAdd',
    validate: (x: string, helperText: string) => validateDate(x, 1970, null, helperText, true), keyTradValidation: "notValidDateValidation",
    field2Validation: "dataFineAssenza",
    validate2: (x: string, y: string, helperText: string) => validateDateXSmallerThanY(x, y, true, helperText, true), keyTradValidation2: "greaterEqualThanAbsenceEndDateValidation",
    show: true, titleKey: "absenceStartDateParam"
  },
  {
    field: "dataFineAssenza", type: "date", required: true,
    validate: (x: string, helperText: string) => validateDate(x, 1970, null, helperText, true), keyTradValidation: "notValidDateValidation",
    field2Validation: "dataInizioAssenza",
    validate2: (x: string, y: string, helperText: string) => validateDateXGreaterThanY(x, y, true, helperText, true), keyTradValidation2: "smallerEqualThanAbsenceStartDateValidation",
    show: true, titleKey: "absenceEndDateParam"
  },
  {
    field: "idArticoloLegge", required: false, show: true, titleKey: "articleParam", showOn: 'form'
  },
  {
    field: "articoloLeggeDescrizione", type: 'string', required: false, showOn: 'table',
    show: true, titleKey: "articleParam"
  },
  {
    field: "noteAssenza", type: "string", required: false,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 100, false, helperText, false), keyTradValidation: "size3to100ParamValidation",
    show: true, titleKey: "absenceNoteParam"
  },
  {
    field: "approvazioneAzione", type: "string", required: false, editable: 'never',
    show: true, titleKey: "statusParam"
  },
]

export const allFieldsApprovazioneAssenza: Fields[] = [
  {
    field: "idAnagraficaSoggetto", titleKey: "nominativeParam", removable: false, required: true, show: true,
    render: (rowData: AssenzaProgrammata) => rowData.cognome + ' ' + rowData.nome
  },
  {
    field: "turnoAssenzaDescrizione", titleKey: "absenceShiftParam", required: false, removable: false, show: true, defaultValue: "",
  },
  {
    field: "dataInizioAssenza", type: "date", required: true, editable: 'onAdd',
    validate: (x: string, helperText: string) => validateDate(x, 1970, null, helperText, true), keyTradValidation: "notValidDateValidation",
    field2Validation: "dataFineAssenza",
    validate2: (x: string, y: string, helperText: string) => validateDateXSmallerThanY(x, y, true, helperText, true), keyTradValidation2: "greaterEqualThanAbsenceEndDateValidation",
    show: true, titleKey: "absenceStartDateParam"
  },
  {
    field: "dataFineAssenza", type: "date", required: true,
    validate: (x: string, helperText: string) => validateDate(x, 1970, null, helperText, true), keyTradValidation: "notValidDateValidation",
    field2Validation: "dataInizioAssenza",
    validate2: (x: string, y: string, helperText: string) => validateDateXGreaterThanY(x, y, true, helperText, true), keyTradValidation2: "smallerEqualThanAbsenceStartDateValidation",
    show: true, titleKey: "absenceEndDateParam"
  },
  {
    field: "approvazioneMotivo", type: 'string', titleKey: "reasonParam", required: false, show: true,
  },
  {
    field: "approvazione", titleKey: "approval", required: false, removable: false, show: true, defaultValue: undefined,
  },
]

export interface AssenzaProgrammata {
  idAnagraficaSoggetto: number,
  anagraficaSoggetto: LookupAnagraficaSoggettoElem[],

  idStruttura: number,
  idDipartimento: number,
  idUnitaOperativa: number,

  idTurnoAssenza: number,
  dataInizioAssenza: string,
  dataFineAssenza: string,

  oraInizioAssenza?: string,
  oraFineAssenza?: string,
  oreAssenza?: number,
  minutiAssenza?: number,

  idArticoloLegge: number,
  noteAssenza?: string,
  approvazioneUtente?: string | null,
  approvazioneDateTime: string | null,
  assenzaParziale: boolean,
  approvazioneRichiesta: boolean,
  approvazioneNegataData: string,
  approvazioneRichiestaMotivo: string,

  assenzaDaApprovare?: boolean,

  partoDataPresunta: string;
  partoDataEffettiva: string;

  version: number,

  // decode
  nome: string,
  cognome: string,

  strutturaNome: string,
  dipartimentoNome: string,
  unitaOperativaNome: string,

  turnoAssenzaDescrizione?: string;
  turnoAssenzaDescrizioneBreve?: string;

  articolo?: string;
  articoloLeggeDescrizione?: string;
}
