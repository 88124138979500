import { Column } from "@material-table/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { anagrafichePath } from "../../../utils/utilconst";
import { getDateDDMMYYYY, getTodayStart } from "../../../utils/utilfunctions"
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import { elementIdProps, elementRenderProps, AnagraficaSoggettiTurnoAbilitato, allFields, allFieldsTurniAbilitati, AnagraficaSoggettiTurnoAbilitatoKeys } from '../../../models/AnagraficaSoggettiTurniAbilitati';
import { Lookup, PDFExtraData } from "../../../models/Utils";
import { Fields } from "../../../models/Fields";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import GeneralForm, { OptionalArgs } from "../forms/GeneralForm";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import { componentInsertPath, componentTabsPath, turnoAbilitatoTabPath } from "../../../utils/innerFuncPaths";
import i18n from "../../../i18n";
import {
  fetchExtendedByIdAndDate,
  resetError as resetErrorAnagraficaSoggetti
} from "../../../store/slices/anagraficaSoggettiSlice";
import {
  insert,
  physicalDel,
  update,
  resetError as resetErrorAnagraficaSoggettiTurnoAbilitato
} from "../../../store/slices/anagraficaSoggettiTurnoAbilitatoSlice";
import {
  fetchAllValidById as fetchQualificaPerAnagraficaSoggetto,
  resetError as resetErrorAnagraficaSoggettiQualifica
} from '../../../store/slices/anagraficaSoggettiQualificaSlice';
import { Box, Button, Paper } from "@material-ui/core";
import InnerComponentViewsAnagrifiche from "./AnagraficaSoggettiComponents/InnerComponentsViewsAnagrafiche";
import _ from 'lodash';
import useInitValid from "./hooks/useInitValid";
import { fetchAllValid as fetchAllValidCombinazioniTurni } from "../../../store/slices/combinazioniTurniSlice";

interface AnagraficaSoggettiTurnoAbilitatoWProps {
  idAnagraficaSoggetto: number,
  anagraficaSoggettiTurnoAbilitato: AnagraficaSoggettiTurnoAbilitato[],
}

const AnagraficaSoggettiTurnoAbilitatoW = ({ idAnagraficaSoggetto, anagraficaSoggettiTurnoAbilitato, }: AnagraficaSoggettiTurnoAbilitatoWProps) => {
  const { t } = useTranslation();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const history = useHistory();
  const dispatch = useAppDispatch();


  const abilitazione = useAppSelector(state => getAbilitazione(state, anagrafichePath));
  const errorBE = useAppSelector(state => state.anagraficaSoggettiTurnoAbilitato.error);
  const resetError = useCallback(() => {
    dispatch(resetErrorAnagraficaSoggetti());
    dispatch(resetErrorAnagraficaSoggettiTurnoAbilitato());

    dispatch(resetErrorAnagraficaSoggettiQualifica())

  }, [dispatch]);

  const anagraficaSoggettiSchedaSanitaria = useAppSelector(state => state.anagraficaSoggetti.anagraficaSoggettoEstesa?.anagraficaSoggettiSchedaSanitaria)
  const anagraficaSoggettiEstesa = useAppSelector(state => state.anagraficaSoggetti.anagraficaSoggettoEstesa);
  const lookupAnagraficaSoggetto = useAppSelector(state => state.anagraficaSoggetti.lookup);
  const combinazioneTurniLookup = useAppSelector(state => state.combinazioniTurni.lookup);
  const validCombinazioneTurni = useAppSelector(state => state.combinazioniTurni.validCombinazioniTurni);

  const idAnagSoggConst = "idAnagraficaSoggetto";
  const idAnagraficaSoggettiSchedaSanitaria = "idAnagraficaSoggettiSchedaSanitaria";
  const combinazioneTurniConst = 'idCombinazioneTurni';

  const anagraficaSoggettiSchedaSanitariaLookup = useMemo(() => {
    let temp: Record<number, string> = {};
    anagraficaSoggettiSchedaSanitaria?.forEach(t =>
      temp[t.idAnagraficaSoggettiSchedaSanitaria] = getDateDDMMYYYY(new Date(t.dataVisita)) + (t.codiceVisitaDescrizione ? ' - ' + t.codiceVisitaDescrizione : '') + (t.idoneitaDescrizione ? ' - ' + t.idoneitaDescrizione : '') + (t.prescrizione ? ' - ' + t.prescrizione : '')
    );
    return temp;
  }, [anagraficaSoggettiSchedaSanitaria])

  const title = t('shiftsTitle');

  const [obj, setObj] = useState<AnagraficaSoggettiTurnoAbilitato | null>(null);

  const [isArchiveOpen, setIsArchiveOpen] = useState<boolean>(false);

  const fixedProps = useMemo(() => {
    return {
      [idAnagSoggConst]: idAnagraficaSoggetto,
    }
  }, [idAnagraficaSoggetto]);

  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFieldsTurniAbilitati);
  const [columns, setColumns] = useState<Array<Column<AnagraficaSoggettiTurnoAbilitato>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<AnagraficaSoggettiTurnoAbilitato> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,

        }

        if (f.type === 'date') {
          obj.dateSetting = { locale: 'it-IT', format: 'mm/dd/yyyy' }
        }

        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as AnagraficaSoggettiTurnoAbilitatoKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          };
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = false;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        /*+++*/
        switch (f.field) {
          case idAnagraficaSoggettiSchedaSanitaria:
            obj.lookup = anagraficaSoggettiSchedaSanitariaLookup;
            break;
          case combinazioneTurniConst:
            obj.lookup = combinazioneTurniLookup;
            break;
        }
        /*+++*/
        return obj;
      }));
  }, [t, anagraficaSoggettiSchedaSanitariaLookup, allFieldsState, combinazioneTurniLookup, validCombinazioneTurni]);

  const statusValidAnagraficaSoggettiTurniAbilitati = useAppSelector(state => state.anagraficaSoggettiTurnoAbilitato.statusValidAnagraficaSoggettiTurniAbilitati);

  const [data, setData] = useState<Array<AnagraficaSoggettiTurnoAbilitato>>([]);

  const formLookup = useMemo(() => {
    const numeroOrdine: Record<string, string> = {};

    if (obj?.numeroOrdine && obj?.idCombinazioneTurni) {
      validCombinazioneTurni.find(elem => elem.idCombinazioneTurni === obj.idCombinazioneTurni)
        ?.combinazioniTurniTurni.forEach(elem => {
          numeroOrdine[elem.numeroOrdine] = elem.turno.descrizione
        })
    }

    return {
      idAnagraficaSoggettiSchedaSanitaria: anagraficaSoggettiSchedaSanitariaLookup,
      idCombinazioneTurni: combinazioneTurniLookup,
      numeroOrdine
    }
  }, [anagraficaSoggettiSchedaSanitariaLookup, combinazioneTurniLookup, obj?.idCombinazioneTurni, obj?.numeroOrdine, validCombinazioneTurni]);

  useEffect(() => {
    setData(anagraficaSoggettiTurnoAbilitato);
  }, [anagraficaSoggettiTurnoAbilitato]);

  useInitValid(
    statusValidAnagraficaSoggettiTurniAbilitati,
    () => dispatch(fetchExtendedByIdAndDate({ idAnagraficaSoggetto }))
  );

  useEffect(() => {
    dispatch(fetchExtendedByIdAndDate({ idAnagraficaSoggetto }));
    dispatch(fetchQualificaPerAnagraficaSoggetto([idAnagraficaSoggetto, getTodayStart()]));
  }, [dispatch, idAnagraficaSoggetto]);

  useEffect(() => {
    dispatch(fetchAllValidCombinazioniTurni());
  }, [dispatch]);


  const insertCallback = () => {
    const temp: AnagraficaSoggettiTurnoAbilitato = {
      idAnagraficaSoggetto: 0,
      dataTurniAbilitati: "",
      idCombinazioneTurni: undefined,
      combinazioneTurni: "",
      motivoVariazione: "",
      idAnagraficaSoggettiSchedaSanitaria: undefined,
      riposoSabato: false,
      riposoDomenicaFestivi: false,
      richiestaPianificazione: false,
      dataTurniAbilitatiPrecedente: "",
      dataInizioTurnoFisso: "",
      turnoFisso: "",
      codiceTurno: "",
      minutiElasticita: 0,
      recuperoRichiesto: false,
      timbraturaSoloPresenza: false,
      version: 0,
    };

    const lastAnagraficaSoggettiTurnoAbilitato: Partial<AnagraficaSoggettiTurnoAbilitato> = {
      ..._.pick(anagraficaSoggettiEstesa, Object.keys(temp)),
      dataTurniAbilitati: undefined,
    };
    setObj(lastAnagraficaSoggettiTurnoAbilitato as AnagraficaSoggettiTurnoAbilitato);
    history.push(anagrafichePath + componentTabsPath + turnoAbilitatoTabPath + componentInsertPath)
  };

  const updateDetailCallback = (AnagraficaSoggettiTurnoAbilitato: AnagraficaSoggettiTurnoAbilitato) => {
    setObj(AnagraficaSoggettiTurnoAbilitato);
    history.push(anagrafichePath + componentTabsPath + turnoAbilitatoTabPath + componentTabsPath)
  };

  // title to be finalize
  const exportDataExtra: PDFExtraData = { head: { title: [], value: [] }, extra: [] };
  Object.keys(fixedProps).forEach(elem => {
    switch (elem) {
      case 'idAnagraficaSoggetto':
        if (exportDataExtra.head)
          exportDataExtra.head['Anagrafica Soggetto'] = lookupAnagraficaSoggetto[fixedProps[elem]];
        break;
    }
  });

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  const formCallback = useCallback((anagraficaSoggettiTurnoAbilitato: AnagraficaSoggettiTurnoAbilitato | null, field: string, optionalArgs: OptionalArgs<AnagraficaSoggettiTurnoAbilitato>) => {
    if (anagraficaSoggettiTurnoAbilitato) {
      if (field === 'idCombinazioneTurni') {
        optionalArgs.setFormLookups(state => {
          const newState = { ...state };

          const newLookup: Lookup = {};

          validCombinazioneTurni.forEach(elem => {
            if (Number(anagraficaSoggettiTurnoAbilitato.idCombinazioneTurni) === elem.idCombinazioneTurni) {
              elem.combinazioniTurniTurni.forEach(i => {
                newLookup[i.numeroOrdine] = i.turno.descrizione
              });
            }
          });

          newState['numeroOrdine'] = newLookup;

          return newState;
        });

        // Resets turno fisso
        optionalArgs.setInternalObj(state => {
          return {
            ...state,
            numeroOrdine: undefined
          }
        })
      }
    }
  }, [validCombinazioneTurni]);

  return <Switch>
    <Route path={anagrafichePath + componentTabsPath + turnoAbilitatoTabPath + componentInsertPath} exact>
      <InnerComponentViewsAnagrifiche
        abilitazione={abilitazione}
        mainUri={anagrafichePath + componentTabsPath + turnoAbilitatoTabPath}
        tabsUri={anagrafichePath + componentTabsPath + turnoAbilitatoTabPath + componentInsertPath}
        tabsView={false}
        buttonTitle={t("cancelLabel")}
        info1={t("update")}
        tabs={[
          {
            label: t("registryTitle"),
            tabPath: "",
            abilitazione: Abilitazione.READ_UPDATE,
            componentIf: (
              <GeneralForm
                readOnly={false}
                language={i18n.language}
                componentPath={anagrafichePath + componentTabsPath + turnoAbilitatoTabPath}
                action={insert}
                fixedProps={fixedProps}
                status={statusValidAnagraficaSoggettiTurniAbilitati}
                error={errorBE}
                update={false}
                fields={allFields}
                translate={t}
                obj={obj}
                lookups={formLookup}
                buttonLabel="saveLabel"
                checksCallback={formCallback}
              />
            ),
            componentElse: <Redirect to={anagrafichePath} />,
          },
        ]}
      />
    </Route>
    <Route path={anagrafichePath + componentTabsPath + turnoAbilitatoTabPath + componentTabsPath} exact>
      <InnerComponentViews
        abilitazione={abilitazione}
        mainUri={anagrafichePath + componentTabsPath + turnoAbilitatoTabPath}
        tabsUri={anagrafichePath + componentTabsPath + turnoAbilitatoTabPath + componentTabsPath}
        tabsView={false}
        buttonTitle={t("cancelLabel")}
        info1={""}
        tabs={[
          {
            label: t("registryTitle"),
            tabPath: "",
            abilitazione: Abilitazione.READ_UPDATE,
            componentIf: (
              <GeneralForm
                readOnly={false}
                language={i18n.language}
                componentPath={anagrafichePath + componentTabsPath + turnoAbilitatoTabPath}
                action={update}
                fixedProps={fixedProps}
                status={statusValidAnagraficaSoggettiTurniAbilitati}
                error={errorBE}
                update={true}
                fields={allFields}
                translate={t}
                lookups={formLookup}
                obj={obj}
                buttonLabel="confirmLabel"
                checksCallback={formCallback}
              />
            ),
            componentElse: <Redirect to={anagrafichePath} />,
          },
        ]}
      />
    </Route>
    <Route path="*">
      <Box flex={1} display='flex' justifyContent='center'>
      </Box>
      <Box mt={6} display="flex" justifyContent='right'>
        <Button
          size="large"
          variant="contained"
          onClick={() => insertCallback()}
        >
          {t("update")}
        </Button>
      </Box>
      <GeneralForm
        readOnly={true}
        language={i18n.language}
        componentPath={anagrafichePath + componentTabsPath + turnoAbilitatoTabPath}
        action={update}
        fixedProps={fixedProps}
        status={statusValidAnagraficaSoggettiTurniAbilitati}
        error={errorBE}
        update={false}
        fields={allFieldsTurniAbilitati}
        translate={t}
        obj={anagraficaSoggettiEstesa?.anagraficaSoggettiTurniAbilitati?.at(0) ?? {}}
        lookups={formLookup}
        checksCallback={formCallback}

      />
      <Box mt={6} display="flex">
        <Box flex={1} display='flex' justifyContent='right'>
          <Button
            size="large"
            variant="contained"
            onClick={() => {
              setIsArchiveOpen(state => !state);
            }}
          >
            {t("archive")}
          </Button>
        </Box>
      </Box>
      {
        isArchiveOpen &&
        <>
          <Box mt={6}>
            <Paper>
              <CrudMaterialTableWithoutLogicDelete
                abilitazione={abilitazione}
                title={title}
                columns={columns}
                columnsButton={true}
                data={data}
                elementIdProps={elementIdProps}
                elementRenderProps={elementRenderProps}
                fetchAllValid={fetchExtendedByIdAndDate}
                physicalDel={physicalDel}
                statusValid={statusValidAnagraficaSoggettiTurniAbilitati}
                errorBE={errorBE}
                fixedProps={fixedProps}
                exportDataExtra={exportDataExtra}
                logoUri={logoUri}
                nullableTextFields={true}
                update={update}
                updateCallback={updateDetailCallback}
                detailCallback={updateDetailCallback}
                localizedDatePicker={true}
                resetErrorCallback={resetError}
                extraOptions={{
                  maxBodyHeight: 460,
                }}
              />
            </Paper>
          </Box>
        </>
      }
    </Route>
  </Switch>
}
export default AnagraficaSoggettiTurnoAbilitatoW;




