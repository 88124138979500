import { Column } from "@material-table/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import { allFields, Ditta, DittaKeys, elementIdProps, elementRenderProps } from "../../../models/Ditte";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchAllDeleted,
  fetchAllValid,
  insert,
  logicalDel,
  physicalDel,
  restore,
  update,
  reset as resetDitte,
  resetError as resetErrorDitte
} from "../../../store/slices/ditteSlice";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { anagraficaSoggettoTabPath, componentTabsPath, dittaTabPath, componentInsertPath } from "../../../utils/innerFuncPaths";
import { dittePath, notFoundPath } from "../../../utils/utilconst";
import GeneralForm, { ChecksCallbackReturn } from "../forms/GeneralForm";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";
import DitteAnagraficaSoggettiW from '../../../core/components/crudWrappers/DitteAnagraficaSoggettiW';
import { Fields } from "../../../models/Fields";
import { calculateMTableHeight } from "../../../utils/styleconst";

const DitteW = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);

  const abilitazione = useAppSelector(state => getAbilitazione(state, dittePath));

  const errorBE = useAppSelector(state => state.ditte.error);
  const resetError = useCallback(() => {
    dispatch(resetErrorDitte());
  }, [dispatch]);

  const title = t('companiesTitle');

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<Ditta>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<Ditta> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field, removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? '',
        }
        if (f.validate2) {
          obj.validate = (rowData) => {
            let resp: boolean | { isValid: boolean, helperText: string } = false;
            let resp2: boolean | { isValid: boolean, helperText: string } = false;

            if (f.validate2)
              resp2 = f.validate2(
                rowData[f.field as DittaKeys],
                rowData[f.field2Validation as DittaKeys],
                f.keyTradValidation2 ? t(f.keyTradValidation2) : ''
              );

            if (f.validate) {
              resp = f.validate(
                rowData[f.field as DittaKeys],
                f.keyTradValidation ? t(f.keyTradValidation) : ''
              );
            }
            if (resp === true) {
              return resp2;
            } else return resp;
          }
        } else if (f.validate) {
          obj.validate = (rowData) => {

            if (f.validate)
              return f.validate(
                rowData[f.field as DittaKeys],
                f.keyTradValidation ? t(f.keyTradValidation) : ''
              );
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = false;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        return obj;
      }));
  }, [allFieldsState, excludedFieldInTable, t]);

  const validDitte = useAppSelector(state => state.ditte.validDitte);
  const deletedDitte = useAppSelector(state => state.ditte.deletedDitte);

  const statusValidDitte = useAppSelector(state => state.ditte.statusValidDitte);
  const statusDeletedDitte = useAppSelector(state => state.ditte.statusDeletedDitte);

  const [data, setData] = useState<Array<Ditta>>([]);

  const [obj, setObj] = useState<Ditta | null>(null);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  useEffect(() => {
    toggleValidDeleted ? setData(validDitte) : setData(deletedDitte);
  }, [validDitte, deletedDitte, toggleValidDeleted]);

  const insertCallback = () => {
    history.push(dittePath + componentInsertPath);
  }

  const updateDetailCallback = (ditt: Ditta) => {
    setObj(ditt);
    history.push(dittePath + componentTabsPath);
  }

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setToggleValidDeleted(true);
      setObj(null);
      setAllFieldsState([]);
      dispatch(resetDitte());
    };
  }, [dispatch]);

  const checksCallback = useCallback(
    (obj: Object | null): ChecksCallbackReturn | void => {
      if (obj) {
        let checkObj = obj as Ditta;
        let end = Date.parse(checkObj.dataFine);
        let start = Date.parse(checkObj.dataInizio);
        if (end.valueOf() < start.valueOf()) {
          return { field: "dataFine", message: t("endDateGreaterEqValidation"), type: 'error' };
        } else return;
      } else return;

    },
    [t]
  )

  return (
    <Switch>
      <Route path={dittePath} exact>
        <CrudMaterialTable
          abilitazione={abilitazione}
          title={title}
          columns={columns}
          columnsButton={true}
          data={data}
          elementIdProps={elementIdProps}
          elementRenderProps={elementRenderProps}
          fetchAllValid={fetchAllValid}
          fetchAllDeleted={fetchAllDeleted}
          logicalDel={logicalDel}
          physicalDel={physicalDel}
          restore={restore}
          statusValid={statusValidDitte}
          statusDeleted={statusDeletedDitte}
          dataValid={toggleValidDeleted}
          setToggleValidDeleted={setToggleValidDeleted}
          errorBE={errorBE}
          insertCallback={insertCallback}
          updateCallback={updateDetailCallback}
          detailCallback={updateDetailCallback}
          logoUri={logoUri}
          localizedDatePicker={true}
          resetErrorCallback={resetError}
          extraOptions={{
            minBodyHeight: calculateMTableHeight(0, false, false),
            maxBodyHeight: calculateMTableHeight(0, false, false)
          }}
        />
      </Route>
      <Route path={dittePath + componentInsertPath} exact>
        <InnerComponentViews abilitazione={abilitazione} mainUri={dittePath} tabsUri={dittePath + componentInsertPath} tabsView={false}
          buttonTitle={t('companiesTitle')} info1={t("newCompanyTitle")}
          tabs={
            [
              {
                label: t("companyTabLabel"), tabPath: "",
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: <GeneralForm
                  readOnly={false}
                  componentPath={dittePath}
                  action={insert}
                  language={i18n.language}
                  status={statusValidDitte}
                  error={errorBE}
                  update={false}
                  fields={allFields}
                  translate={t}
                  checksCallback={checksCallback}
                />,
                componentElse: <Redirect to={dittePath} />
              }
            ]
          } />
      </Route>
      <Route path={dittePath + componentTabsPath}>
        {
          obj ? (
            <InnerComponentViews abilitazione={abilitazione} mainUri={dittePath} tabsUri={dittePath + componentTabsPath} tabsView={true}
              buttonTitle={t('companiesTitle')} info1={obj.denominazione}
              tabs={
                [
                  {
                    label: t("companyTabLabel"),
                    tabPath: dittaTabPath,
                    abilitazione: Abilitazione.READ_UPDATE,
                    componentIf: <GeneralForm
                      readOnly={false}
                      componentPath={dittePath}
                      action={update}
                      language={i18n.language}
                      status={statusValidDitte}
                      error={errorBE}
                      obj={obj}
                      update={true}
                      fields={allFields}
                      translate={t}
                      checksCallback={checksCallback}
                    />,
                    componentElse: <GeneralForm
                      readOnly={true}
                      componentPath={dittePath}
                      action={update}
                      language={i18n.language}
                      status={statusValidDitte}
                      error={errorBE}
                      obj={obj}
                      update={true}
                      fields={allFields}
                      translate={t}
                      checksCallback={checksCallback}
                    />
                  },
                  {
                    label: t("registryTitle"),
                    tabPath: anagraficaSoggettoTabPath,
                    abilitazione: Abilitazione.READ_UPDATE,
                    componentIf: <DitteAnagraficaSoggettiW
                      idDitta={obj.idDitta}
                    />,
                    componentElse: <Redirect to={dittePath} />
                  }
                ]
              } />
          ) : <Redirect to={dittePath} />
        }
      </Route>
      <Route path="*"><Redirect to={notFoundPath} /></Route>
    </Switch>
  )
}
export default DitteW;