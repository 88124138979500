import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, StatusEnum } from '../../models/Utils'
import { cancelById, create, definitiveDeleteById, getById, restoreById, upd, getAllValidByIds, getAllDeletedByIds, getByIds } from '../../services/services.service'
import { AssenzaProgrammata, AssenzaProgrammataKeys, elementIdProps } from '../../models/AssenzeProgrammate';

const microservice = "assenzeprogrammate";
const entity = "assenzeprogrammate";

interface LungheAssenzeState {
  statusValidLungheAssenze: StatusEnum,
  validLungheAssenze: AssenzaProgrammata[],
  statusDeletedLungheAssenze: StatusEnum,
  deletedLungheAssenze: AssenzaProgrammata[],
  lungaAssenza: AssenzaProgrammata | null,
  error: string | null,
}

const initialState: LungheAssenzeState = {
  statusValidLungheAssenze: StatusEnum.Succeeded,
  validLungheAssenze: [],
  statusDeletedLungheAssenze: StatusEnum.Succeeded,
  deletedLungheAssenze: [],
  lungaAssenza: null,
  error: null,
}

export const fetchByIds = createAsyncThunk(entity + '/lungaAssenza/dipendenti/fetchByIds', async (obj: Record<string, string | boolean | null>) => {
  const ids: (string | boolean)[] = ['approvazionerichiesta']
  if (obj) {
    if (obj['soloDaApprovare'] !== null && obj['soloDaApprovare'] !== undefined)
      ids.push(obj['soloDaApprovare'])
    else
      ids.push(true)

    if ((obj['inizioData'] !== null && obj['inizioData'] !== undefined) || (obj['fineData'] !== null && obj['fineData'] !== undefined)) {
      if (obj['inizioData'] !== null && obj['inizioData'] !== undefined)
        ids.push(obj['inizioData'])
      else
        ids.push('1900-01-01T00:00:00')
      if (obj['fineData'] !== null && obj['fineData'] !== undefined)
        ids.push(obj['fineData'])
      else
        ids.push('9999-12-31T00:00:00')
    }
  }

  const response = await getByIds(microservice, entity, ids, ['/lungaAssenza/dipendenti/fetchByIds']);

  return response.data as AssenzaProgrammata[];
});

// cerca/assenzeprogrammate/id/{idAnagraficaSoggetto}/{idTurnoAssenza}/{dataInizioAssenza}
export const fetchById = createAsyncThunk(entity + '/lungaAssenza/dipendenti/fetchById', async (ids: Ids<string>[]) => {
  const args = ids.map(elem => elem.id)

  const response = await getById(microservice, entity, args);
  return response.data as AssenzaProgrammata;
});

// "cerca/assenzeprogrammate/all/valid/lungaassenza/{dataInizioPeriodo}/{dataFinePeriodo}"
export const fetchAllValidByPeriodo = createAsyncThunk(entity + '/lungaAssenza/dipendenti/fetchAllValidByPeriodo', async (obj: { dataInizioPeriodo: string, dataFinePeriodo: string }) => {
  const params = ['lungaassenza', ...Object.values(obj).filter(elem => elem != null)];

  const response = await getAllValidByIds(microservice, entity, params, ['/lungaAssenza/dipendenti/fetchAllValidByPeriodo']);

  return response.data as AssenzaProgrammata[];
});

// "cerca/assenzeprogrammate/all/deleted/lungaassenza/{dataInizioPeriodo}/{dataFinePeriodo}"
export const fetchAllDeletedByPeriodo = createAsyncThunk(entity + '/lungaAssenza/dipendenti/fetchAllDeletedByPeriodo', async (obj: { dataInizioPeriodo: string, dataFinePeriodo: string }) => {
  const params = ['lungaassenza', ...Object.values(obj).filter(elem => elem != null)];

  const response = await getAllDeletedByIds(microservice, entity, params, ['/lungaAssenza/dipendenti/fetchAllDeletedByPeriodo']);

  return response.data as AssenzaProgrammata[];
});

// inserisci/assenzeprogrammate
export const insert = createAsyncThunk(entity + '/lungaAssenza/insert', async (assenzaProgrammata: AssenzaProgrammata) => {
  const response = await create(assenzaProgrammata, microservice, entity);
  return response.data as AssenzaProgrammata;
});

// modifica/assenzeprogrammate
export const update = createAsyncThunk(entity + '/lungaAssenza/update', async (assenzaProgrammata: AssenzaProgrammata) => {
  const response = await upd(assenzaProgrammata, microservice, entity);
  return response.data as AssenzaProgrammata;
});

// elimina/assenzeprogrammate/id/{idAnagraficaSoggetto}/{idTurnoAssenza}/{dataInizioAssenza}
export const logicalDel = createAsyncThunk(entity + '/lungaAssenza/dipendenti/logicalDelete', async (ids: Ids<string>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

// restore/assenzeprogrammate/id/{idAnagraficaSoggetto}/{idTurnoAssenza}/{dataInizioAssenza}
export const restore = createAsyncThunk(entity + '/lungaAssenza/dipendenti/restore', async (ids: Ids<string>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

// delete/assenzeprogrammate/id/{idAnagraficaSoggetto}/{idTurnoAssenza}/{dataInizioAssenza}
export const physicalDel = createAsyncThunk(entity + '/lungaAssenza/dipendenti/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const lungheAssenzeSlice = createSlice({
  name: 'lungheAssenze',
  initialState,
  reducers: {
    reset: () => {
      return initialState;;
    },
    resetError: (state: LungheAssenzeState) => {
      state.error = initialState.error;
      state.statusValidLungheAssenze = initialState.statusValidLungheAssenze;
      state.statusDeletedLungheAssenze = initialState.statusDeletedLungheAssenze;
    }
  },
  extraReducers: builder => {


    // fetchById
    builder.addCase(fetchByIds.pending, (state) => {
      state.statusValidLungheAssenze = StatusEnum.Loading;
    })
    builder.addCase(fetchByIds.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidLungheAssenze = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidLungheAssenze = StatusEnum.Failed;
      }

      state.validLungheAssenze = []
    })
    builder.addCase(fetchByIds.fulfilled, (state, { payload }: PayloadAction<AssenzaProgrammata[]>) => {
      state.statusValidLungheAssenze = StatusEnum.Succeeded;
      state.validLungheAssenze = payload;

    })

    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidLungheAssenze = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidLungheAssenze = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidLungheAssenze = StatusEnum.Failed;
      }
      state.lungaAssenza = null;
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<AssenzaProgrammata>) => {
      state.statusValidLungheAssenze = StatusEnum.Succeeded;
      state.lungaAssenza = payload;
    })

    // fetchAllValidByPeriodo
    builder.addCase(fetchAllValidByPeriodo.pending, (state) => {
      state.statusValidLungheAssenze = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValidByPeriodo.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidLungheAssenze = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidLungheAssenze = StatusEnum.Failed;
      }
      state.validLungheAssenze = [];
    })
    builder.addCase(fetchAllValidByPeriodo.fulfilled, (state, { payload }: PayloadAction<AssenzaProgrammata[]>) => {
      state.statusValidLungheAssenze = StatusEnum.Succeeded;
      state.validLungheAssenze = payload ?? [];
      // state.validAssenzeProgrammate = payload.map((elem, index) => {
      //     return {
      //         ...elem,
      //         approvazioneDateTime: index % 2 === 0 ? null : elem.approvazioneDateTime,
      //         approvazioneUtente: index % 2 === 0 ? null : elem.approvazioneUtente,
      //     }
      // })
    })

    // fetchAllDeletedByPeriodo
    builder.addCase(fetchAllDeletedByPeriodo.pending, (state) => {
      state.statusValidLungheAssenze = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeletedByPeriodo.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedLungheAssenze = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedLungheAssenze = StatusEnum.Failed;
      }
      state.deletedLungheAssenze = [];
    })
    builder.addCase(fetchAllDeletedByPeriodo.fulfilled, (state, { payload }: PayloadAction<AssenzaProgrammata[]>) => {
      state.statusDeletedLungheAssenze = StatusEnum.Succeeded;
      state.deletedLungheAssenze = payload ?? [];
      // state.deletedAssenzeProgrammate = payload.map((elem, index) => {
      //     return {
      //         ...elem,
      //         approvazioneDateTime: index % 2 === 0 ? null : elem.approvazioneDateTime,
      //         approvazioneUtente: index % 2 === 0 ? null : elem.approvazioneUtente,
      //     }
      // })
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidLungheAssenze = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidLungheAssenze = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidLungheAssenze = StatusEnum.Failed;
      }
      state.lungaAssenza = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<AssenzaProgrammata>) => {
      state.statusValidLungheAssenze = StatusEnum.Succeeded;
      state.lungaAssenza = payload;
      state.validLungheAssenze = state.validLungheAssenze.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidLungheAssenze = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidLungheAssenze = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidLungheAssenze = StatusEnum.Failed;
      }
      state.lungaAssenza = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<AssenzaProgrammata>) => {
      state.statusValidLungheAssenze = StatusEnum.Succeeded;
      state.validLungheAssenze = state.validLungheAssenze.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.lungaAssenza = payload;
    })

    // logicalDel
    builder.addCase(logicalDel.pending, (state) => {
      state.statusValidLungheAssenze = StatusEnum.Loading;
    })
    builder.addCase(logicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidLungheAssenze = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidLungheAssenze = StatusEnum.Failed;
      }
    })
    builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidLungheAssenze = StatusEnum.Succeeded;
      const deleted = state.validLungheAssenze.find(el => payload.ids.every(idObj => el[idObj.name as AssenzaProgrammataKeys] === idObj.id));
      if (deleted) {
        deleted.version += 1;
        state.deletedLungheAssenze = state.deletedLungheAssenze.concat([deleted]);
      }
      state.validLungheAssenze = state.validLungheAssenze.filter(el => payload.ids.some(idObj => el[idObj.name as AssenzaProgrammataKeys] !== idObj.id));
    })

    // restore
    builder.addCase(restore.pending, (state) => {
      state.statusDeletedLungheAssenze = StatusEnum.Loading;
    })
    builder.addCase(restore.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedLungheAssenze = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedLungheAssenze = StatusEnum.Failed;
      }
    })
    builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedLungheAssenze = StatusEnum.Succeeded;
      const valid = state.deletedLungheAssenze.find(el => payload.ids.every(idObj => el[idObj.name as AssenzaProgrammataKeys] === idObj.id));
      if (valid) {
        valid.version += 1;
        state.validLungheAssenze = state.validLungheAssenze.concat([valid]);
      }
      state.deletedLungheAssenze = state.deletedLungheAssenze.filter(el => payload.ids.some(idObj => el[idObj.name as AssenzaProgrammataKeys] !== idObj.id));
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusDeletedLungheAssenze = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedLungheAssenze = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedLungheAssenze = StatusEnum.Failed;
      }
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedLungheAssenze = StatusEnum.Succeeded;
      state.deletedLungheAssenze = state.deletedLungheAssenze.filter(el => payload.ids.some(idObj => el[idObj.name as AssenzaProgrammataKeys] !== idObj.id));
    })
  }
});

export const { reset, resetError } = lungheAssenzeSlice.actions;
export default lungheAssenzeSlice.reducer;