import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box, createStyles, Grid, List,
  ListItem,
  ListItemText, makeStyles, Paper, Theme, Select, InputLabel, MenuItem, FormControl, ListSubheader, Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';

import { Ids, Lookup } from '../../../../../models/Utils';
import { sortAscObjectsBy } from '../../../../../utils/utilfunctions';
import InsertSoggettoModal from './InsertSoggettoModal';

/** ANAGRAFICA_SOGGETTI_LIST */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    scrollable: {
      overflow: "auto",
    },
    h100p: {
      height: "100%",
    },
    hListAS: {
      maxHeight: 815
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    },

    // add
    cursorPointer: {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.light,
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.04)',
      },
      '&:hover svg': {
        color: '#888',
      }
    },
    icon: {
      color: '#fff',
    },

    // select
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
    },

    // list con selectedQualifica = 0
    rootList: {
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
    },
    listSection: {
      backgroundColor: 'inherit',
    },
    ul: {
      backgroundColor: 'inherit',
      padding: 0,
    },
    textUnderline: {
      textDecoration: 'underline',
    }
  }),
);

export interface AS {
  idStruttura: number;
  idDipartimento: number;
  idUnitaOperativa: number;
  idAnagraficaSoggetto: number;
  nominativo: string;
  cognome: string;
  nome: string;
  qualificheDescrizione?: Ids<string>[];
  qualificheDescrizioneBreve?: Ids<string>[];
}

interface AnagraficaGroupByQualifica {
  idQual: number,
  qualificaDescrizione: string,
  qualificaDescrizioneBreve: string,
  anagrafiche: AS[],
}

interface AnagraficaSoggettiListProps {
  selectedItem: number,
  handleSelectItem: (idAnagraficaSoggetto: number | string, idQualifica: number) => void,
  setSelectedAnagraficaSoggetto: Dispatch<SetStateAction<number>>,
  listAsState: {
    listAS: AS[],
    setListAS: Dispatch<SetStateAction<AS[]>>,
  },
  lookupAnagrafica: Lookup,
  fixedProps: {
    idStruttura: number | string,
    idDipartimento: number | string,
    idUnitaOperativa: number | string,
    mese: number | null,
    anno: number | null,
  },
  approvato: boolean,
}

const AnagraficaSoggettiList = (props: AnagraficaSoggettiListProps) => {
  const { selectedItem, handleSelectItem, listAsState, setSelectedAnagraficaSoggetto, fixedProps, approvato } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const [selectedIdQualifica, setSelectedIdQualifica] = useState<number>(0);
  const [unfilteredSelectedIdQualifica, setUnfilteredSelectedIdQualifica] = useState<number>();

  const handleChange = (value: number) => {
    setSelectedIdQualifica(value);
    setSelectedAnagraficaSoggetto(-1);
    setUnfilteredSelectedIdQualifica(undefined);
  };

  // viene utilizzato dalla lista dei soggetti
  const [anagraficaGroupByQualifica, setAnagraficaGroupByQualifica] = useState<AnagraficaGroupByQualifica[]>([]);
  useEffect(() => {
    setAnagraficaGroupByQualifica(() => {
      let retval: AnagraficaGroupByQualifica[] = [];

      listAsState.listAS.forEach(anagrafica => {
        anagrafica.qualificheDescrizione?.forEach((qual, index) => {
          if (retval.every(elem => elem.idQual !== qual.id)) {
            retval.push({
              idQual: qual.id,
              qualificaDescrizione: qual.name,
              qualificaDescrizioneBreve: anagrafica?.qualificheDescrizioneBreve ? anagrafica?.qualificheDescrizioneBreve[index]?.name : '',
              anagrafiche: [{
                idStruttura: anagrafica.idStruttura,
                idDipartimento: anagrafica.idDipartimento,
                idUnitaOperativa: anagrafica.idUnitaOperativa,
                idAnagraficaSoggetto: anagrafica.idAnagraficaSoggetto,
                nominativo: anagrafica.nominativo,
                cognome: anagrafica.cognome,
                nome: anagrafica.nome,
              }],
            });
          } else {
            const index = retval.findIndex(elem => elem.idQual === qual.id);

            if (retval[index].anagrafiche.every(elem => elem.idAnagraficaSoggetto !== anagrafica.idAnagraficaSoggetto)) {
              retval[index].anagrafiche.push({
                idStruttura: anagrafica.idStruttura,
                idDipartimento: anagrafica.idDipartimento,
                idUnitaOperativa: anagrafica.idUnitaOperativa,
                idAnagraficaSoggetto: anagrafica.idAnagraficaSoggetto,
                nominativo: anagrafica.nominativo,
                cognome: anagrafica.cognome,
                nome: anagrafica.nome,
              });
            }
          }
        });
      });

      // ordinare tutte le qualifiche in ordine alfabetico
      retval = sortAscObjectsBy(retval, 'qualificaDescrizioneBreve') as AnagraficaGroupByQualifica[];

      retval.unshift({
        idQual: 0,
        qualificaDescrizione: t('allFTitle'),
        qualificaDescrizioneBreve: t('allFTitle'),
        anagrafiche: [],
      });

      // ordinare tutte le anagrafiche di ogni qualifica in ordine alfabetico
      retval.forEach((qual, index, array) => {
        array[index].anagrafiche = sortAscObjectsBy(qual.anagrafiche, 'nominativo') as AS[];
      });

      return retval;
    });
  }, [listAsState.listAS, t]);

  // set default selected qualifica
  useEffect(() => {
    setSelectedIdQualifica(state => {
      return anagraficaGroupByQualifica[0]?.idQual ?? state
    });
  }, [anagraficaGroupByQualifica]);

  listAsState.listAS.sort((a, b) => a.nominativo > b.nominativo ? 1 : -1);

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    return () => {
      setAnagraficaGroupByQualifica([]);
    }
  }, []);

  return <>
    {/* select qualifica */}
    <Paper elevation={4}>
      <Box p={2}>
        <Grid container>
          <Grid item xs>
            {/* Qualifica selection */}
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel id="simple-select-label">{t('qualificationTitle')}</InputLabel>
              <Select
                labelId="simple-select-label"
                id="simple-select"
                value={selectedIdQualifica}
                onChange={event => handleChange(event.target.value as number)}
              >
                {
                  anagraficaGroupByQualifica.map(qual => {
                    return <MenuItem
                      key={qual.idQual}
                      value={qual.idQual}
                    >
                      {qual.qualificaDescrizioneBreve}
                    </MenuItem>
                  })
                }
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Paper>

    {/* lista delle anagrafiche */}
    {
      listAsState.listAS?.length > 0 &&
      <Box mt={2}>
        <Paper className={classes.h100p} elevation={4}>
          {selectedIdQualifica !== 0
            // filtered list
            ? <List className={clsx(classes.scrollable, classes.hListAS)}>
              {anagraficaGroupByQualifica.find(qual => qual.idQual === selectedIdQualifica)?.anagrafiche.map(anagrafica => {
                return <ListItem
                  key={anagrafica.idAnagraficaSoggetto}
                  button
                  selected={anagrafica.idAnagraficaSoggetto === selectedItem}
                  onClick={() => handleSelectItem(anagrafica.idAnagraficaSoggetto, selectedIdQualifica)}
                >
                  <ListItemText primary={anagrafica.nominativo} />
                </ListItem>
              }
              )}
            </List>
            :
            // unfiltered list
            <List className={clsx(classes.rootList, classes.hListAS)} subheader={<li />}>
              {
                anagraficaGroupByQualifica.filter(qual => qual.idQual !== 0)?.map((qualifica) => {
                  return (
                    <li key={'section-' + qualifica.idQual} className={classes.listSection}>
                      <ul className={classes.ul}>
                        <ListSubheader className={clsx(classes.textUnderline)}>
                          <Typography variant='caption'>
                            {qualifica.qualificaDescrizioneBreve}
                          </Typography>
                        </ListSubheader>
                        {
                          qualifica.anagrafiche.map((anagrafica) => {
                            const key = qualifica.idQual + '-' + anagrafica.idAnagraficaSoggetto
                            return <ListItem
                              key={key}
                              button
                              selected={anagrafica.idAnagraficaSoggetto === selectedItem && qualifica.idQual === unfilteredSelectedIdQualifica}
                              onClick={() => {
                                handleSelectItem(key, qualifica.idQual);
                                setUnfilteredSelectedIdQualifica(qualifica.idQual)
                              }}
                            >
                              <ListItemText primary={anagrafica.nominativo} />
                            </ListItem>
                          })
                        }
                      </ul>
                    </li>
                  )
                })
              }
            </List>}
        </Paper>
      </Box>
    }

    {/* buttone aggiungi */}
    {
      !approvato &&
      <Box mt={2}>
        <Paper
          onClick={handleOpen}
          elevation={4}
          className={clsx(classes.cursorPointer)}
        >
          <Box py={1}>
            <Grid container justifyContent='center'>
              <Grid item>
                <AddIcon
                  alignmentBaseline='middle'
                  fontSize='large'
                  className={clsx(classes.icon)}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    }

    {/**
     * Inserimento Anagrafica
     */}
    <InsertSoggettoModal
      fixedProps={fixedProps}
      listAsState={listAsState}
      openState={{
        open: open,
        setOpen: setOpen
      }}
    />
  </>
}

export default AnagraficaSoggettiList;