import { Fields } from "./Fields";
import { UnitaOperativaTurnoNumeroAddetti } from "./UnitaOperativeTurniNumeroAddetti";
import RemoveIcon from '@material-ui/icons/Remove';

export type ConteggioQualificaKeys = keyof ConteggioQualifica;

export const elementIdProps: ConteggioQualificaKeys[] = [];
export const elementRenderProps: ConteggioQualificaKeys[] = [];

export const allFields: Fields[] = [
  {
    field: "qualificaDescrizioneBreve", titleKey: "qualificationParam", required: false, show: true, lookupField: true, defaultGroupOrder: 0
  },

  {
    field: "turnoDescrizione", titleKey: "shiftParam", required: false, show: true, lookupField: true, sort: 'asc'
  },

  {
    field: "numeroPresenze", titleKey: "attendanceNumberParam", type: 'numeric', required: false, show: true,
  },

  {
    field: "numeroReperibilita", titleKey: "availabilityParam", type: 'numeric', required: false, show: true,
  },

  {
    field: "differenzaNumLegale", titleKey: "legalNumberDiffParam", type: 'numeric', required: false, show: true,
    render: (rowData: ConteggioQualifica) => {
      let retval: string | number | JSX.Element = rowData.differenzaNumLegale;

      if (retval < 0) retval = 'Eccedenti ' + Math.abs(retval);
      else if (retval > 0) retval = 'Mancanti ' + retval;
      else retval = <RemoveIcon fontSize='medium' htmlColor="#000" />

      return retval;
    }
  },

  {
    field: "differenzaNumAddetti", titleKey: "employeeNumberDiffParam", type: 'numeric', required: false, show: true,
    render: (rowData: ConteggioQualifica) => {
      let retval: string | number | JSX.Element = rowData.differenzaNumAddetti;

      if (retval < 0) retval = 'Eccedenti ' + Math.abs(retval);
      else if (retval > 0) retval = 'Mancanti ' + retval;
      else retval = <RemoveIcon fontSize='medium' htmlColor="#000" />

      return retval;
    }
  },

  {
    field: "differenzaNumReperibilitaRichieste", titleKey: "requestedAvailabilityNumberDiffParam", type: 'numeric', required: false, show: true,
    render: (rowData: ConteggioQualifica) => {
      let retval: string | number | JSX.Element = rowData.differenzaNumReperibilitaRichieste;

      if (retval < 0) retval = 'Eccedenti ' + Math.abs(retval);
      else if (retval > 0) retval = 'Mancanti ' + retval;
      else retval = <RemoveIcon fontSize='medium' htmlColor="#000" />

      return retval;
    }
  },

  {
    field: "colorMonitoring", titleKey: "statusParam", required: false, show: true,
  },
];

export interface ConteggioQualifica extends UnitaOperativaTurnoNumeroAddetti {

  numeroPresenze: number,
  numeroReperibilita: number,
  numeroLegale: number,
  numeroReperibilitaRichieste: number,
  differenzaNumAddetti: number,
  differenzaNumLegale: number,
  differenzaNumReperibilitaRichieste: number,
  colorMonitoring: 'Bianco' | 'Rosso' | 'Verde' | 'Giallo' | 'Arancione',
}