import { Column } from "@material-table/core";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { allFields, elementIdProps, elementRenderProps, Turno, TurnoKeys } from "../../../models/Turni";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { fetchAllDeleted, fetchAllValid, insert, logicalDel, physicalDel, restore, update, reset as resetTurni, resetError as resetErrorTurni } from "../../../store/slices/turniSlice";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { combinazioniTurniPath, notFoundPath, turniPath } from "../../../utils/utilconst";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";
import {
  AppBar,
  Tabs,
  Tab,
  TextField,
  makeStyles,
  Theme,
  createStyles
} from "@material-ui/core";
import { Fields } from "../../../models/Fields";
import { sortingStringCI } from "../../../utils/utilfunctions";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import { componentInsertPath, componentTabsPath, turniTabPath } from "../../../utils/innerFuncPaths";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import i18n from "../../../i18n";
import CombinazioniTurniW from "./CombinazioniTurniW";
import GeneralForm from "../forms/GeneralForm";
import TabPanel from "../tabs/TabPanel/TabPanel";
import { calculateMTableHeight } from "../../../utils/styleconst";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appbar: {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    },
    indicator: {
      backgroundColor: "#fff",
    },
  }),
);

enum TabIndex {
  TURNI,
  COMBINAZIONI_TURNI,
}

const TurniW = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const dispatch = useAppDispatch();

  const classes = useStyles();

  const logoUri = useAppSelector(state => state.authInfo.logoUri);

  const abilitazione = useAppSelector(state => getAbilitazione(state, turniPath));
  const errorBE = useAppSelector(state => state.turni.error);

  const resetError = useCallback(() => {
    dispatch(resetErrorTurni());
  }, [dispatch]);

  const title = t('shiftsTitle');

  /**
   * Tabs
   */
  const [tabValue, setTabValue] = useState<TabIndex>(TabIndex.TURNI);
  const handleTabIndexChange = (event: React.ChangeEvent<{}>, newValue: TabIndex) => {
    setTabValue(newValue);
    history.push(newValue === TabIndex.TURNI ? turniPath : turniPath + combinazioniTurniPath);
  };
  const handleTabChange = (index: TabIndex) => {
    return {
      id: 'tab-' + index,
      'aria-controls': 'tabpanel-' + index,
    };
  }

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<Turno>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<Turno> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field, removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,

        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as TurnoKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        if (f.type === 'string')
          obj.customSort = (a, b) => sortingStringCI(a.descrizione, b.descrizione);
        /*+++*/
        if (f.field === "ore") {
          obj.editComponent = props => {
            return (
              <TextField
                type="number"
                value={props.value ? props.value : ""}
                onChange={(e) => {
                  (e.target.value === "" || (Number(e.target.value) >= 1 && Number(e.target.value) <= 24)) && props.onChange(e.target.value)
                }
                }
                inputProps={{ "min": 1 }}
                error={props.error}
                //@ts-ignore
                helperText={props.helperText}
              />
            )
          }
        }
        /*+++*/
        return obj;
      })
    );
  }, [allFieldsState, excludedFieldInTable, t]);

  const validTurni = useAppSelector(state => state.turni.validTurni);
  const deletedTurni = useAppSelector(state => state.turni.deletedTurni);

  const statusValidTurni = useAppSelector(state => state.turni.statusValidTurni);
  const statusDeletedTurni = useAppSelector(state => state.turni.statusDeletedTurni);

  const [obj, setObj] = useState<Turno | null>(null);

  const [data, setData] = useState<Array<Turno>>([]);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  useEffect(() => {
    toggleValidDeleted ? setData(validTurni) : setData(deletedTurni);
  }, [validTurni, deletedTurni, toggleValidDeleted]);

  const insertCallback = () => {
    history.push(turniPath + componentInsertPath);
  }

  const updateDetailCallback = (turno: Turno) => {
    setObj(turno);
    history.push(turniPath + componentTabsPath);
  }

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setToggleValidDeleted(true);
      setAllFieldsState([]);
      dispatch(resetTurni());
    };
  }, [dispatch]);

  const localInsert = (obj: Turno) => {
    if (!obj.richiestaApprovazione) {
      obj.richiestaApprovazione = false;
    }

    dispatch(insert(obj));
  }

  return (
    <>
      <Switch>
        <Route path={turniPath + componentInsertPath} exact>
          <InnerComponentViews abilitazione={abilitazione} mainUri={turniPath} tabsUri={turniPath + componentInsertPath} tabsView={false}
            buttonTitle={title} info1={t("newShiftTitle")}
            tabs={
              [
                {
                  label: t("shiftsTitle"),
                  tabPath: "",
                  abilitazione: Abilitazione.READ_UPDATE,
                  componentIf: <GeneralForm
                    readOnly={false}
                    language={i18n.language}
                    componentPath={turniPath}
                    localAction={localInsert}
                    status={statusValidTurni}
                    error={errorBE}
                    update={false}
                    fields={allFields}
                    translate={t}
                  />,
                  componentElse: <Redirect to={turniPath} />
                }
              ]
            } />
        </Route>
        <Route path={turniPath + componentTabsPath}>
          {
            obj ? (
              <InnerComponentViews
                abilitazione={abilitazione}
                mainUri={turniPath}
                tabsUri={turniPath + componentTabsPath}
                tabsView={true}
                buttonTitle={title}
                info1={obj.descrizione ?? ''}
                tabs={
                  [
                    {
                      label: title,
                      tabPath: turniTabPath,
                      abilitazione: Abilitazione.READ_UPDATE,
                      componentIf: <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={turniPath}
                        action={update}
                        status={statusValidTurni}
                        error={errorBE}
                        obj={obj}
                        update={true}
                        fields={allFields}
                        translate={t}
                      />,
                      componentElse: <></>
                    },
                    {
                      label: t("shiftCombinationTabLabel"),
                      tabPath: combinazioniTurniPath,
                      abilitazione: Abilitazione.READ,
                      componentIf: <CombinazioniTurniW
                        idTurno={obj.idTurno}
                        basePath={turniPath + componentTabsPath + combinazioniTurniPath}
                      />,
                      componentElse: <Redirect to={turniPath} />
                    }
                  ]
                } />
            ) : <Redirect to={turniPath} />
          }
        </Route>
        <Route path={turniPath}>
          <>
            <AppBar position="static" className={classes.appbar}>
              <Tabs value={tabValue ?? TabIndex.TURNI} onChange={handleTabIndexChange} classes={{ indicator: classes.indicator }}>
                <Tab label={t("shiftsTabLabel")} {...handleTabChange(TabIndex.TURNI)} />
                <Tab label={t("shiftCombinationTabLabel")} {...handleTabChange(TabIndex.COMBINAZIONI_TURNI)} />
              </Tabs>
            </AppBar>
            <TabPanel value={tabValue} index={TabIndex.TURNI}>
              <CrudMaterialTable
                abilitazione={abilitazione}
                title={title}
                columns={columns}
                columnsButton={true}
                data={data}
                elementIdProps={elementIdProps}
                elementRenderProps={elementRenderProps}
                fetchAllValid={fetchAllValid}
                fetchAllDeleted={fetchAllDeleted}
                logicalDel={logicalDel}
                physicalDel={physicalDel}
                restore={restore}
                insertCallback={insertCallback}
                updateCallback={updateDetailCallback}
                detailCallback={updateDetailCallback}
                dataValid={toggleValidDeleted}
                setToggleValidDeleted={setToggleValidDeleted}
                statusValid={statusValidTurni}
                statusDeleted={statusDeletedTurni}
                errorBE={errorBE}
                logoUri={logoUri}
                resetErrorCallback={resetError}
                extraOptions={{
                  minBodyHeight: calculateMTableHeight(0, true, false),
                  maxBodyHeight: calculateMTableHeight(0, true, false),
                }}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={TabIndex.COMBINAZIONI_TURNI}>
              <CombinazioniTurniW
                basePath={turniPath + combinazioniTurniPath}
              />
            </TabPanel>
          </>
        </Route>
        <Route path="*"><Redirect to={notFoundPath} /></Route>
      </Switch>
    </>
  )
}
export default TurniW;

