import { validateInputTextMinMaxLen } from "../utils/utilfunctions";
import { CombinazioniTurniTurni } from "./CombinazioniTurniTurni";
import { Fields } from "./Fields";

export type CombinazioniTurniKeys = keyof CombinazioniTurni;

export const elementIdProps: CombinazioniTurniKeys[] = ["idCombinazioneTurni"];
export const elementRenderProps: CombinazioniTurniKeys[] = ["descrizioneCombinazione"];

export const allFields: Fields[] = [
  {
    field: "descrizioneCombinazione", type: "string", required: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 1, 255, false, helperText, true), keyTradValidation: "size1to255ParamValidation",
    show: true, titleKey: "descriptionParam"
  },
  {
    field: "descrizioneCombinazioneBreve", type: "string", required: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 1, 255, false, helperText, true), keyTradValidation: "size1to255ParamValidation",
    show: true, titleKey: "descriptionShortParam"
  },
  {
    field: "combinazioniTurniTurni", autocomplete: true, allowMultipleDraggable: true, allowDuplicate: true, required: false,
    show: true, titleKey: "shiftCombinationParam",
    render: (rowData: CombinazioniTurni) => {
      return rowData.combinazioniTurniTurni?.reduce((oldData, newData) => {
        if (oldData === '')
          return newData.turno?.descrizioneBreve;
        return oldData + ' - ' + newData.turno?.descrizioneBreve;
      }, '');
    }
  }
]
export interface CombinazioniTurni {
  idCombinazioneTurni: number;
  descrizioneCombinazioneBreve: string;
  descrizioneCombinazione: string;
  combinazioniTurniTurni: CombinazioniTurniTurni[];
  version: number;
}