import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'

import {
  Modal,
  Fade,
  Paper,
  Grid,
  IconButton,
  Box,
  Backdrop,
  Theme,
  makeStyles,
  createStyles
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import GeneralForm from '../../../forms/GeneralForm'
import { AS } from './AnagraficaSoggettiList';
import { allFieldsNuovoSoggetto, NuovoSoggetto } from '../../../../../models/Pianificazioni';
import { Lookup, LookupObject } from '../../../../../models/Utils';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { createLookup, getDateYYYYMMDD } from '../../../../../utils/utilfunctions';
import { fetchAllValidById as fetchASQualifiche } from '../../../../../store/slices/anagraficaSoggettiQualificaSlice';
import {
  lookupByNameAndIDs as fetchAnagraficheEsterne,
  cleanLookupEsterno as cleanASLookupEsterno
} from '../../../../../store/slices/anagraficaSoggettiUnitaOperativeSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
  }),
);

interface InsertSoggettoModalProps {
  openState: {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>
  },
  listAsState: {
    listAS: AS[],
    setListAS: Dispatch<SetStateAction<AS[]>>,
  },
  fixedProps: {
    idStruttura: number | string,
    idDipartimento: number | string,
    idUnitaOperativa: number | string,
    mese: number | null,
    anno: number | null,
  },
}

const InsertSoggettoModal = ({ openState, listAsState, fixedProps }: InsertSoggettoModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const idAnagraficaSoggetto = 'idAnagraficaSoggetto';

  const [notSelectedAnagrafica, setNotSelectedAnagrafica] = useState<Lookup>({});
  const [notSelectedAnagraficaObject, setNotSelectedAnagraficaObject] = useState<LookupObject>({});

  const lookupAnagrafichePerUnitaOperativa = useAppSelector(state => state.anagraficaSoggettiUnitaOperative.lookup);
  const lookupAnagraficheEsterne = useAppSelector(state => state.anagraficaSoggettiUnitaOperative.lookupEsterno);
  const lookupAnagraficheEsterneNomeCognome = useAppSelector(state => state.anagraficaSoggettiUnitaOperative.lookupEsternoNomeCognome);
  const qualificheAbilitate = useAppSelector(state => state.anagraficaSoggettiQualifica.qualificheAbilitate);

  const qualificheDescrizioneAbilitateLookup = useMemo(() => {
    return createLookup(qualificheAbilitate, 'idQualifica', ['descrizione']);
  }, [qualificheAbilitate]);

  const qualificheDescrizioneBreveAbilitateLookup = useMemo(() => {
    return createLookup(qualificheAbilitate, 'idQualifica', ['descrizioneBreve']);
  }, [qualificheAbilitate]);

  useEffect(() => {
    setNotSelectedAnagrafica(() => {
      let newState = { ...lookupAnagraficheEsterne } ?? {};

      const addedAnagrafica = listAsState.listAS.map(elem => elem.idAnagraficaSoggetto.toString());

      Object.entries(lookupAnagrafichePerUnitaOperativa ?? {})
        .filter(([id, _]) => !addedAnagrafica.includes(id))
        .forEach(([id, value]) => { newState[id] = value });

      return newState;
    });

    setNotSelectedAnagraficaObject(() => {
      let newState = { ...lookupAnagraficheEsterneNomeCognome } ?? {};

      const addedAnagrafica = listAsState.listAS.map(elem => elem.idAnagraficaSoggetto.toString());

      Object.entries(lookupAnagrafichePerUnitaOperativa ?? {})
        .filter(([id, _]) => !addedAnagrafica.includes(id))
        .forEach(([id, value]) => {
          newState[id] = { nominativo: value };
        });

      return newState;
    });
  }, [listAsState.listAS, lookupAnagraficheEsterne, lookupAnagraficheEsterneNomeCognome, lookupAnagrafichePerUnitaOperativa]);

  /**
   * Search token and save results to slice. Called by autocomplete in GeneralForm.
   * @param {string} token - string to be searched
   * @param {number} minLen - minimum acceptable length of token
   */
  const searchAction = (token: string, minLen: number = 3) => {
    if (token.length === minLen && fixedProps.anno && null !== fixedProps.mese) {
      dispatch(fetchAnagraficheEsterne({
        idStruttura: Number(fixedProps.idStruttura),
        idDipartimento: Number(fixedProps.idDipartimento),
        idUnitaOperativa: Number(fixedProps.idUnitaOperativa),
        dataRif: getDateYYYYMMDD(new Date(fixedProps.anno, fixedProps.mese)) + 'T00:00:00',
        token: token,
      }))
    }
    else if (token.length === 0) {
      dispatch(cleanASLookupEsterno());
    }
  }

  const handleClose = () => {
    openState.setOpen(false);
  };

  const addListAS = (object: NuovoSoggetto) => {
    listAsState.setListAS(state => {
      const newObject: AS = {
        idStruttura: fixedProps.idStruttura as number,
        idDipartimento: fixedProps.idDipartimento as number,
        idUnitaOperativa: fixedProps.idUnitaOperativa as number,
        idAnagraficaSoggetto: Number(object.idAnagraficaSoggetto),
        nominativo: notSelectedAnagrafica[object.idAnagraficaSoggetto] || (notSelectedAnagraficaObject[object.idAnagraficaSoggetto]
          ? (notSelectedAnagraficaObject[object.idAnagraficaSoggetto] as Record<string, string>)['nominativo']
          : ''),
        cognome: notSelectedAnagraficaObject[object.idAnagraficaSoggetto]
          ? (notSelectedAnagraficaObject[object.idAnagraficaSoggetto] as Record<string, string>)['cognome']
          : '',
        nome: notSelectedAnagraficaObject[object.idAnagraficaSoggetto]
          ? (notSelectedAnagraficaObject[object.idAnagraficaSoggetto] as Record<string, string>)['nome']
          : '',
        qualificheDescrizione: [
          {
            name: qualificheDescrizioneAbilitateLookup[object.idQualifica],
            id: Number(object.idQualifica)
          }
        ],
        qualificheDescrizioneBreve: [
          {
            name: qualificheDescrizioneBreveAbilitateLookup[object.idQualifica],
            id: Number(object.idQualifica)
          }
        ],
      }
      return [...state, newObject]
    });
    handleClose();
  };

  const formCallback = useCallback((formObject: NuovoSoggetto | null, field: string) => {
    if (formObject) {
      if (field === idAnagraficaSoggetto) {
        const _idAnagraficaSoggetto = formObject[idAnagraficaSoggetto];

        if (_idAnagraficaSoggetto) {
          dispatch(
            fetchASQualifiche([
              _idAnagraficaSoggetto,
              getDateYYYYMMDD(new Date(Number(fixedProps.anno), Number(fixedProps.mese) + 1)) + 'T00:00:00',
            ])
          );
        }
      }
    }
  }, [dispatch, fixedProps.anno, fixedProps.mese]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openState.open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={openState.open}>
        <Paper elevation={2}>
          <Grid container justifyContent='flex-end'>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Box p={2}>
            <GeneralForm
              fields={allFieldsNuovoSoggetto}
              translate={t}
              localAction={addListAS}
              status={''}
              error={''}
              readOnly={false}
              update={false}
              lookups={{
                idAnagraficaSoggetto: notSelectedAnagrafica,
                idQualifica: qualificheDescrizioneBreveAbilitateLookup,
              }}
              checksCallback={formCallback}
              autoCompleteSearchAction={searchAction}
            />
          </Box>
        </Paper>
      </Fade>
    </Modal>
  )
}

export default InsertSoggettoModal