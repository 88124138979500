import { useAppSelector } from "../../store/hooks";

const usePianificazione = () => {
  const isDirty = useAppSelector(state => state.pianificazione.isDirty);

  const unsavedChangesPrompt = () => {
    let ok = true;

    if (isDirty) {
      ok = window.confirm('Esistono delle modifiche non salvate. Prosegui?');
    }

    return ok;
  }

  return { unsavedChangesPrompt };
}

export default usePianificazione;