import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, getByIds, upd, } from '../../services/services.service'
import { AnagraficaSoggettiTurnoAbilitato } from '../../models/AnagraficaSoggettiTurniAbilitati';

const microservice = "anagraficasoggetti";
const entity = "anagraficasoggettiturniabilitati";

export interface TurniAbilitati {
  idTurno: number,
  descrizioneBreve: string,
  descrizione: string,
  ore: number,
  minuti: number,
}

interface AnagraficaSoggettiTurnoAbilitatoState {
  statusValidAnagraficaSoggettiTurniAbilitati: StatusEnum,
  validAnagraficaSoggettiTurniAbilitati: AnagraficaSoggettiTurnoAbilitato[],
  anagraficaSoggettiTurnoAbilitato: AnagraficaSoggettiTurnoAbilitato | null,
  turniAbilitati: TurniAbilitati[],
  error: string | null
}

const initialState: AnagraficaSoggettiTurnoAbilitatoState = {
  statusValidAnagraficaSoggettiTurniAbilitati: StatusEnum.Succeeded,
  validAnagraficaSoggettiTurniAbilitati: [],
  anagraficaSoggettiTurnoAbilitato: null,
  turniAbilitati: [],
  error: null
}

// get cerca/anagraficasoggettiturniabilitati/lookupTurniAbilitati/{idStruttura}/{idAnagraficaSoggetto}/{dataRiferimento}
export const fetchAllValidById = createAsyncThunk(entity + '/fetchAllValidById', async (ids: any[]) => {
  const newIds = ['lookupTurniAbilitati', ...ids]
  const response = await getByIds(microservice, entity, newIds, ['/fetchAllValidById']);
  return response.data as TurniAbilitati[];
});

export const insert = createAsyncThunk(entity + '/insert', async (anagraficaSoggettiTurnoAbilitato: AnagraficaSoggettiTurnoAbilitato) => {
  if (!anagraficaSoggettiTurnoAbilitato.riposoSabato) {
    anagraficaSoggettiTurnoAbilitato.riposoSabato = false;
  }
  if (!anagraficaSoggettiTurnoAbilitato.riposoDomenicaFestivi) {
    anagraficaSoggettiTurnoAbilitato.riposoDomenicaFestivi = false;
  }
  if (!anagraficaSoggettiTurnoAbilitato.richiestaPianificazione) {
    anagraficaSoggettiTurnoAbilitato.richiestaPianificazione = false;
  }

  const response = await create(anagraficaSoggettiTurnoAbilitato, microservice, entity);
  return response.data as AnagraficaSoggettiTurnoAbilitato;
});

export const update = createAsyncThunk(entity + '/update', async (anagraficaSoggettiTurnoAbilitato: AnagraficaSoggettiTurnoAbilitato) => {
  const response = await upd(anagraficaSoggettiTurnoAbilitato, microservice, entity);
  return response.data as AnagraficaSoggettiTurnoAbilitato;
});

export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const anagraficaSoggettiTurnoAbilitatoSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: AnagraficaSoggettiTurnoAbilitatoState) => {
      return initialState;;
    },
    resetError: (state: AnagraficaSoggettiTurnoAbilitatoState) => {
      state.error = initialState.error;
      state.statusValidAnagraficaSoggettiTurniAbilitati = initialState.statusValidAnagraficaSoggettiTurniAbilitati;
    }
  },
  extraReducers: builder => {
    // fetchAllValidById
    builder.addCase(fetchAllValidById.pending, (state) => {
      state.statusValidAnagraficaSoggettiTurniAbilitati = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValidById.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiTurniAbilitati = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiTurniAbilitati = StatusEnum.Failed;
      }
      state.turniAbilitati = [];
    })
    builder.addCase(fetchAllValidById.fulfilled, (state, { payload }: PayloadAction<TurniAbilitati[]>) => {
      state.statusValidAnagraficaSoggettiTurniAbilitati = StatusEnum.Succeeded;
      state.turniAbilitati = payload ?? [];
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidAnagraficaSoggettiTurniAbilitati = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiTurniAbilitati = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiTurniAbilitati = StatusEnum.Failed;
      }
      state.anagraficaSoggettiTurnoAbilitato = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettiTurnoAbilitato>) => {
      state.statusValidAnagraficaSoggettiTurniAbilitati = StatusEnum.Succeeded;
      state.anagraficaSoggettiTurnoAbilitato = payload
    })

    //update
    builder.addCase(update.pending, (state) => {
      state.statusValidAnagraficaSoggettiTurniAbilitati = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiTurniAbilitati = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiTurniAbilitati = StatusEnum.Failed;
      }
      state.anagraficaSoggettiTurnoAbilitato = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettiTurnoAbilitato>) => {
      state.statusValidAnagraficaSoggettiTurniAbilitati = StatusEnum.Succeeded;
      state.anagraficaSoggettiTurnoAbilitato = payload
    })

    // physicalDelete
    builder.addCase(physicalDel.pending, (state) => {
      state.statusValidAnagraficaSoggettiTurniAbilitati = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggettiTurniAbilitati = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggettiTurniAbilitati = StatusEnum.Failed;
      }
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidAnagraficaSoggettiTurniAbilitati = StatusEnum.Succeeded;
    })
  }
});

export const { reset, resetError } = anagraficaSoggettiTurnoAbilitatoSlice.actions;
export default anagraficaSoggettiTurnoAbilitatoSlice.reducer;