import { Column } from "@material-table/core";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  elementIdProps,
  elementRenderProps,
  AssenzaProgrammata,
  allFieldsRichiesta,
  AssenzaProgrammataKeys,
} from "../../../models/AssenzeProgrammate";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchAllValidByAnagrafica,
  fetchAllDeletedByAnagrafica,
  insert,
  logicalDel,
  physicalDel,
  restore,
  update,
  reset as resetRichiesteAssenzeProgrammate,
  resetError as resetErrorRichiesteAssenzeProgrammate
} from "../../../store/slices/assenzeProgrammateSlice";
import { fetchAllLookupByAbilitazione, reset as resetAnagraficaSoggetti, resetError as resetErrorAnagrificheSoggetti } from '../../../store/slices/anagraficaSoggettiSlice';
import { fetchAllValid as fetchArticoliLegge, reset as resetArticoliLegge, resetError as resetErrorArticoliLegge } from "../../../store/slices/articoliLeggeSlice";
import { fetchAllValid as fetchAssenze, reset as resetAssenze, resetError as resetErrorAssenze } from "../../../store/slices/assenzeSlice";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { richiesteAssenzeProgrammatePath } from "../../../utils/utilconst";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";
import Paper from "@material-ui/core/Paper";
import { Box, Typography } from "@material-ui/core";
import SimpleIdSelects from "../selects/SimpleIdSelects";
import { PDFExtraData } from "../../../models/Utils";
import { Fields } from "../../../models/Fields";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import { componentInsertPath, componentTabsPath } from "../../../utils/innerFuncPaths";
import GeneralForm, { OptionalArgs } from "../forms/GeneralForm";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import i18n from "../../../i18n";
import { differenceInMinutes, minutesInHour } from "date-fns";
import { getDateYYYYMMDD_BackEnd } from "../../../utils/utilfunctions";

const RichiesteAssenzeProgrammateW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const logoUri = useAppSelector((state) => state.authInfo.logoUri);
  const abilitazione = useAppSelector((state) => getAbilitazione(state, richiesteAssenzeProgrammatePath));
  const title = t("plannedAbsencesRequestTitle");

  const soggettoSelectName = useMemo(() => t('subjectRegistryTitle'), [t]);
  const soggettoSelectLabel = useMemo(() => t('registrySelect'), [t]);

  const resetError = useCallback(() => {
    dispatch(resetErrorAnagrificheSoggetti());
    dispatch(resetErrorArticoliLegge());
    dispatch(resetErrorAssenze())
    dispatch(resetErrorRichiesteAssenzeProgrammate());
  }, [dispatch]);

  const [states, setStates] = useState<{
    [selectName: string]: string | number | null;
  }>({ [soggettoSelectName]: null });

  useEffect(() => {
    setStates({
      [soggettoSelectName]: null,
    });
  }, [soggettoSelectName]);

  const fixedProps = useMemo(() => {
    return {
      idAnagraficaSoggetto: states[soggettoSelectName],
    };
  }, [states, soggettoSelectName]);

  const idAnagraficaSoggettoConst = "idAnagraficaSoggetto";
  const idTurnoAssenzaConst = "idTurnoAssenza";
  const idArticoloLeggeConst = "idArticoloLegge";

  const dataInizioAssenzaConst = 'dataInizioAssenza';
  const dataFineAssenzaConst = 'dataFineAssenza';

  const oraInizioAssenzaConst = "oraInizioAssenza";
  const oraFineAssenzaConst = "oraFineAssenza";
  const oreAssenzaConst = 'oreAssenza';
  const minutiAssenzaConst = 'minutiAssenza';

  // const lookupStr = useAppSelector((state) => state.strutture.lookup);
  // const lookupDip = useAppSelector((state) => state.dipartimentiFiltered.lookup);
  // const lookupUnitOp = useAppSelector((state) => state.unitaOperativeFiltered.lookup);
  const lookupAnagraficaSoggettoPerAbilitazione = useAppSelector((state) => state.anagraficaSoggetti.lookup);
  const lookupTurnoAssenza = useAppSelector((state) => state.assenze.lookupAssenzaProgrammata);
  const lookupArticoloLegge = useAppSelector((state) => state.articoliLegge.lookup);

  const validAssenze = useAppSelector(state => state.assenze.validAssenze);

  const utenteLoggato = useAppSelector(state => state.utentiLoggato);

  useEffect(() => {
    dispatch(fetchAllLookupByAbilitazione());
    dispatch(fetchAssenze());
    dispatch(fetchArticoliLegge());
  }, [dispatch]);

  const [obj, setObj] = useState<AssenzaProgrammata | null>(null);

  const updateFields = useCallback((field: Fields, isParziale: boolean): Fields => {
    if ([oraInizioAssenzaConst, oraFineAssenzaConst, oreAssenzaConst, minutiAssenzaConst].includes(field.field)) {
      return {
        ...field,
        showOn: !isParziale ? 'hidden' : undefined,
        required: [oraInizioAssenzaConst, oraFineAssenzaConst].includes(field.field) ? isParziale : field.required
      }
    }

    if ([dataFineAssenzaConst].includes(field.field)) {
      return {
        ...field,
        formHidden: isParziale
      }
    }

    if ([dataInizioAssenzaConst].includes(field.field)) {
      return {
        ...field,
      }
    }

    return field;
  }, []);

  const insertCallback = () => {
    setAllFieldsState(state => {
      return state.map(field => {
        if ([oraInizioAssenzaConst, oraFineAssenzaConst, oreAssenzaConst, minutiAssenzaConst].includes(field.field)) {
          return {
            ...field,
            showOn: 'hidden'
          }
        }

        return field;
      });
    });

    history.push(richiesteAssenzeProgrammatePath + componentInsertPath);
  }

  const updateCallback = (assenzaProgrammate: AssenzaProgrammata) => {
    setObj(assenzaProgrammate);

    setAllFieldsState(state => {
      const isParziale = validAssenze.find(i => i.idTurnoAssenza === Number(assenzaProgrammate.idTurnoAssenza))?.assenzaParziale ?? false;
      return state.map(field => updateFields(field, isParziale));
    });

    history.push(richiesteAssenzeProgrammatePath + componentTabsPath);
  }

  const formLookups = useMemo(() => {
    return {
      [idTurnoAssenzaConst]: lookupTurnoAssenza,
      [idArticoloLeggeConst]: lookupArticoloLegge
    }
  }, [lookupArticoloLegge, lookupTurnoAssenza]);

  const formCallback = useCallback((formData: AssenzaProgrammata | null, field: string, extraArgs: OptionalArgs<AssenzaProgrammata>) => {
    if (formData) {
      const isParziale = validAssenze.find(i => i.idTurnoAssenza === Number(formData.idTurnoAssenza))?.assenzaParziale ?? false;
      const daApprovare = validAssenze.find(i => i.idTurnoAssenza === Number(formData.idTurnoAssenza))?.richiestaApprovazione ?? false;

      // insert only
      // idTurnoAssenza
      if (field === idTurnoAssenzaConst) {
        extraArgs.setInternalObj(state => {
          return {
            ...state,
            assenzaDaApprovare: !!daApprovare
          }
        });

        if (!isParziale) {
          extraArgs.setInternalObj(state => {
            return {
              ...state,
              [oraInizioAssenzaConst]: undefined,
              [oraFineAssenzaConst]: undefined,
              [oreAssenzaConst]: undefined,
              [minutiAssenzaConst]: undefined,
            }
          });
        }

        extraArgs.setFields(state => {
          return state.map(field => updateFields(field, isParziale));
        });
      }

      // oreAssenza || minutiAssenza
      if ([oraInizioAssenzaConst, oraFineAssenzaConst].includes(field)) {
        if (formData[oraInizioAssenzaConst] && formData[oraFineAssenzaConst]) {
          const startDate = new Date();
          const endDate = new Date();

          const startTime = formData[oraInizioAssenzaConst];
          const endTime = formData[oraFineAssenzaConst];

          startDate.setHours(Number(startTime?.split(':')[0]));
          startDate.setMinutes(Number(startTime?.split(':')[1]));

          endDate.setHours(Number(endTime?.split(':')[0]));
          endDate.setMinutes(Number(endTime?.split(':')[1]));

          const totalMinutes = differenceInMinutes(endDate, startDate);
          const hours = Math.floor(totalMinutes / minutesInHour);
          const minutes = totalMinutes % minutesInHour;

          extraArgs.setInternalObj(state => {
            return {
              ...state,
              [oreAssenzaConst]: hours,
              [minutiAssenzaConst]: minutes
            };
          })
        }
      }

      if (isParziale && [dataInizioAssenzaConst, idTurnoAssenzaConst].includes(field)) {
        extraArgs.setInternalObj(state => {
          return {
            ...state,
            [dataFineAssenzaConst]: state[dataInizioAssenzaConst]
          }
        })
      }
    }
  }, [updateFields, validAssenze]);

  const localActionInsert = (formData: AssenzaProgrammata) => {
    const payload = {
      ...formData
    };

    if (payload.assenzaDaApprovare === false) {
      payload.approvazioneDateTime = getDateYYYYMMDD_BackEnd(new Date());
      payload.approvazioneUtente = utenteLoggato.nomeUtente;
    }

    delete payload.assenzaDaApprovare;

    dispatch(insert(payload));
  }

  const localActionUpdate = (formData: AssenzaProgrammata) => {
    const payload = {
      ...formData
    };

    if (payload.assenzaDaApprovare === true) {
      payload.approvazioneDateTime = null;
      payload.approvazioneUtente = null;
    }

    delete payload.assenzaDaApprovare;

    dispatch(update(payload));
  }

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFieldsRichiesta);
  const [columns, setColumns] = useState<Array<Column<AssenzaProgrammata>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<AssenzaProgrammata> = {
          title: f.titleKey ? t(f.titleKey) : "",
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue,
        };

        if (f.validate2) {
          obj.validate = (rowData) => {
            let resp: boolean | { isValid: boolean, helperText: string } = false;
            let resp2: boolean | { isValid: boolean, helperText: string } = false;

            if (f.validate2)
              resp2 = f.validate2(
                rowData[f.field as AssenzaProgrammataKeys],
                rowData[f.field2Validation as AssenzaProgrammataKeys],
                f.keyTradValidation2 ? t(f.keyTradValidation2) : ''
              );

            if (f.validate) {
              resp = f.validate(
                rowData[f.field as AssenzaProgrammataKeys],
                f.keyTradValidation ? t(f.keyTradValidation) : ''
              );
            }
            if (resp === true) {
              return resp2;
            } else return resp;
          }
        } else if (f.validate) {
          obj.validate = (rowData) => {
            if (f.validate)
              return f.validate(
                rowData[f.field as AssenzaProgrammataKeys],
                f.keyTradValidation ? t(f.keyTradValidation) : ''
              );
            return false;
          }
        }

        if (!f.show) {
          obj.hidden = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        /*+++*/
        if (f.field === idAnagraficaSoggettoConst) obj.lookup = lookupAnagraficaSoggettoPerAbilitazione;
        else if (f.field === idTurnoAssenzaConst) obj.lookup = lookupTurnoAssenza;
        else if (f.field === idArticoloLeggeConst) obj.lookup = { null: t("fieldNullSelect"), ...lookupArticoloLegge };
        /*+++*/
        return obj;
      })
    );
  }, [lookupTurnoAssenza, lookupArticoloLegge, lookupAnagraficaSoggettoPerAbilitazione, t, allFieldsState, excludedFieldInTable]);

  const errorBE = useAppSelector((state) => state.assenzeProgrammate.error);

  const validAssenzeProgrammate = useAppSelector((state) => state.assenzeProgrammate.validAssenzeProgrammate);
  const deletedAssenzeProgrammate = useAppSelector((state) => state.assenzeProgrammate.deletedAssenzeProgrammate);

  const statusValidAssenzeProgrammate = useAppSelector((state) => state.assenzeProgrammate.statusValidAssenzeProgrammate);
  const statusDeletedAssenzeProgrammate = useAppSelector((state) => state.assenzeProgrammate.statusDeletedAssenzeProgrammate);

  const [data, setData] = useState<Array<AssenzaProgrammata>>([]);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  useEffect(() => {
    toggleValidDeleted ? setData(validAssenzeProgrammate) : setData(deletedAssenzeProgrammate);
  }, [validAssenzeProgrammate, deletedAssenzeProgrammate, toggleValidDeleted]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setToggleValidDeleted(true);
      setAllFieldsState([]);
      setStates({});
      dispatch(resetAnagraficaSoggetti());
      dispatch(resetArticoliLegge());
      dispatch(resetAssenze());
      dispatch(resetRichiesteAssenzeProgrammate());

    };
  }, [dispatch]);

  // title to be finalize
  const exportDataExtra: PDFExtraData = { head: { title: [], value: [] }, extra: [] };
  // Object.keys(fixedProps).forEach(elem => {
  //   switch (elem) {
  //     case 'idStruttura':
  //     if(exportDataExtra.head)
  //     exportDataExtra.head['Struttura'] = fixedProps[elem] ? lookupStr[fixedProps[elem]] : '';
  //       break;
  //     case 'idDipartimento':
  //     if(exportDataExtra.head)
  //     exportDataExtra.head['Dipartimento'] = fixedProps[elem] ? lookupDip[fixedProps[elem]] : '';
  //       break;
  //     case 'idUnitaOperativa':
  //     if(exportDataExtra.head)
  //     exportDataExtra.head['Unità Operativa'] = fixedProps[elem] ? lookupUnitOp[fixedProps[elem]] : '';
  //       break;
  //   }
  // });

  return (
    <>
      <Paper elevation={2}>
        <Box p={4}>
          <SimpleIdSelects
            selectsArray={[
              {
                name: soggettoSelectName,
                lookup: lookupAnagraficaSoggettoPerAbilitazione,
                label: soggettoSelectLabel,
                disabled: false,
                breakpoints: { xs: 12, md: 6, sm: 12, lg: 4 }
              },
            ]}
            states={states}
            setStates={setStates}
          />
        </Box>
      </Paper>
      <Box marginTop={2}>
        {states[soggettoSelectName]
          ? (
            <Box marginTop={2}>
              <Switch>
                <Route path={richiesteAssenzeProgrammatePath} exact>
                  <CrudMaterialTable
                    abilitazione={abilitazione}
                    title={title}
                    columns={columns}
                    columnsButton={true}
                    data={data}
                    elementIdProps={elementIdProps}
                    elementRenderProps={elementRenderProps}
                    fetchAllValid={fetchAllValidByAnagrafica}
                    fetchAllDeleted={fetchAllDeletedByAnagrafica}
                    insertCallback={insertCallback}
                    updateCallback={updateCallback}
                    logicalDel={logicalDel}
                    physicalDel={physicalDel}
                    restore={restore}
                    dataValid={toggleValidDeleted}
                    setToggleValidDeleted={setToggleValidDeleted}
                    statusValid={statusValidAssenzeProgrammate}
                    statusDeleted={statusDeletedAssenzeProgrammate}
                    errorBE={errorBE}
                    logoUri={logoUri}
                    localizedDatePicker={true}
                    fixedProps={fixedProps}
                    exportDataExtra={exportDataExtra}
                    isExportLandscape={true}
                    resetErrorCallback={resetError}
                    extraOptions={{
                      maxBodyHeight: 460,
                    }}
                  />
                </Route>
                <Route path={richiesteAssenzeProgrammatePath + componentInsertPath} exact>
                  <InnerComponentViews
                    abilitazione={abilitazione}
                    mainUri={richiesteAssenzeProgrammatePath}
                    tabsUri={richiesteAssenzeProgrammatePath + componentInsertPath}
                    tabsView={false}
                    buttonTitle={t("plannedAbsenceParam")}
                    info1={t("")}
                    tabs={[
                      {
                        label: t(""),
                        tabPath: "",
                        abilitazione: Abilitazione.READ_UPDATE,
                        componentIf: (
                          <GeneralForm
                            readOnly={false}
                            language={i18n.language}
                            componentPath={richiesteAssenzeProgrammatePath}
                            localAction={localActionInsert}
                            status={statusValidAssenzeProgrammate}
                            error={errorBE}
                            update={false}
                            fields={allFieldsState}
                            fixedProps={fixedProps}
                            lookups={formLookups}
                            translate={t}
                            checksCallback={formCallback}
                          />
                        ),
                        componentElse: <Redirect to={richiesteAssenzeProgrammatePath} />,
                      },
                    ]}
                  />
                </Route>
                <Route path={richiesteAssenzeProgrammatePath + componentTabsPath}>
                  <InnerComponentViews
                    abilitazione={abilitazione}
                    mainUri={richiesteAssenzeProgrammatePath}
                    tabsUri={richiesteAssenzeProgrammatePath + componentTabsPath}
                    tabsView={true}
                    buttonTitle={t(title)}
                    info1={''}
                    tabs={[
                      {
                        label: t("plannedAbsenceParam"),
                        tabPath: richiesteAssenzeProgrammatePath,
                        abilitazione: Abilitazione.READ_UPDATE,
                        componentIf: (
                          <GeneralForm
                            readOnly={false}
                            language={i18n.language}
                            componentPath={richiesteAssenzeProgrammatePath}
                            localAction={localActionUpdate}
                            status={statusValidAssenzeProgrammate}
                            error={errorBE}
                            update={true}
                            obj={obj}
                            fields={allFieldsState}
                            fixedProps={fixedProps}
                            lookups={formLookups}
                            translate={t}
                            checksCallback={formCallback}
                          />
                        ),
                        componentElse: <Redirect to={richiesteAssenzeProgrammatePath} />,
                      }
                    ]}
                  />
                </Route>
              </Switch>
            </Box>
          ) : (
            <Paper elevation={2}>
              <Box p={4}>
                <Typography align="center" variant="h5">
                  {soggettoSelectLabel}
                </Typography>
              </Box>
            </Paper>
          )}
      </Box>
    </>
  );
};
export default RichiesteAssenzeProgrammateW;
