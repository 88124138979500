import { Column } from "@material-table/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  elementIdProps,
  elementRenderProps,
  AssenzaProgrammata,
  allFieldsLungaAssenza,
  AssenzaProgrammataKeys,
} from "../../../models/AssenzeProgrammate";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchAllDeletedByPeriodo,
  fetchAllValidByPeriodo,
  insert,
  logicalDel,
  physicalDel,
  restore,
  update,
  reset as resetAssenzeProgrammate,
  resetError as resetErrorAssenzeProgrammate
} from "../../../store/slices/lungheAssenzeSlice";
import {
  fetchAllValid as fetchArticoliLegge,
  reset as resetArticoliLegge,
  resetError as resetErrorArticoliLegge
} from "../../../store/slices/articoliLeggeSlice";
import {
  fetchAllValid as fetchAssenze,
  reset as resetAssenze,
  resetError as resetErrorAssenze
} from "../../../store/slices/assenzeSlice";
import {
  lookup as fetchLookupStrutture,
  reset as resetStruttureSlice,
  resetError as resetStrutture
} from "../../../store/slices/struttureSlice";
import {
  cleanLookup as resetDipartimentiLookup,
  reset as resetDipartimentiFiltered,
  resetError as resetErrorDipartimentiFiltered
} from "../../../store/slices/dipartimentiFilteredSlice";
import {
  cleanLookup as resetUOLookup,
  reset as resetUnitaOperativeFiltered,
  resetError as resetErrorUnitaOperativeFiltered
} from "../../../store/slices/unitaOperativeFilteredSlice";
import {
  reset as resetAnagraficaSoggettiUnitaOperative,
  resetError as resetErrorAnagraficaSoggettiUnitaOperative
} from "../../../store/slices/anagraficaSoggettiUnitaOperativeSlice";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { lungheAssenzePath } from "../../../utils/utilconst";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";
import { Box, FormControl, Grid, TextField, Paper } from "@material-ui/core";
import { PDFExtraData } from "../../../models/Utils";
import { Fields } from "../../../models/Fields";
import { Autocomplete } from "@material-ui/lab";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { getDateYYYYMMDD, getDateYYYYMMDD_BackEnd, isDateValid, isUrlRoot } from "../../../utils/utilfunctions";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import { componentInsertPath, componentTabsPath } from "../../../utils/innerFuncPaths";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import GeneralForm, { OptionalArgs } from "../forms/GeneralForm";
import i18n from "../../../i18n";
import { differenceInMinutes, minutesInHour, setDate } from "date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { fetchLookupByToken as fetchLookupByNominativo } from "../../../store/slices/anagraficaSoggettiSlice";
import { calculateMTableHeight } from "../../../utils/styleconst";

const LungheAssenzeW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const logoUri = useAppSelector((state) => state.authInfo.logoUri);
  const abilitazione = useAppSelector((state) => getAbilitazione(state, lungheAssenzePath));
  const title = t("longAbsenceTitle");
  const history = useHistory();

  const startSelectName = useMemo(() => t("startPeriodTitle"), [t]);
  const startSelectLabel = useMemo(() => t("startDateSelect"), [t]);
  const endSelectName = useMemo(() => t("endPeriodTitle"), [t]);
  const endSelectLabel = useMemo(() => t("endDateSelect"), [t]);

  const resetError = useCallback(() => {
    dispatch(resetErrorAssenzeProgrammate());
    dispatch(resetErrorArticoliLegge());
    dispatch(resetErrorAssenze());
    dispatch(resetStrutture());
    dispatch(resetErrorDipartimentiFiltered());
    dispatch(resetErrorUnitaOperativeFiltered());
    dispatch(resetErrorAnagraficaSoggettiUnitaOperative());
  }, [dispatch]);

  const [states, setStates] = useState<{
    [selectName: string]: string | null;
  }>({
    [startSelectName]: getDateYYYYMMDD_BackEnd(setDate(new Date(), 1), 'START'),
    [endSelectName]: null
  });

  const fixedProps = useMemo(() => {
    return {
      dataInizioPeriodo: states[startSelectName],
      dataFinePeriodo: states[endSelectName]
    };
  }, [states, startSelectName, endSelectName]);

  const idAnagraficaSoggettoConst = "idAnagraficaSoggetto";
  const idTurnoAssenzaConst = "idTurnoAssenza";
  const idArticoloLeggeConst = "idArticoloLegge";

  const dataInizioAssenzaConst = 'dataInizioAssenza';
  const dataFineAssenzaConst = 'dataFineAssenza';

  const oraInizioAssenzaConst = "oraInizioAssenza";
  const oraFineAssenzaConst = "oraFineAssenza";
  const oreAssenzaConst = 'oreAssenza';
  const minutiAssenzaConst = 'minutiAssenza'

  const lookupStr = useAppSelector((state) => state.strutture.lookup);
  const lookupDip = useAppSelector((state) => state.dipartimentiFiltered.lookup);
  const lookupAnagraficaSoggetto = useAppSelector((state) => state.anagraficaSoggetti.lookup);
  const lookupLungheAssenze = useAppSelector((state) => state.assenze.lookupLungheAssenze);
  const lookupArticoloLegge = useAppSelector((state) => state.articoliLegge.lookup);
  const lookupAssenzeValidiFromAssenzaProgrammata = useAppSelector(state => state.assenzeProgrammate.lookupAssenzeValidi);
  const lookupAssenzeEliminatiFromAssenzaProgrammata = useAppSelector(state => state.assenzeProgrammate.lookupAssenzeEliminati);

  const validAssenze = useAppSelector(state => state.assenze.validAssenze);

  const utenteLoggato = useAppSelector(state => state.utentiLoggato);

  const [obj, setObj] = useState<AssenzaProgrammata | null>(null);

  const updateFields = useCallback((field: Fields, isParziale: boolean): Fields => {
    if ([oraInizioAssenzaConst, oraFineAssenzaConst, oreAssenzaConst, minutiAssenzaConst].includes(field.field)) {
      return {
        ...field,
        showOn: !isParziale ? 'hidden' : undefined,
        required: [oraInizioAssenzaConst, oraFineAssenzaConst].includes(field.field) && isParziale
      }
    }

    if ([dataFineAssenzaConst].includes(field.field)) {
      return {
        ...field,
        showOn: isParziale ? 'hidden' : undefined
      }
    }

    if ([dataInizioAssenzaConst].includes(field.field)) {
      return {
        ...field,
        colsNum: isParziale ? 12 : 6
      }
    }

    return field;
  }, []);

  const insertCallback = () => {
    setObj({ assenzaDaApprovare: false } as AssenzaProgrammata)

    setAllFieldsState(state => {
      return state.map(field => {
        if ([oraInizioAssenzaConst, oraFineAssenzaConst, oreAssenzaConst, minutiAssenzaConst].includes(field.field)) {
          return {
            ...field,
            showOn: 'hidden'
          }
        }

        return field;
      });
    });

    history.push(lungheAssenzePath + componentInsertPath);
  }

  const updateCallback = (assenzaProgrammate: AssenzaProgrammata) => {
    setObj(assenzaProgrammate);

    setAllFieldsState(state => {
      const isParziale = validAssenze.find(i => i.idTurnoAssenza === Number(assenzaProgrammate.idTurnoAssenza))?.assenzaParziale ?? false;
      return state.map(field => updateFields(field, isParziale));
    });

    history.push(lungheAssenzePath + componentTabsPath);
  }

  const formLookups = useMemo(() => {
    return {
      [idAnagraficaSoggettoConst]: lookupAnagraficaSoggetto,
      [idTurnoAssenzaConst]: lookupLungheAssenze,
      [idArticoloLeggeConst]: lookupArticoloLegge
    }
  }, [lookupAnagraficaSoggetto, lookupArticoloLegge, lookupLungheAssenze]);

  /**
   * Fetch lookups
   */
  useEffect(() => {
    dispatch(fetchLookupStrutture());
    dispatch(fetchAssenze());
    dispatch(fetchArticoliLegge());
  }, [dispatch]);

  const handleDateChange = (d: Date | null, field?: string) => {
    let fieldDate: string | null = null;

    if (d && isDateValid(getDateYYYYMMDD(d))) {
      fieldDate = getDateYYYYMMDD_BackEnd(d, field === startSelectName ? 'START' : 'END');
    } else if (d) {
      return;
    }

    field && setStates(prev => { return { ...prev, [field]: fieldDate } });
  };

  useEffect(() => {
    dispatch(resetDipartimentiLookup());
    dispatch(resetUOLookup());
  }, [dispatch]);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  const excludedFieldInTable = useMemo((): string[] => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFieldsLungaAssenza);
  const [columns, setColumns] = useState<Array<Column<AssenzaProgrammata>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState
        .filter(f => !excludedFieldInTable.includes(f.field))
        .filter(f => ['both', 'table', undefined, null].includes(f.showOn))
        .map((f) => {
          let obj: Column<AssenzaProgrammata> = {
            title: f.titleKey ? t(f.titleKey) : "",
            field: f.field,
            removable: f.removable ?? !f.required,
            editable: f.editable ? f.editable : "always",
            defaultSort: f.sort,
            emptyValue: f.defaultValue ?? 'N/A',
          };

          if (f.validate2) {
            obj.validate = (rowData) => {
              let resp: boolean | { isValid: boolean, helperText: string } = false;
              let resp2: boolean | { isValid: boolean, helperText: string } = false;

              if (f.validate2) {
                resp2 = f.validate2(
                  rowData[f.field as AssenzaProgrammataKeys],
                  rowData[f.field2Validation as AssenzaProgrammataKeys],
                  f.keyTradValidation2 ? t(f.keyTradValidation2) : ''
                );
              }

              if (f.validate) {
                resp = f.validate(
                  rowData[f.field as AssenzaProgrammataKeys],
                  f.keyTradValidation ? t(f.keyTradValidation) : ''
                );
              }
              if (resp === true) {
                return resp2;
              } else return resp;
            }
          } else if (f.validate) {
            obj.validate = (rowData) => {
              if (f.validate) {
                return f.validate(
                  rowData[f.field as AssenzaProgrammataKeys],
                  f.keyTradValidation ? t(f.keyTradValidation) : ''
                );
              }
              return false;
            }
          }

          if (f.render) obj.render = f.render;

          if (!f.show) {
            obj.hidden = true;
            obj.hiddenByColumnsButton = false;
          }
          if (f.type && f.type !== "image" && f.type !== "file") {
            obj.type = f.type;
          }
          /*+++*/
          if (f.lookupField) {
            switch (f.field) {
              case idAnagraficaSoggettoConst:
                obj.lookup = lookupAnagraficaSoggetto;
                break;
              case idTurnoAssenzaConst:
                obj.lookup = {
                  ...lookupLungheAssenze,
                  ...(toggleValidDeleted ? lookupAssenzeValidiFromAssenzaProgrammata : lookupAssenzeEliminatiFromAssenzaProgrammata)
                };
                break;
              case idArticoloLeggeConst:
                obj.lookup = lookupArticoloLegge;
                break;
            }
          }
          /*+++*/

          // Custom components
          if (f.field === idAnagraficaSoggettoConst) {
            obj.editComponent = (props) => {
              const soggettiData = Object.entries(lookupAnagraficaSoggetto ?? {})
                .map(([key, value]) => [value, key]).sort();

              let id = '';

              return <FormControl fullWidth>
                <Autocomplete
                  freeSolo
                  options={soggettiData}
                  value={lookupAnagraficaSoggetto[id] ?? ""}
                  onChange={(e, val) => {
                    id = val ? val[1] : id;
                    props.onChange(id);
                  }}
                  id={"controllable-states" + f.field}
                  getOptionLabel={(option) => option[0] ?? ''}
                  fullWidth
                  renderInput={(params) => <TextField
                    {...params}
                    required={f.required}
                    margin='dense'
                    error={props.error}
                    helperText={f.keyTradValidation ? t(f.keyTradValidation) : undefined}
                  />}
                />
              </FormControl>
            }
          }

          return obj;
        })
    );
  }, [lookupLungheAssenze, lookupArticoloLegge, lookupAnagraficaSoggetto, t, allFieldsState, excludedFieldInTable, toggleValidDeleted, lookupAssenzeValidiFromAssenzaProgrammata, lookupAssenzeEliminatiFromAssenzaProgrammata]);

  const errorBE = useAppSelector((state) => state.lungheAssenze.error);

  const validLungheAssenze = useAppSelector((state) => state.lungheAssenze.validLungheAssenze);
  const deletedLungheAssenze = useAppSelector((state) => state.lungheAssenze.deletedLungheAssenze);

  const statusValidLungheAssenze = useAppSelector((state) => state.lungheAssenze.statusValidLungheAssenze);
  const statusDeletedLungheAssenze = useAppSelector((state) => state.lungheAssenze.statusDeletedLungheAssenze);

  const [data, setData] = useState<Array<AssenzaProgrammata>>([]);

  useEffect(() => {
    toggleValidDeleted
      ? setData(validLungheAssenze)
      : setData(deletedLungheAssenze);
  }, [validLungheAssenze, deletedLungheAssenze, toggleValidDeleted]);

  const formCallback = useCallback((formData: AssenzaProgrammata | null, field: string, extraArgs: OptionalArgs<AssenzaProgrammata>) => {
    if (formData) {
      const isParziale = validAssenze.find(i => i.idTurnoAssenza === Number(formData.idTurnoAssenza))?.assenzaParziale ?? false;

      if (field === idTurnoAssenzaConst) {
        if (!isParziale) {
          extraArgs.setInternalObj(state => {
            return {
              ...state,
              [oraInizioAssenzaConst]: undefined,
              [oraFineAssenzaConst]: undefined,
              [oreAssenzaConst]: undefined,
              [minutiAssenzaConst]: undefined,
            }
          });
        }

        extraArgs.setFields(state => {
          return state.map(field => updateFields(field, isParziale));
        });
      }

      // oreAssenza || minutiAssenza
      if ([oraInizioAssenzaConst, oraFineAssenzaConst].includes(field)) {
        if (formData[oraInizioAssenzaConst] && formData[oraFineAssenzaConst]) {
          const startDate = new Date();
          const endDate = new Date();

          const startTime = formData[oraInizioAssenzaConst];
          const endTime = formData[oraFineAssenzaConst];

          startDate.setHours(Number(startTime?.split(':')[0]));
          startDate.setMinutes(Number(startTime?.split(':')[1]));

          endDate.setHours(Number(endTime?.split(':')[0]));
          endDate.setMinutes(Number(endTime?.split(':')[1]));

          const totalMinutes = differenceInMinutes(endDate, startDate);
          const hours = Math.floor(totalMinutes / minutesInHour);
          const minutes = totalMinutes % minutesInHour;

          extraArgs.setInternalObj(state => {
            return {
              ...state,
              [oreAssenzaConst]: Number.isNaN(hours) ? undefined : hours,
              [minutiAssenzaConst]: Number.isNaN(minutes) ? undefined : minutes
            };
          })
        }
      }

      if (isParziale && field === dataInizioAssenzaConst) {
        extraArgs.setInternalObj(state => {
          return {
            ...state,
            [dataFineAssenzaConst]: state[dataInizioAssenzaConst]
          }
        })
      }
    }
  }, [updateFields, validAssenze]);

  /**
   * Search token and save results to slice. Called by autocomplete in GeneralForm.
   * @param {string} token - string to be searched
   * @param {number} minLen - minimum acceptable length of token
   */
  const searchAction = (token: string) => {
    const minLen = 3;
    if (token.length === minLen) {
      dispatch(fetchLookupByNominativo({ token }));
    }
  }

  const localActionInsert = (formData: AssenzaProgrammata) => {
    const payload = {
      ...formData,
      approvazioneDateTime: getDateYYYYMMDD_BackEnd(new Date()),
      approvazioneUtente: utenteLoggato.nomeUtente,
    };

    dispatch(insert(payload));
  }

  // title to be finalize
  const exportDataExtra: PDFExtraData = { head: { title: [], value: [] }, extra: [] };
  Object.keys(fixedProps).forEach(elem => {
    switch (elem) {
      case 'dataInizioPeriodo':
        const inizioPeriodoValue: string | null = fixedProps[elem];
        if (exportDataExtra.head && inizioPeriodoValue)
          exportDataExtra.head['Inizio Periodo'] = lookupStr[inizioPeriodoValue];
        break;
      case 'dataFinePeriodo':
        const finePeriodoValue: string | null = fixedProps[elem];
        if (exportDataExtra.head && finePeriodoValue)
          exportDataExtra.head['Fine Periodo'] = lookupDip[finePeriodoValue];
        break;
    }
  });

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setToggleValidDeleted(true);
      setAllFieldsState([]);
      setStates({});
      dispatch(resetAssenzeProgrammate());
      dispatch(resetArticoliLegge());
      dispatch(resetAssenze());
      dispatch(resetStruttureSlice());
      dispatch(resetDipartimentiFiltered());
      dispatch(resetUnitaOperativeFiltered());
      dispatch(resetAnagraficaSoggettiUnitaOperative());
    };
  }, [dispatch]);

  return (
    <>
      <Paper elevation={2}>
        <Box p={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  variant="inline"
                  label={startSelectLabel}
                  format={"dd/MM/yyyy"}
                  onChange={(e) => handleDateChange(e, startSelectName)}
                  autoOk={true}
                  disabled={!isUrlRoot(history.location.pathname, lungheAssenzePath)}
                  value={states[startSelectName] ?? null}
                  views={["year", "month", "date"]}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            {
              states[startSelectName] &&
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    label={endSelectLabel}
                    format={"dd/MM/yyyy"}
                    onChange={(e) => handleDateChange(e, endSelectName)}
                    autoOk={true}
                    minDate={new Date(states[startSelectName] ?? '')}
                    disabled={!isUrlRoot(history.location.pathname, lungheAssenzePath)}
                    value={states[endSelectName] ?? null}
                    views={["year", "month", "date"]}
                    minDateMessage={t('greaterEqualThanStartDateValidation')}
                    clearable
                    okLabel={(i18n.language === "en-GB" || i18n.language === "en-US" || i18n.language === "en") ? "OK" : "Inserisci"}
                    clearLabel={(i18n.language === "en-GB" || i18n.language === "en-US" || i18n.language === "en") ? "Clear" : "Pulisci"}
                    cancelLabel={(i18n.language === "en-GB" || i18n.language === "en-US" || i18n.language === "en") ? "Cancel" : "Annulla"}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            }
          </Grid>
        </Box>
      </Paper>
      <Box marginTop={2}>
        {
          (
            <Box marginTop={2}>
              <Switch>
                <Route path={lungheAssenzePath} exact>
                  <CrudMaterialTable
                    abilitazione={abilitazione}
                    title={title}
                    columns={columns}
                    columnsButton={true}
                    data={data}
                    elementIdProps={elementIdProps}
                    elementRenderProps={elementRenderProps}
                    fetchAllValid={fetchAllValidByPeriodo}
                    fetchAllDeleted={fetchAllDeletedByPeriodo}
                    insertCallback={insertCallback}
                    updateCallback={updateCallback}
                    logicalDel={logicalDel}
                    physicalDel={physicalDel}
                    restore={restore}
                    dataValid={toggleValidDeleted}
                    setToggleValidDeleted={setToggleValidDeleted}
                    statusValid={statusValidLungheAssenze}
                    statusDeleted={statusDeletedLungheAssenze}
                    errorBE={errorBE}
                    logoUri={logoUri}
                    localizedDatePicker={true}
                    fixedProps={fixedProps}
                    exportDataExtra={exportDataExtra}
                    isExportLandscape={true}
                    resetErrorCallback={resetError}
                    extraOptions={{
                      minBodyHeight: calculateMTableHeight(0, false, true),
                      maxBodyHeight: calculateMTableHeight(0, false, true),
                    }}
                  />
                </Route>
                <Route path={lungheAssenzePath + componentInsertPath} exact>
                  <InnerComponentViews
                    abilitazione={abilitazione}
                    mainUri={lungheAssenzePath}
                    tabsUri={lungheAssenzePath + componentInsertPath}
                    tabsView={false}
                    buttonTitle={t("longAbsenceTitle")}
                    info1={t("")}
                    tabs={[
                      {
                        label: t(""),
                        tabPath: "",
                        abilitazione: Abilitazione.READ_UPDATE,
                        componentIf: (
                          <GeneralForm
                            readOnly={false}
                            language={i18n.language}
                            componentPath={lungheAssenzePath}
                            // action={insert}
                            localAction={localActionInsert}
                            autoCompleteSearchAction={searchAction}
                            status={statusValidLungheAssenze}
                            error={errorBE}
                            update={false}
                            obj={{}}
                            fields={allFieldsState}
                            fixedProps={fixedProps}
                            lookups={formLookups}
                            translate={t}
                            checksCallback={formCallback}
                          />
                        ),
                        componentElse: <Redirect to={lungheAssenzePath} />,
                      },
                    ]}
                  />
                </Route>
                <Route path={lungheAssenzePath + componentTabsPath}>
                  <InnerComponentViews
                    abilitazione={abilitazione}
                    mainUri={lungheAssenzePath}
                    tabsUri={lungheAssenzePath + componentTabsPath}
                    tabsView={false}
                    buttonTitle={t(title)}
                    info1={''}
                    tabs={[
                      {
                        label: t("longAbsenceTitle"),
                        tabPath: '',
                        abilitazione: Abilitazione.READ_UPDATE,
                        componentIf: (
                          <GeneralForm
                            readOnly={false}
                            language={i18n.language}
                            componentPath={lungheAssenzePath}
                            action={update}
                            status={statusValidLungheAssenze}
                            error={errorBE}
                            update={true}
                            obj={obj}
                            fields={allFieldsState}
                            fixedProps={fixedProps}
                            lookups={formLookups}
                            translate={t}
                            checksCallback={formCallback}
                          />
                        ),
                        componentElse: <Redirect to={lungheAssenzePath} />,
                      }
                    ]}
                  />
                </Route>
              </Switch>
            </Box>
          )
        }
      </Box>
    </>
  );
};

export default LungheAssenzeW;