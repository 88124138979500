import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StatusEnum } from '../../models/Utils'
import { getByIds } from '../../services/services.service'
import { ScadenzaFormazione } from '../../models/ScadenzaFormazione';
import { getNow } from '../../utils/utilfunctions';

const microservice = "anagraficasoggetti";
const entity = "anagraficasoggettiformazione";

interface ScadenzaFormazioneState {
  statusValidScadenzeFormazione: StatusEnum,
  validScadenzeFormazione: ScadenzaFormazione[],
  formazioneScadenze: ScadenzaFormazione | null,
  error: string | null
}

const initialState: ScadenzaFormazioneState = {
  statusValidScadenzeFormazione: StatusEnum.Succeeded,
  validScadenzeFormazione: [],
  formazioneScadenze: null,
  error: null
}

export const fetchScadenzaFormazione = createAsyncThunk(entity + 'controlloscaduti/fetchScadenzaFormazione', async (obj: {
  dataRiferimento: Date,
}) => {
  const data = getNow();

  const response = await getByIds(microservice, entity, ['controlloscaduti', data], ['controlloscaduti/fetchScadenzaFormazione']);
  return response.data as ScadenzaFormazione[];
});

export const scadenzeFormazioneSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: ScadenzaFormazioneState) => {
      return initialState;;
    },
    resetError: (state: ScadenzaFormazioneState) => {
      state.error = initialState.error;
      state.statusValidScadenzeFormazione = initialState.statusValidScadenzeFormazione;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchScadenzaFormazione.pending, (state) => {
      state.statusValidScadenzeFormazione = StatusEnum.Loading;
    })
    builder.addCase(fetchScadenzaFormazione.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidScadenzeFormazione = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidScadenzeFormazione = StatusEnum.Failed;
      }
      state.validScadenzeFormazione = [];
    })
    builder.addCase(fetchScadenzaFormazione.fulfilled, (state, { payload }: PayloadAction<ScadenzaFormazione[]>) => {
      state.statusValidScadenzeFormazione = StatusEnum.Succeeded;
      state.validScadenzeFormazione = payload;
    })
  }
});

export const { reset, resetError } = scadenzeFormazioneSlice.actions;
export default scadenzeFormazioneSlice.reducer;