import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StatusEnum } from '../../models/Utils'
import { getByIds } from '../../services/services.service'
import { ScadenzeVisite } from '../../models/ScadenzeVisite';
import { getNow } from '../../utils/utilfunctions';

const microservice = "anagraficasoggetti";
const entity = "anagraficasoggettischedasanitaria";

interface ScadadenzeVisiteState {
  statusValidScadenzeVisite: StatusEnum,
  validScadenzeVisite: ScadenzeVisite[],
  scadenzeVisite: ScadenzeVisite | null,
  error: string | null
}

const initialState: ScadadenzeVisiteState = {
  statusValidScadenzeVisite: StatusEnum.Succeeded,
  validScadenzeVisite: [],
  scadenzeVisite: null,
  error: null
}

export const fetchScadenzaVisite = createAsyncThunk(entity + 'controllosuccessivo/fetchScadenzaVisite', async (obj: {
  dataRiferimento: Date,
}) => {
  const data = getNow();

  const response = await getByIds(microservice, entity, ['controllosuccessivo', data], ['controllosuccessivo/fetchScadenzaVisite']);
  return response.data as ScadenzeVisite[];
});

export const scadenzeVisiteSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: ScadadenzeVisiteState) => {
      return initialState;;
    },
    resetError: (state: ScadadenzeVisiteState) => {
      state.error = initialState.error;
      state.statusValidScadenzeVisite = initialState.statusValidScadenzeVisite;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchScadenzaVisite.pending, (state) => {
      state.statusValidScadenzeVisite = StatusEnum.Loading;
    })
    builder.addCase(fetchScadenzaVisite.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidScadenzeVisite = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidScadenzeVisite = StatusEnum.Failed;
      }
      state.validScadenzeVisite = [];
    })
    builder.addCase(fetchScadenzaVisite.fulfilled, (state, { payload }: PayloadAction<ScadenzeVisite[]>) => {
      state.statusValidScadenzeVisite = StatusEnum.Succeeded;
      state.validScadenzeVisite = payload;
    })
  }
});

export const { reset, resetError } = scadenzeVisiteSlice.actions;
export default scadenzeVisiteSlice.reducer;