import {
  makeStyles,
  Theme,
  createStyles,
  Paper,
  Box,
  Grid,
  Typography,
  Tooltip,
  Divider,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { addDays, getDaysInMonth } from 'date-fns';
import { useAppSelector } from '../../../../../store/hooks';
import { Presenza } from '../../../../../models/Presenze';
import { differenzaInOreMinuti, getDateYYYYMMDD, sortAscObjectsBy } from '../../../../../utils/utilfunctions';
import clsx from 'clsx';
import { TurniColors } from '../../../../../utils/utildata';

/** CALENDAR */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hCell: {
      height: 120
    },
    disabled: {
      color: "#cecece"
    },
    bold: {
      fontWeight: "bold"
    },
    error: {
      color: '#f00',
    },
    bgError: {
      backgroundColor: "#900",
      color: "#fff"
    },
    bgErrorDiffMonth: {
      backgroundColor: "#b36767",
      color: "#fff",
    },
    paper: {
      padding: theme.spacing(2, 4, 3),
      marginTop: '2em',
    },
    textLightGray: {
      color: "#ccc",
    },
    turnoMattina: {
      backgroundColor: TurniColors.Mattina,
    },
    turnoPomeriggio: {
      backgroundColor: TurniColors.Pomeriggio,
    },
    turnoNotte: {
      backgroundColor: TurniColors.Notte,
    },
    turnoSmontoNotte: {
      backgroundColor: TurniColors.SmontoNotte,
    },
    turnoRiposo: {
      backgroundColor: TurniColors.Riposo,
    },
    assenza: {
      backgroundColor: TurniColors.Assenza,
    },
    altriTurni: {
      backgroundColor: TurniColors.AltriTurni,
    }
  }),
);

export interface PresenzaDipendente {
  idAnagraficaSoggetto: number,
  nome: string,
  cognome: string,
  pianificazioni: PresenzaSingoloGiorno[],
}

export interface PresenzaSingoloGiorno {
  data: string,
  infoTurno: InfoPresenza[],
}

export interface InfoPresenza {
  idStruttura: number,
  strutturaNome: string,
  idDipartimento: number,
  dipartimentoNome: string,
  idUnitaOperativa: number,
  unitaOperativaNome: string,
  idQualifica?: number,
  qualificaDescrizione?: string,
  qualificaDescrizioneBreve?: string,
  idTurno?: number,
  turnoDescrizione?: string,
  turnoDescrizioneBreve?: string,
  idTurnoAssenza?: number,
  turnoAssenzaDescrizione?: string,
  turnoAssenzaDescrizioneBreve?: string,
  oraInizioTurno: string;
  oraFineTurno: string;
  oreTurno: number | string;
  minutiTurno: number | string,
}

interface CalendarProps {
  idAnagraficaSoggetto: number,
  presenza: Presenza[],
  dataInizio: string,
  dataFine: string, // 1-12
}

const CalendarDipendentiPresenze = ({ idAnagraficaSoggetto, presenza, dataInizio, dataFine }: CalendarProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const anno = (new Date(dataInizio)).getFullYear()
  const mese = (new Date(dataInizio)).getMonth() + 1

  const daysOfTheWeek: string[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const dayOfOne: number = new Date(anno, mese - 1, 1).getDay();

  const dateNow = new Date();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const validPresenze = useAppSelector(state => state.presenze.validPresenze);
  const [presenzaState, setPresenzaState] = useState<Presenza[]>([]);
  useEffect(() => {
    setPresenzaState(validPresenze);
  }, [validPresenze])

  const [preparedData, setPreparedData] = useState<PresenzaSingoloGiorno[]>([]);
  useEffect(() => {
    setPreparedData(prepareData(presenzaState, anno, mese, dayOfOne));
  }, [anno, dataFine, dataInizio, dayOfOne, mese, presenzaState]);

  const numberOfWeeks = useMemo(() => {
    return Math.ceil(preparedData.length / daysOfTheWeek.length)
  }, [daysOfTheWeek.length, preparedData.length]);

  return (
    <>
      <Paper >
        <Box p={1}>
          <Grid container>
            {/* Header: days of the week & total column */}
            <Grid item xs={12} container>
              {daysOfTheWeek.map((dayOfTheWeek, index) => (
                <Grid key={'dayOfWeek' + index} item xs>
                  <Paper>
                    <Typography align="center">
                      {t(dayOfTheWeek).substring(0, 3)}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
            {
              // calendar table
              new Array(numberOfWeeks)
                .fill(null)
                .map((_, weekNumber) => {
                  // week row
                  return <Grid key={'calendar' + weekNumber} item container xs={12}>
                    {
                      daysOfTheWeek.map((_, indexDayOfTheWeek) => {
                        const dateIndex: number = weekNumber * daysOfTheWeek.length + indexDayOfTheWeek;
                        const currentDate: Date = new Date(preparedData[dateIndex]?.data);
                        const currentDateNumber = currentDate.getDate();

                        const orderedPreparedData = sortAscObjectsBy(preparedData[dateIndex]?.infoTurno, ['oraInizioTurno'])

                        // Crea la cella di una giornata dei turni
                        return (
                          <Grid key={'dateCell' + weekNumber.toString() + '-' + indexDayOfTheWeek} item xs>
                            {
                              currentDate.getMonth() === mese - 1 &&
                              <Box
                                border={1}
                                borderRadius={2}
                                borderColor="gray"
                                bgcolor={dateNow.getDate() === currentDateNumber ? '#ccffcc' : undefined}
                                className={classes.hCell}
                              >

                                {/* date title number */}
                                <div>
                                  <Typography className={classes.bold} align="center">
                                    {currentDateNumber}
                                  </Typography>
                                </div>

                                <Divider />

                                { /* Lista turni*/
                                  preparedData && preparedData[dateIndex] && currentDate.getMonth() === mese - 1 &&
                                  orderedPreparedData.map((turno, index) => {
                                    const [ora, minuti] = differenzaInOreMinuti(turno.oraInizioTurno, turno.oraFineTurno);

                                    let turnoStyle;

                                    if (turno.turnoAssenzaDescrizioneBreve != null) {
                                      turnoStyle = classes.assenza;
                                    }
                                    else if (turno.turnoDescrizioneBreve?.startsWith('M')) {
                                      turnoStyle = classes.turnoMattina;
                                    }
                                    else if (turno.turnoDescrizioneBreve?.startsWith('P')) {
                                      turnoStyle = classes.turnoPomeriggio;
                                    }
                                    else if (turno.turnoDescrizioneBreve?.startsWith('N')) {
                                      turnoStyle = classes.turnoNotte;
                                    }
                                    else if (turno.turnoDescrizioneBreve?.startsWith('SN')) {
                                      turnoStyle = classes.turnoSmontoNotte;
                                    }
                                    else if (turno.turnoDescrizioneBreve?.startsWith('R')) {
                                      turnoStyle = classes.turnoRiposo;
                                    } else {
                                      turnoStyle = classes.altriTurni;
                                    }

                                    return (
                                      <TooltipTurno
                                        key={'tooltip-presenze-' + weekNumber.toString() + '-' + indexDayOfTheWeek + index}
                                        turno={turno}
                                      >
                                        <Grid
                                          container
                                          className={clsx(turnoStyle)}
                                        >
                                          <Grid item xs>
                                            <Typography style={{ textAlign: 'center', fontSize: isMobile ? '0.75em' : '1em' }}>
                                              {
                                                isMobile
                                                  ? (turno.turnoDescrizioneBreve ?? turno.turnoAssenzaDescrizioneBreve)
                                                  : (turno.turnoDescrizione ?? turno.turnoAssenzaDescrizione)
                                              }
                                            </Typography>
                                            <Typography style={{ textAlign: 'center', fontSize: isMobile ? '0.75em' : '1em' }}>
                                              {
                                                turno.oraFineTurno
                                                  ? (ora.toString().padStart(2, '0')) + ':' + (minuti.toString().padStart(2, '0'))
                                                  : ((turno.oreTurno.toString().padStart(2, '0')) + ':' + (turno.minutiTurno.toString().padStart(2, '0')))
                                              }
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </TooltipTurno>
                                    )

                                  })
                                }
                              </Box>
                            }
                          </Grid>
                        );
                      })
                    }
                  </Grid>
                })
            }
          </Grid>
        </Box>
      </Paper>
    </>
  );
}

export default CalendarDipendentiPresenze;

interface TooltipTurnoProps {
  children: React.ReactElement,
  turno: InfoPresenza,
}

const TooltipTurno = (props: TooltipTurnoProps) => {
  const { turno, children } = props;

  return (
    <Tooltip
      title={<span style={{ fontSize: 13 }}>
        Struttura : {turno.strutturaNome} <br />
        Dipartimento : {turno.dipartimentoNome} <br />
        Unita Operativa : {turno.unitaOperativaNome} <br />
        Qualifica : {turno.qualificaDescrizione} <br />
        {
          turno.turnoDescrizione ?
            <>
              Turni: {turno.turnoDescrizione} <br />
            </>
            :
            <>
              Assenza: {turno.turnoAssenzaDescrizione} <br />
            </>
        }
        Inizio Turno : {turno.oraInizioTurno} <br />
        Fine Turno : {turno.oraFineTurno} <br />
      </span>}
    >
      {children}
    </Tooltip>
  )
}

function prepareData(presenze: Presenza[], anno: number, mese: number, offset: number): PresenzaSingoloGiorno[] {
  const retval: PresenzaSingoloGiorno[] = [];
  const temp: PresenzaSingoloGiorno[] = [];

  const dataRiferimento = new Date(anno, mese - 1, 1);

  const maxDays = getDaysInMonth(dataRiferimento);

  for (let i = 0; i < offset; i++) {
    temp.push({
      data: getDateYYYYMMDD(addDays(new Date(anno, mese - 1, 1), -(offset - i))),
      infoTurno: [],
    })
  }

  for (let i = 0; i < maxDays; i++) {
    const _dataRiferimento = getDateYYYYMMDD(new Date(anno, mese - 1, i + 1));
    temp.push({
      data: _dataRiferimento,
      infoTurno: [],
    });

    const presenzeFiltered = presenze.filter(presenza => {
      return presenza.presenzaData.startsWith(_dataRiferimento);
    });

    presenzeFiltered.forEach(elem => {
      const infoTurno: InfoPresenza = {
        idStruttura: elem.idStruttura,
        idDipartimento: elem.idDipartimento,
        idUnitaOperativa: elem.idUnitaOperativa,
        idQualifica: elem.idQualifica,
        idTurno: elem.idTurno,
        idTurnoAssenza: elem.idTurnoAssenza,
        oreTurno: elem.oreTurno,
        minutiTurno: elem.minutiTurno,
        strutturaNome: elem.strutturaNome,
        dipartimentoNome: elem.dipartimentoNome,
        unitaOperativaNome: elem.unitaOperativaNome,
        qualificaDescrizione: elem.qualificaDescrizione,
        qualificaDescrizioneBreve: elem.qualificaDescrizioneBreve,
        turnoDescrizione: elem.turnoDescrizione,
        turnoDescrizioneBreve: elem.turnoDescrizioneBreve,
        turnoAssenzaDescrizione: elem.turnoAssenzaDescrizione,
        turnoAssenzaDescrizioneBreve: elem.turnoAssenzaDescrizioneBreve,
        oraInizioTurno: elem.oraInizioTurno,
        oraFineTurno: elem.oraFineTurno,
      };

      temp[temp.length - 1]?.infoTurno.push({ ...infoTurno });
    });
  }

  return retval.concat(temp);
}