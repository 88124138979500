import {
  makeStyles,
  Theme,
  createStyles,
  Paper,
  Box,
  Grid,
  Typography,
  Divider,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { RiepilogoPianificazioneDTO, MappaTurniOre, IdsConfigExtended } from '../../PianificazioniW';
import InsertTurniModal from './InsertTurniModal';
import { fetchAllValidById as fetchTurniAbilitati } from '../../../../../store/slices/anagraficaSoggettiTurnoAbilitatoSlice';
import { useAppDispatch } from '../../../../../store/hooks';
import { getDateYYYYMMDD } from '../../../../../utils/utilfunctions';
import { TurniColors } from '../../../../../utils/utildata';

/** CALENDAR */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cell: {
      minHeight: 120,
      cursor: 'pointer',
      '&:hover': {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
      }
    },
    disabled: {
      color: "#cecece"
    },
    bold: {
      fontWeight: "bold"
    },
    error: {
      color: '#f00',
    },
    bgError: {
      backgroundColor: "#900",
      color: "#fff"
    },
    bgErrorDiffMonth: {
      backgroundColor: "#b36767",
      color: "#fff",
    },
    paper: {
      // backgroundColor: theme.palette.background.paper,
      // border: "1px solid #ccc",
      padding: theme.spacing(2, 4, 3),
      marginTop: '2em',
    },
    textLightGray: {
      color: "#ccc",
    },
    turnoMattina: {
      backgroundColor: TurniColors.Mattina,
    },
    turnoPomeriggio: {
      backgroundColor: TurniColors.Pomeriggio,
    },
    turnoNotte: {
      backgroundColor: TurniColors.Notte,
    },
    turnoSmontoNotte: {
      backgroundColor: TurniColors.SmontoNotte,
    },
    turnoRiposo: {
      backgroundColor: TurniColors.Riposo,
    },
    assenza: {
      backgroundColor: TurniColors.Assenza,
    },
    altriTurni: {
      backgroundColor: TurniColors.AltriTurni,
    }
  }),
);

export interface OrderedListOfTurni {
  idQualifica: number,
  turno: IdsConfigExtended,
  ore: number,
  minuti: number,
  combinazioneTurni?: string,
  turnoFisso: boolean,
  codiceTurno: string,
  modificaInizioTurnoFissoCodiceTurno: boolean,
}

export interface CalendarProps {
  currentSelectedAS: RiepilogoPianificazioneDTO;
  mappaTurniOre: MappaTurniOre;
  dateToShow: Date[];
  selectedMonth: Date;
  selectedIdQualifica: number;
  riepilogoAnagraficaModifica: RiepilogoPianificazioneDTO,
  fixedProps: {
    idStruttura: number;
    idDipartimento: number;
    idUnitaOperativa: number;
    mese: number;
    anno: number;
  };
  approvato: boolean;
}

const Calendar = (props: CalendarProps) => {
  const { mappaTurniOre, dateToShow, selectedMonth, currentSelectedAS, selectedIdQualifica, fixedProps, approvato, riepilogoAnagraficaModifica } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const [openTurniModal, setOpenTurniModal] = useState<boolean>(false);
  const [selectedDateIndex, setSelectedDateIndex] = useState<number>(0);
  const [currentSelectedDate, setCurrentSelectedDate] = useState<Date>();

  const dispatch = useAppDispatch();

  const dateNow = new Date();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (currentSelectedAS.idAnagraficaSoggetto && currentSelectedAS.idStruttura)
      dispatch(fetchTurniAbilitati([currentSelectedAS.idStruttura, currentSelectedAS.idAnagraficaSoggetto, getDateYYYYMMDD(selectedMonth) + 'T00:00:00']));
  }, [currentSelectedAS.idAnagraficaSoggetto, currentSelectedAS.idStruttura, dispatch, selectedMonth]);

  const daysOfTheWeek: string[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const numberOfWeeks: number = Math.ceil(dateToShow.length / daysOfTheWeek.length);
  const dayOfOne: number = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1).getDay();

  const [idQualifica, setIdQualifica] = useState<number>(0);
  useEffect(() => {
    setIdQualifica(selectedIdQualifica)
  }, [selectedIdQualifica]);

  const handleOpen = (dateIndex: number, currentDate: Date) => {
    setSelectedDateIndex(dateIndex);
    setCurrentSelectedDate(currentDate);
    setOpenTurniModal(true);
  };

  const [preparedData, setPreparedData] = useState<OrderedListOfTurni[][]>([]);
  useEffect(() => {
    setPreparedData(prepareData(currentSelectedAS, mappaTurniOre, dayOfOne));
  }, [currentSelectedAS, dayOfOne, mappaTurniOre]);


  useEffect(() => {
    return () => {
      setPreparedData([]);
      setCurrentSelectedDate(undefined);
    }
  }, []);

  return (
    <>
      <Paper>
        <Box p={1}>
          <Grid container>
            <Grid item xs={12} >
              {/* Header: days of the week & total column */}
              <Box display='flex' justifyContent={'space-Between'} padding={3} >
                <Typography variant="body1" className={classes.bold} >
                  {riepilogoAnagraficaModifica?.nominativo}
                </Typography>
                <Typography variant="body1">
                  {t('qualificationParam')}:&nbsp;
                  <span className={classes.bold}>
                    {riepilogoAnagraficaModifica?.qualificaDescrizione}
                  </span>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} container>
              {daysOfTheWeek.map((dayOfTheWeek, index) => (
                <Grid key={'dayOfWeek' + index} item xs>
                  <Paper>
                    <Typography style={{ fontSize: '1em' }} align="center">
                      {t(dayOfTheWeek).substring(0, 3)}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
            {
              // calendar table
              new Array(numberOfWeeks)
                .fill(null)
                .map((_, weekNumber) => {
                  // week row
                  return <Grid key={'calendar' + weekNumber} item container xs={12}>
                    {
                      daysOfTheWeek.map((_, indexDayOfTheWeek) => {
                        const dateIndex: number = weekNumber * daysOfTheWeek.length + indexDayOfTheWeek;
                        const currentDate: Date = dateToShow[dateIndex];
                        const currentDateNumber = currentDate.getDate();

                        // Crea la cella di una giornata dei turni
                        return (
                          <Grid key={'dateCell' + weekNumber.toString() + indexDayOfTheWeek} item xs>
                            {
                              currentDate.getMonth() === selectedMonth.getMonth() &&
                              <Box
                                border={1}
                                borderColor="gray"
                                bgcolor={dateNow.getDate() === currentDateNumber ? '#ccffcc' : undefined}
                                className={classes.cell}
                                onClick={() => handleOpen(dateIndex, currentDate)}
                              >
                                {/* date title number */}
                                <div>
                                  <Typography className={classes.bold} align="center">
                                    {currentDateNumber}
                                  </Typography>
                                </div>

                                <Divider />

                                {/* turni box */}
                                <Grid container>
                                  {
                                    preparedData && preparedData[dateIndex] && currentDate.getMonth() === selectedMonth.getMonth() &&
                                    preparedData[dateIndex].filter(elem => (elem.turno.descrizioneBreve !== '' || elem.turno.descrizione !== '')).map((turni, index) => {

                                      let turnoStyle;

                                      if (!turni.turno.isTurno) {
                                        turnoStyle = classes.assenza;
                                      }
                                      else if (turni.turno.descrizioneBreve?.startsWith('M')) {
                                        turnoStyle = classes.turnoMattina;
                                      }
                                      else if (turni.turno.descrizioneBreve?.startsWith('P')) {
                                        turnoStyle = classes.turnoPomeriggio;
                                      }
                                      else if (turni.turno.descrizioneBreve?.startsWith('N')) {
                                        turnoStyle = classes.turnoNotte;
                                      }
                                      else if (turni.turno.descrizioneBreve?.startsWith('SN')) {
                                        turnoStyle = classes.turnoSmontoNotte;
                                      }
                                      else if (turni.turno.descrizioneBreve?.startsWith('R')) {
                                        turnoStyle = classes.turnoRiposo;
                                      } else {
                                        turnoStyle = classes.altriTurni;
                                      }

                                      return (
                                        <Grid item xs={12} className={clsx(turnoStyle)}>
                                          <Typography
                                            key={'turnoDescrizione' + weekNumber.toString() + indexDayOfTheWeek.toString() + index}
                                            variant="body2"
                                            className={clsx(turni.idQualifica !== idQualifica ? classes.textLightGray : '')}
                                            style={{ textAlign: 'center', fontSize: isMobile ? '0.75em' : '1em' }}
                                          >
                                            {isMobile ? turni.turno.descrizioneBreve : turni.turno.descrizione}
                                            {turni.turno.reperibilita ? '*' : ""}
                                          </Typography>
                                          <Typography
                                            key={'turnoOre' + weekNumber.toString() + indexDayOfTheWeek.toString() + index}
                                            className={clsx(turni.idQualifica !== idQualifica ? classes.textLightGray : '')}
                                            style={{ textAlign: 'center', fontSize: isMobile ? '0.75em' : '1em' }}
                                          >
                                            {turni.ore + ':' + (turni.minuti.toString().padStart(2, '0') ?? '00')}
                                          </Typography>
                                        </Grid>
                                      )
                                    })
                                  }
                                </Grid>
                              </Box>
                            }
                          </Grid>
                        );
                      })
                    }

                  </Grid>
                })
            }
            <Grid xs={12}>
              <Box display='flex' justifyContent={'space-Between'} padding={3}>

                <Typography variant="body1"  >
                  {t('totalShiftParam')}:&nbsp;
                  <span className={classes.bold}>
                    {(riepilogoAnagraficaModifica?.oreTotale ?? 0) + ':' + (riepilogoAnagraficaModifica.minutiTotale?.toString().padStart(2, '0') ?? '00')}
                  </span>
                </Typography>

                <Typography variant="body1" >
                  {t('totalRestParam')}:&nbsp;
                  <span className={classes.bold}>
                    {riepilogoAnagraficaModifica?.numeroRiposi ?? 0}
                  </span>
                </Typography>
              </Box>
              <Box padding={3}>

              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      {
        currentSelectedDate &&
        <InsertTurniModal
          currentSelectedAS={currentSelectedAS}
          currentSelectedDate={currentSelectedDate}
          preparedData={preparedData}
          selectedDateIndex={selectedDateIndex}
          fixedProps={fixedProps}
          approvato={approvato}
          openState={{
            open: openTurniModal,
            setOpen: setOpenTurniModal
          }}
        />
      }
    </>
  );
}

export default Calendar;

export function prepareData(riepilogoSingoloAS: RiepilogoPianificazioneDTO, mappaTurniOre: MappaTurniOre, offset: number): OrderedListOfTurni[][] {
  const retval: OrderedListOfTurni[][] = riepilogoSingoloAS?.turniObj.map((giorno, indexGiorno) => {
    const temp: OrderedListOfTurni[] = [];

    // se giorno === '', allora salta (ritorna un'array vuota)
    giorno && giorno.forEach((turno, indexTurno) => {
      temp.push({
        idQualifica: riepilogoSingoloAS.qualificheIds[indexGiorno][indexTurno],
        turno: turno,
        ore: mappaTurniOre[turno.descrizioneBreve]?.ore,
        minuti: mappaTurniOre[turno.descrizioneBreve]?.minuti,
        combinazioneTurni: riepilogoSingoloAS.combinazioneTurni,
        turnoFisso: riepilogoSingoloAS.turnoFisso,
        codiceTurno: riepilogoSingoloAS.codiceTurno,
        modificaInizioTurnoFissoCodiceTurno: riepilogoSingoloAS.modificaInizioTurnoFissoCodiceTurno,
      });
    });

    return temp;
  });

  // riempie i primi offset posizioni con oggetti vuoti
  new Array(offset).fill({ "": undefined }).forEach(_ => {
    retval?.unshift([]);
  });

  return retval;
}