import { useCallback, useEffect, useMemo, useState } from "react";
import { Column } from "@material-table/core";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { notFoundPath, approvazioneAssenzePath } from "../../../utils/utilconst";
import Paper from "@material-ui/core/Paper";
import { Box, IconButton, FormControl, FormControlLabel, Grid, Switch as MuiSwitch, } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { update } from "../../../store/slices/assenzeProgrammateSlice";
import { cleanLookup as cleanDipartimentiLookup, } from "../../../store/slices/dipartimentiFilteredSlice";
import { cleanLookup as cleanUOLookup, } from "../../../store/slices/unitaOperativeFilteredSlice";
import { useHistory, Switch, Route, Redirect } from "react-router-dom";
import { getDateDDMMYYYY_HHMM, getNow, isUrlRoot, } from '../../../utils/utilfunctions';
import { Fields } from "../../../models/Fields";
import ReadOnlyMaterialTable from '../tables/ReadOnly/ReadOnlyMaterialTable';
import i18n from "../../../i18n";
import { it, enGB } from "date-fns/locale";
import { fetchByIds } from "../../../store/slices/assenzeProgrammateSlice";


import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { allFieldsApprovazioneAssenza, AssenzaProgrammataKeys } from "../../../models/AssenzeProgrammate";
import { AssenzaProgrammata } from "../../../models/AssenzeProgrammate";

const ApprovazioneAssenzeW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const logoUri = useAppSelector((state) => state.authInfo.logoUri);
  const title = t("approvalAbsence");

  const isCurrentPageNotIndex = !isUrlRoot(history.location.pathname, approvazioneAssenzePath);

  const currentPath = useMemo(() => history.location.pathname, [history.location.pathname]);

  const dateStartName = useMemo(() => t("startDateTitle"), [t]);
  const dateStarLabel = useMemo(() => t("startDateSelect"), [t]);
  const dateEndLabel = useMemo(() => t("endDateSelect"), [t]);
  const dateEndName = useMemo(() => t("endDateTitle"), [t]);

  const [states, setStates] = useState<{ [selectName: string]: string | null; }>(
    {
      [dateStartName]: null,
      [dateEndName]: null,
    }
  );

  useEffect(() => {
    setStates({
      [dateStartName]: null,
      [dateEndName]: null,
    });
  }, [dateStartName, dateEndName]);

  useEffect(() => {
    dispatch(cleanDipartimentiLookup());
    dispatch(cleanUOLookup());
  }, [dispatch]);

  const [soloDaApprovare, setsoloDaApprovare] = useState(true)
  const handleChange = () => setsoloDaApprovare(state => !state);

  const fixedProps = useMemo(() => {
    return {
      inizioData: states[dateStartName],
      fineData: states[dateEndName],
      soloDaApprovare: soloDaApprovare
    };
  }, [states, dateStartName, dateEndName, soloDaApprovare]);

  const handleDateChange = (d: Date | null, field?: string) => {
    let fieldDate: string | null = null;
    if (d) {
      let month = (d.getMonth() + 1).toString().padStart(2, '0');
      let day = d.getDate().toString().padStart(2, '0');
      fieldDate = d.getFullYear() + "-" + month + "-" + day + "T00:00:00";
    }
    if (field)
      setStates(prev => { return { ...prev, [field]: fieldDate } });
  };

  const idAnagraficaSoggettoInternaConst = "idAnagraficaSoggetto int";
  const idAnagraficaSoggettoEsternaConst = "idAnagraficaSoggetto est";
  const escludeFlagConst = "escludiInseriti";

  // useEffect(() => {
  //   setAllFieldsState(allFields);
  // }, []);

  useEffect(() => {
    dispatch(fetchByIds(fixedProps));
  }, [dispatch, fixedProps]);

  const excludedFieldInTable = useMemo(() => [idAnagraficaSoggettoEsternaConst, idAnagraficaSoggettoInternaConst, escludeFlagConst,], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFieldsApprovazioneAssenza);
  const [columns, setColumns] = useState<Array<Column<AssenzaProgrammata>>>([]);
  const [data, setData] = useState<Array<AssenzaProgrammata>>([]);

  const accetta = useCallback(
    (assenza: AssenzaProgrammata) => {
      assenza.approvazioneDateTime = getNow();
      dispatch(update(assenza))
        .then(() => {
          dispatch(fetchByIds(fixedProps));
        })
    }, [dispatch, fixedProps]
  )

  const rifiuta = useCallback(
    (assenza: AssenzaProgrammata) => {
      assenza.approvazioneNegataData = getNow();
      dispatch(update(assenza))
        .then(() => {
          dispatch(fetchByIds(fixedProps));
        })
    }, [dispatch, fixedProps]
  )

  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<AssenzaProgrammata> = {
          title: f.titleKey ? t(f.titleKey) : "",
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue,
        };

        if (f.validate2) {
          obj.validate = (rowData) => {
            let resp: boolean | { isValid: boolean, helperText: string } = false;
            let resp2: boolean | { isValid: boolean, helperText: string } = false;

            if (f.validate2) {
              resp2 = f.validate2(
                rowData[f.field as AssenzaProgrammataKeys],
                rowData[f.field2Validation as AssenzaProgrammataKeys],
                f.keyTradValidation2 ? t(f.keyTradValidation2) : ''
              );
            }

            if (f.validate) {
              resp = f.validate(
                rowData[f.field as AssenzaProgrammataKeys],
                f.keyTradValidation ? t(f.keyTradValidation) : ''
              );
            }
            if (resp === true) {
              return resp2;
            } else return resp;
          }
        } else if (f.validate) {
          obj.validate = (rowData) => {
            if (f.validate) {
              return f.validate(
                rowData[f.field as AssenzaProgrammataKeys],
                f.keyTradValidation ? t(f.keyTradValidation) : ''
              );
            }

            return false;
          }
        }

        obj.sorting = false;

        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = false;
        } else {
          if ((["nomeGruppo", "presenzaData"].includes(f.field)))
            obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        if (f.field === "approvazione") {
          obj.render = (rowData: AssenzaProgrammata) => {
            if (!rowData.approvazioneDateTime && !rowData.approvazioneNegataData) {
              return <>
                <Box display='flex'>
                  <Box
                    borderColor={'#0e59a8'}
                    border='1px solid #0e59a8'
                    borderRadius={5}
                    px={1}
                    mr={1}
                    onClick={() => accetta(rowData)}
                  >
                    <IconButton
                      size='small'
                      color='primary'
                    >
                      <CheckIcon />
                    </IconButton>
                  </Box>

                  <Box
                    borderColor={'#e83519'}
                    border='1px solid #e83519'
                    borderRadius={5}
                    px={1}
                    onClick={() => rifiuta(rowData)}
                  >
                    <IconButton
                      size='small'
                      color='secondary'
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Box>
              </>
            }
            else if (rowData.approvazioneDateTime) {
              return <>{t('approvalGrantedDate') + ' ' + getDateDDMMYYYY_HHMM(new Date(rowData.approvazioneDateTime))} </>
            }
            else if (rowData.approvazioneNegataData) {
              return <>{t('approvalDeniedDate') + ' ' + getDateDDMMYYYY_HHMM(new Date(rowData.approvazioneNegataData))} </>
            }
          };
        }

        if (f.field === "idAnagraficaSoggetto") {
          obj.render = (rowData: AssenzaProgrammata) => {
            return rowData.cognome + ' ' + rowData.nome
          };
        }

        if (f.render) {
          obj.render = f.render;
        }

        if (f.defaultGroupOrder !== null && f.defaultGroupOrder !== undefined) {
          obj.defaultGroupOrder = f.defaultGroupOrder;
        }

        return obj;
      })
    );
  }, [accetta, allFieldsState, excludedFieldInTable, rifiuta, t]);

  const validApprovazioneAssenze = useAppSelector((state) => state.assenzeProgrammate.validAssenzeProgrammate);
  const statusValidApprovazionePresenze = useAppSelector((state) => state.assenzeProgrammate.statusValidAssenzeProgrammate);
  const errorBE = useAppSelector((state) => state.assenzeProgrammate.error);

  useEffect(() => {
    setData(validApprovazioneAssenze
      .map(elem => {
        return {
          ...elem,
          nomeGruppo: [elem.strutturaNome + ' > ' + elem.dipartimentoNome + ' > ' + elem.unitaOperativaNome]
        }
      })
    )
  }, [validApprovazioneAssenze]);

  useEffect(() => {
    setAllFieldsState(state => {
      const newState = [...state]
      if (!(newState).find((elem) => elem.field === 'nomeGruppo')) {
        newState.push(
          {
            field: "nomeGruppo", titleKey: "operatingUnitTitle", required: false, show: true, defaultGroupOrder: 0,
          },
        )
      }
      return newState
    })
  }, []);

  return (
    <>
      <Paper elevation={2}>
        <Box p={4}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === "it-IT" || i18n.language === "it" ? it : enGB}>
                <DatePicker
                  fullWidth
                  variant="inline"
                  label={dateStarLabel}
                  format={"dd/MM/yyyy"}
                  onChange={(e) => handleDateChange(e, dateStartName)}
                  autoOk={true}
                  disabled={isCurrentPageNotIndex}
                  value={states[dateStartName] ? states[dateStartName] : null}
                  views={["year", "month", "date"]}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === "it-IT" || i18n.language === "it" ? it : enGB}>
                <DatePicker
                  fullWidth
                  variant="inline"
                  label={dateEndLabel}
                  format={"dd/MM/yyyy"}
                  onChange={(e) => handleDateChange(e, dateEndName)}
                  autoOk={true}
                  disabled={isCurrentPageNotIndex}
                  value={states[dateEndName] ? states[dateEndName] : null}
                  views={["year", "month", "date"]}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            {
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <MuiSwitch
                        disabled={!isUrlRoot(currentPath, approvazioneAssenzePath)}
                        checked={soloDaApprovare}
                        onChange={handleChange}
                        name="Solo Approvazione"
                        color="primary"
                      />
                    }
                    labelPlacement="start"
                    label={t("endforapproved")}
                  />
                </FormControl>
              </Grid>
            }
          </Grid>
        </Box>
      </Paper>
      <Box marginTop={2}>
        {

          <>
            <Switch>
              <Route path={approvazioneAssenzePath} exact>
                <ReadOnlyMaterialTable
                  title={title}
                  columns={columns}
                  data={data}
                  statusValid={statusValidApprovazionePresenze}
                  errorBE={errorBE}
                  logoUri={logoUri}
                  fixedProps={fixedProps}
                  fetchAllValid={fetchByIds}
                  extraOptions={{
                    defaultExpanded: true,
                    columnsButton: true,
                    padding: 'dense',
                  }}
                />
              </Route>
              <Route>
                <Redirect to={notFoundPath} />
              </Route>
            </Switch>
          </>
        }
      </Box >
    </>
  );
};
export default ApprovazioneAssenzeW;
