import { Column } from "@material-table/core";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import i18n from "../../../i18n";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import { elementIdProps, elementRenderProps, CombinazioniTurni, allFields, CombinazioniTurniKeys } from "../../../models/CombinazioniTurni";
import { CombinazioniTurniTurni } from "../../../models/CombinazioniTurniTurni";
import { Fields } from "../../../models/Fields";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  insert,
  logicalDel,
  physicalDel,
  restore,
  update,
  reset as resetCombinazioniTurni,
  resetError as resetErrorCombinazioniTurni,
  fetchAllValidByIdTurno,
  fetchAllDeletedByIdTurno,
  fetchAllValid as fetchAllValidCT,
  fetchAllDeleted as fetchAllDeletedCT
} from "../../../store/slices/combinazioniTurniSlice";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { fetchAllValid as fetchAllValidTurni } from "../../../store/slices/turniSlice";
import { FixedProps } from "../../../utils/data.types";
import { componentInsertPath, componentTabsPath } from "../../../utils/innerFuncPaths";
import { calculateMTableHeight } from "../../../utils/styleconst";
import { notFoundPath, turniPath } from "../../../utils/utilconst";
import { sortingStringCI } from "../../../utils/utilfunctions";
import GeneralForm from "../forms/GeneralForm";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";

interface CombinazioniTurniForm extends Omit<CombinazioniTurni, 'combinazioniTurniTurni'> {
  combinazioniTurniTurni: number[]
}

interface CombinazioniTurniProps {
  idTurno?: number,
  basePath: string
}

const CombinazioniTurniW = (props: CombinazioniTurniProps) => {
  const { idTurno, basePath } = props;

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);

  const abilitazione = useAppSelector(state => getAbilitazione(state, turniPath));
  const errorBE = useAppSelector(state => state.combinazioniTurni.error);
  const resetError = useCallback(() => {
    dispatch(resetErrorCombinazioniTurni());
  }, [dispatch]);

  useInit();

  const title = t('shiftCombinationTitle');

  const fixedProps: FixedProps | undefined = useMemo(() => {
    if (idTurno == null)
      return undefined;
    return { idTurno };
  }, [idTurno]);

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<CombinazioniTurni>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<CombinazioniTurni> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field, removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,

        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate) {
              return f.validate(rowData[f.field as CombinazioniTurniKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            }

            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        if (f.type === 'string')
          obj.customSort = (a, b) => sortingStringCI(a.descrizioneCombinazioneBreve, b.descrizioneCombinazioneBreve);

        if (f.render)
          obj.render = f.render;

        return obj;
      }))
  }, [allFieldsState, excludedFieldInTable, t]);

  const validTurni = useAppSelector(state => state.turni.validTurni);

  const validCombinazioniTurni = useAppSelector(state => state.combinazioniTurni.validCombinazioniTurni);
  const deletedCombinazioniTurni = useAppSelector(state => state.combinazioniTurni.deletedCombinazioniTurni);

  const statusValidCombinazioniTurni = useAppSelector(state => state.combinazioniTurni.statusValidCombinazioniTurni);
  const statusDeletedCombinazioniTurni = useAppSelector(state => state.combinazioniTurni.statusDeletedCombinazioniTurni);

  const turniLookup = useAppSelector(state => state.turni.lookupDescrizione);

  const formLookup = useMemo(() => {
    return {
      combinazioniTurniTurni: turniLookup
    }
  }, [turniLookup]);

  const [data, setData] = useState<Array<CombinazioniTurni>>([]);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  useEffect(() => {
    toggleValidDeleted ? setData(validCombinazioniTurni) : setData(deletedCombinazioniTurni);
  }, [validCombinazioniTurni, deletedCombinazioniTurni, toggleValidDeleted]);

  const [obj, setObj] = useState<Partial<CombinazioniTurniForm> | null>(null);

  const insertCallback = () => {
    setObj(idTurno ? { combinazioniTurniTurni: [idTurno] } : {});
    history.push(basePath + componentInsertPath);
  }

  const updateDetailCallback = (combinazioneTurni: CombinazioniTurni) => {
    const ctt = combinazioneTurni.combinazioniTurniTurni.map(i => i.idTurno);
    const newCT = {
      ...combinazioneTurni,
      combinazioniTurniTurni: ctt
    };

    setObj(newCT);
    history.push(basePath + componentTabsPath);
  }

  const convert = (formData: CombinazioniTurniForm): CombinazioniTurni => {
    const retval: Partial<CombinazioniTurni> = {
      ...formData,
      combinazioniTurniTurni: []
    };

    const ctt: Partial<CombinazioniTurniTurni>[] = [];

    formData.combinazioniTurniTurni?.forEach((idTurno: number, index: number) => {
      const turno = validTurni.find(elem => elem.idTurno === Number(idTurno));

      if (turno)
        ctt.push({
          idTurno: turno.idTurno,
          numeroOrdine: index
        });
    });

    retval.combinazioniTurniTurni = ctt as CombinazioniTurniTurni[];

    return retval as CombinazioniTurni;
  }

  const localActionInsert = (formData: CombinazioniTurniForm) => {
    const payload = convert(formData);

    dispatch(insert(payload));
  }

  const localActionUpdate = (formData: CombinazioniTurniForm) => {
    const payload = convert(formData);

    dispatch(update(payload));
  }

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setToggleValidDeleted(true);
      setAllFieldsState([]);
      dispatch(resetCombinazioniTurni());
    };
  }, [dispatch]);

  return (
    <Switch>
      <Route path={basePath} exact>
        <CrudMaterialTable
          abilitazione={idTurno != null ? Abilitazione.OVERVIEW : abilitazione}
          title={title}
          columns={columns}
          data={data}
          elementIdProps={elementIdProps}
          elementRenderProps={elementRenderProps}
          fixedProps={fixedProps}
          fetchAllValid={idTurno == null ? fetchAllValidCT : fetchAllValidByIdTurno}
          fetchAllDeleted={idTurno == null ? fetchAllDeletedCT : fetchAllDeletedByIdTurno}
          logicalDel={logicalDel}
          restore={restore}
          physicalDel={physicalDel}
          insertCallback={insertCallback}
          updateCallback={updateDetailCallback}
          detailCallback={updateDetailCallback}
          dataValid={toggleValidDeleted}
          setToggleValidDeleted={setToggleValidDeleted}
          statusValid={statusValidCombinazioniTurni}
          statusDeleted={statusDeletedCombinazioniTurni}
          errorBE={errorBE}
          logoUri={logoUri}
          resetErrorCallback={resetError}
          extraOptions={{
            minBodyHeight: calculateMTableHeight(0, true, false),
            maxBodyHeight: calculateMTableHeight(0, true, false),
          }}
        />
      </Route>
      <Route path={basePath + componentInsertPath} exact>
        <InnerComponentViews abilitazione={abilitazione} mainUri={basePath} tabsUri={basePath + componentInsertPath} tabsView={false}
          buttonTitle={title} info1={t("newShiftsCombinationTitle")}
          tabs={
            [
              {
                label: t("structuresTitle"),
                tabPath: '',
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: <GeneralForm
                  obj={obj}
                  readOnly={false}
                  language={i18n.language}
                  componentPath={basePath}
                  localAction={localActionInsert}
                  status={statusValidCombinazioniTurni}
                  error={errorBE}
                  update={false}
                  fields={allFields}
                  translate={t}
                  lookups={formLookup}
                />,
                componentElse: <Redirect to={basePath} />
              }
            ]
          } />
      </Route>
      <Route path={basePath + componentTabsPath}>
        {
          obj ? (
            <InnerComponentViews
              abilitazione={abilitazione}
              mainUri={basePath}
              tabsUri={basePath + componentTabsPath}
              tabsView={false}
              buttonTitle={title}
              info1={obj.descrizioneCombinazione ?? ''}
              tabs={
                [
                  {
                    label: title,
                    tabPath: '',
                    abilitazione: Abilitazione.READ_UPDATE,
                    componentIf: <GeneralForm
                      readOnly={false}
                      language={i18n.language}
                      componentPath={basePath}
                      localAction={localActionUpdate}
                      status={statusValidCombinazioniTurni}
                      error={errorBE}
                      obj={obj}
                      update={true}
                      fields={allFields}
                      translate={t}
                      lookups={formLookup}
                    />,
                    componentElse: <></>
                  }
                ]
              } />
          ) : <Redirect to={basePath} />
        }
      </Route>
      <Route path="*"><Redirect to={notFoundPath} /></Route>
    </Switch>
  )
}

export default CombinazioniTurniW;

function useInit() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAllValidTurni());
  }, [dispatch]);
}