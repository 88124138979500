import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnagraficaSoggetto, AnagraficaSoggettoCurrentData, AnagraficaSoggettoCurrentDataKeys, AnagraficaSoggettoExtended, elementIdProps, LookupAnagraficaSoggettoElem } from '../../models/AnagraficaSoggetti';
import { Ids, Lookup, StatusEnum } from '../../models/Utils';
import { getTodayStart, createLookup } from '../../utils/utilfunctions'
import { Legenda } from '../../models/Presenze';
import {
  create,
  definitiveDeleteById,
  getAllValidByIds,
  getExtendedEntityById,
  getNotExtendedEntityById,
  upd,
  lookupServiceByIdList,
  getByIds,
  postLookupServiceBySubject,
  getLookupServiceBySubject,
  postAllValidByIds,
} from '../../services/services.service';

const microservice = "anagraficasoggetti";
const entity = "anagraficasoggetti";

interface AnagraficaSoggettiState {
  statusValidAnagraficaSoggetti: StatusEnum,
  validAnagraficaSoggetti: AnagraficaSoggettoCurrentData[],
  anagraficaSoggetto: AnagraficaSoggettoCurrentData | null,
  statusAnagraficaSoggettoEstesa: StatusEnum,
  anagraficaSoggettoEstesa: AnagraficaSoggettoExtended | null,
  lookup: Lookup,
  idSet: { [nome: string]: number[] },
  error: string | null,

  statusLegenda: StatusEnum,
  legenda: Legenda | null,
}

const initialState: AnagraficaSoggettiState = {
  statusValidAnagraficaSoggetti: StatusEnum.Succeeded,
  validAnagraficaSoggetti: [],
  anagraficaSoggetto: null,
  statusAnagraficaSoggettoEstesa: StatusEnum.Succeeded,
  anagraficaSoggettoEstesa: null,
  lookup: {},
  idSet: {},
  error: null,

  statusLegenda: StatusEnum.Succeeded,
  legenda: null,
}

// cerca/anagraficasoggetti/legenda/{idAnagraficaSoggetto}/{currentDate}
export const fetchLegenda = createAsyncThunk(microservice + '/fetchLegenda', async (obj: { idAnagraficaSoggetto: number, presenzaData: string, }) => {
  let ids = Object.values(obj);
  ids.unshift('legenda');

  const response = await getByIds(microservice, entity, ids, ['/fetchLegenda']);
  return response.data as Legenda;
});

// cerca/anagraficasoggetti/id/{extended = true}/{idAnagraficaSoggetto}/{dataRiferimento}
export const fetchExtendedByIdAndDate = createAsyncThunk(microservice + '/fetchExtendedByIdAndDate', async (obj: { idAnagraficaSoggetto: number }) => {
  let ids = [obj.idAnagraficaSoggetto, getTodayStart()];
  const response = await getExtendedEntityById(microservice, entity, ids);
  return response.data as AnagraficaSoggettoExtended;
});

// cerca/anagraficasoggetti/id/{extended = false}/{idAnagraficaSoggetto}/{dataRiferimento}
export const fetchNotExtendedByIdAndDate = createAsyncThunk(microservice + '/fetchNotExtendedById', async (obj: { idAnagraficaSoggetto: number }) => {
  let ids = [obj.idAnagraficaSoggetto, getTodayStart()];
  const response = await getNotExtendedEntityById(microservice, entity, ids);
  return response.data as AnagraficaSoggettoCurrentData;
});

// @GetMapping("cerca/anagraficasoggetti/all/valid/{idStruttura}/{dataRiferimento}")
export const fetchAllValidByIdStruttura = createAsyncThunk(microservice + '/fetchAllValidByIdStruttura', async (obj: { idStruttura: number }) => {
  const response = await getAllValidByIds(microservice, entity, [obj.idStruttura, getTodayStart()], ['/fetchAllValidByIdStruttura']);
  return response.data as AnagraficaSoggettoCurrentData[];
});

// cerca/anagraficasoggetti/all/valid/{idStruttura}/{dataRiferimento}
export const fetchAllValidByFiltroAvanzato = createAsyncThunk(microservice + '/fetchAllValidByFiltroAvanzato', async (obj: { idStruttura: number; inForza: boolean; sottoStringa: string | null; idQualifica: number[]; idFormazione: number[] }) => {
  const param = [obj.idStruttura, getTodayStart()];

  const filtri = {
    soloInForza: obj.inForza,
    sottoStringa: obj.sottoStringa,
    idQualifica: obj.idQualifica,
    idFormazione: obj.idFormazione,
  }

  const response = await postAllValidByIds(microservice, entity, param, filtri, ['/fetchAllValidByFiltroAvanzato']);
  return response.data as AnagraficaSoggettoCurrentData[];
});

// @GetMapping("cerca/anagraficasoggetti/all/valid/{idStruttura}/{dataRiferimento}/{soloInForza}")
// @GetMapping("cerca/anagraficasoggetti/all/valid/{idStruttura}/{dataRiferimento}/{soloInForza}/{sottoStringa}")
export const fetchAllValidByIdStrutturaInForzaFilter = createAsyncThunk(microservice + '/fetchAllValidByIdStrutturaInForzaFilter', async (obj: { idStruttura: number, inForza: boolean, sottoStringa?: string }) => {
  const param = [obj.idStruttura, getTodayStart(), obj.inForza]

  if (obj?.sottoStringa)
    param.push(obj?.sottoStringa)

  const response = await getAllValidByIds(microservice, entity, param, ['/fetchAllValidByIdStrutturaInForzaFilter']);
  return response.data as AnagraficaSoggettoCurrentData[];
});

// @GetMapping("cerca/anagraficasoggetti/all/valid/inforza/{idStruttura}/{dataRiferimento}")
export const fetchAllValidByIdStrutturaInForza = createAsyncThunk(microservice + '/fetchAllValidByIdStrutturaInForza', async (obj: { idStruttura: number, presenzaData: string }) => {
  const response = await getAllValidByIds(microservice, entity, ['inforza', obj.idStruttura, obj.presenzaData], ['/fetchAllValidByIdStrutturaInForza']);
  return response.data as AnagraficaSoggettoCurrentData[];
});

// @GetMapping("cerca/anagraficasoggetti/findByIdStrutturaAndLegge104/{idStruttura}")
export const findIdStrutturaAndLegge104 = createAsyncThunk(microservice + '/findIdStrutturaAndLegge104', async (obj: { idStruttura: number }) => {
  const ids = ["findByIdStrutturaAndLegge104", obj.idStruttura];

  const response = await getByIds(microservice, entity, ids, ['/findIdStrutturaAndLegge104']);
  return response.data as AnagraficaSoggettoCurrentData[];
});

// @GetMapping("cerca/anagraficasoggetti/strutturaBetweenDataPresaServizio/{idStruttura}/{dateFrom}/{dateTo}")
export const fetchAllValidBetweenDatesPresaServizio = createAsyncThunk(microservice + '/fetchAllValidBetweenDatesPresaServizio', async (obj: { idStruttura: number, from: string, to: string }) => {
  const ids = Object.values(obj);
  ids.unshift("strutturaBetweenDataPresaServizio");

  const response = await getByIds(microservice, entity, ids, ['/fetchAllValidBetweenDatesPresaServizio']);
  return response.data as AnagraficaSoggettoCurrentData[];
});

// @GetMapping("cerca/anagraficasoggetti/strutturaBetweenDataFineIncarico/{idStruttura}/{dateFrom}/{dateTo}")
export const fetchAllValidBetweenDatesFineIncarico = createAsyncThunk(microservice + '/fetchAllValidBetweenDatesFineIncarico', async (obj: { idStruttura: number, from: string, to: string }) => {
  const ids = Object.values(obj);
  ids.unshift("strutturaBetweenDataFineIncarico");

  const response = await getByIds(microservice, entity, ids, ['/fetchAllValidBetweenDatesFineIncarico']);
  return response.data as AnagraficaSoggettoCurrentData[];
});

// @GetMapping("cerca/anagraficasoggetti/lookup/nominativo/{nominativo}")
export const fetchLookupByToken = createAsyncThunk(microservice + '/fetchLookupByToken', async (obj: { token: string }) => {
  const ids = Object.values(obj);
  ids.unshift('lookup', 'nominativo');

  const response = await getByIds(microservice, entity, ids, ['/fetchLookupByToken']);
  return response.data as LookupAnagraficaSoggettoElem[];
});


// @GetMapping("cerca/anagraficasoggetti/all/anagrafiche/currentdata")
export const fetchAll = createAsyncThunk(microservice + '/fetchAll', async () => {
  const response = await getLookupServiceBySubject(microservice, entity, "/all/anagrafiche/currentdata");
  return response.data as AnagraficaSoggettoCurrentData[];
});

// cerca/anagraficasoggetti/all/anagrafiche GET
export const fetchAllLookupByAbilitazione = createAsyncThunk(microservice + '/fetchAllLookupByAbilitazione', async () => {
  const response = await getLookupServiceBySubject(microservice, entity, "/all/anagrafiche");
  return response.data as AnagraficaSoggetto[];
});

// cerca/anagraficasoggetti/all/anagrafiche POST
export const allLookup = createAsyncThunk(microservice + '/allLookup', async () => {
  const response = await postLookupServiceBySubject(microservice, entity, "/all/anagrafiche");
  return response.data as LookupAnagraficaSoggettoElem[];
});

// cerca/anagraficasoggetti/all/anagrafiche/{dataRiferimento}
export const lookupByIds = createAsyncThunk(microservice + '/lookupByIds', async (ids: number[]) => {
  const response = await lookupServiceByIdList(microservice, entity, "/all/anagrafiche", ids);
  return response.data as LookupAnagraficaSoggettoElem[];
});

// inserisci/anagraficasoggetti
export const insert = createAsyncThunk(microservice + '/insert', async (anagrafica: AnagraficaSoggettoCurrentData) => {
  anagrafica.codiceFiscale = anagrafica.codiceFiscale.toUpperCase();
  const response = await create(anagrafica, microservice, entity);
  return response.data as AnagraficaSoggettoCurrentData;
});

// modifica/anagraficasoggetti
export const update = createAsyncThunk(microservice + '/update', async (anagrafica: AnagraficaSoggettoCurrentData) => {
  anagrafica.codiceFiscale = anagrafica.codiceFiscale.toUpperCase();
  const response = await upd(anagrafica, microservice, entity);
  return response.data as AnagraficaSoggettoCurrentData;
});

// delete/anagraficasoggetti/id/{idAnagraficaSoggetto}
export const physicalDel = createAsyncThunk(microservice + '/physicalDelete', async (ids: Ids<AnagraficaSoggettoCurrentDataKeys>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const anagraficaSoggettiSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: () => {
      return initialState;;
    },
    resetLookup: (state: AnagraficaSoggettiState) => {
      return {
        ...state,
        lookup: {}
      };
    },
    resetError: (state: AnagraficaSoggettiState) => {
      state.error = initialState.error;
      state.statusValidAnagraficaSoggetti = initialState.statusValidAnagraficaSoggetti;
    },
    resetLegenda: (state: AnagraficaSoggettiState) => {
      return {
        ...state,
        legenda: null
      }
    }
  },
  extraReducers: builder => {
    // fetchLegenda
    builder.addCase(fetchLegenda.pending, (state) => {
      state.statusLegenda = StatusEnum.Loading;
    })
    builder.addCase(fetchLegenda.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusLegenda = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusLegenda = StatusEnum.Failed;
      }
      state.legenda = null;
    })
    builder.addCase(fetchLegenda.fulfilled, (state, { payload }: PayloadAction<Legenda>) => {
      state.statusLegenda = StatusEnum.Succeeded;
      state.legenda = payload;
    })

    //fetchExtendedByIdAndDate
    builder.addCase(fetchExtendedByIdAndDate.pending, (state) => {
      state.statusAnagraficaSoggettoEstesa = StatusEnum.Loading;
    })
    builder.addCase(fetchExtendedByIdAndDate.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusAnagraficaSoggettoEstesa = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusAnagraficaSoggettoEstesa = StatusEnum.Failed;
      }
      state.anagraficaSoggettoEstesa = null;
    })
    builder.addCase(fetchExtendedByIdAndDate.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettoExtended>) => {
      state.statusAnagraficaSoggettoEstesa = StatusEnum.Succeeded;

      const reworkedPayload: AnagraficaSoggettoExtended = {
        ...payload,
        anagraficaSoggettiContestazioni: payload?.anagraficaSoggettiContestazioni ?? [],
        anagraficaSoggettiContestazioniEliminati: payload?.anagraficaSoggettiContestazioniEliminati ?? [],
        anagraficaSoggettiDocumenti: payload?.anagraficaSoggettiDocumenti ?? [],
        anagraficaSoggettiDocumentiEliminati: payload?.anagraficaSoggettiDocumentiEliminati ?? [],
        anagraficaSoggettiFormazione: payload?.anagraficaSoggettiFormazione ?? [],
        anagraficaSoggettiFormazioneEliminati: payload?.anagraficaSoggettiFormazioneEliminati,
        anagraficaSoggettiQualifica: payload?.anagraficaSoggettiQualifica ?? [],
        anagraficaSoggettiQualificaEliminati: payload?.anagraficaSoggettiQualificaEliminati ?? [],
        anagraficaSoggettiSchedaSanitaria: payload?.anagraficaSoggettiSchedaSanitaria ?? [],
        anagraficaSoggettiSchedaSanitariaEliminati: payload?.anagraficaSoggettiSchedaSanitariaEliminati ?? [],
        anagraficaSoggettiSchedeProfessionali: payload?.anagraficaSoggettiSchedeProfessionali ?? []
      }

      state.anagraficaSoggettoEstesa = reworkedPayload;

      type AnagraficaSoggettoExtendedIds = "idFormazione"
        | "idFormazione"
        | "idQualifica"
        | "idQualifica"
        | "idUnitaOperativa"
        | "idDitta";
      type AnagraficaSoggettoExtendedKeys = "anagraficaSoggettiFormazione"
        | "anagraficaSoggettiFormazioneEliminati"
        | "anagraficaSoggettiQualifica"
        | "anagraficaSoggettiQualificaEliminati"
        | "anagraficaSoggettiUnitaOperative"
        | "ditteAnagraficaSoggetti";

      const createIdSet = <T extends Record<string, any>>(obj: T[], id: AnagraficaSoggettoExtendedIds): number[] => {
        const retval: number[] = [];

        obj?.forEach((elemObject) => {
          if (id in elemObject)
            retval.push(elemObject[id] as number);
        });

        return retval;
      }

      const extendeds = [
        { nome: "anagraficaSoggettiFormazione", id: "idFormazione" },
        { nome: "anagraficaSoggettiFormazioneEliminati", id: "idFormazione" },
        { nome: "anagraficaSoggettiQualifica", id: "idQualifica" },
        { nome: "anagraficaSoggettiQualificaEliminati", id: "idQualifica" },
        { nome: "anagraficaSoggettiUnitaOperative", id: "idUnitaOperativa" },
        { nome: "ditteAnagraficaSoggetti", id: "idDitta" },
      ];

      extendeds.forEach(extName => {
        state.idSet[extName.nome] = createIdSet(
          payload[extName.nome as AnagraficaSoggettoExtendedKeys] as Record<string, any>[],
          extName.id as AnagraficaSoggettoExtendedIds
        );
      });
    })

    // fetchNotExtendedByIdAndDate
    builder.addCase(fetchNotExtendedByIdAndDate.pending, (state) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
    })
    builder.addCase(fetchNotExtendedByIdAndDate.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggetti = StatusEnum.Failed;
      }
      state.anagraficaSoggetto = null;
    })
    builder.addCase(fetchNotExtendedByIdAndDate.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettoCurrentData>) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Succeeded;
      state.anagraficaSoggetto = payload;
    })

    // fetchAllValidByIdStruttura
    builder.addCase(fetchAllValidByIdStruttura.pending, (state) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValidByIdStruttura.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggetti = StatusEnum.Failed;
      }
      state.validAnagraficaSoggetti = [];
    })
    builder.addCase(fetchAllValidByIdStruttura.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettoCurrentData[]>) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Succeeded;
      state.validAnagraficaSoggetti = payload ?? [];

      state.lookup = createLookup(payload, 'idAnagraficaSoggetto', ['nome', 'cognome']);
    })

    // fetchAllValidByIdStrutturaInForzaFilter
    builder.addCase(fetchAllValidByIdStrutturaInForzaFilter.pending, (state) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValidByIdStrutturaInForzaFilter.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggetti = StatusEnum.Failed;
      }
      state.validAnagraficaSoggetti = [];
    })
    builder.addCase(fetchAllValidByIdStrutturaInForzaFilter.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettoCurrentData[]>) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Succeeded;
      state.validAnagraficaSoggetti = payload ?? [];

      state.lookup = createLookup(payload, 'idAnagraficaSoggetto', ['nome', 'cognome']);
    })

    // fetchAllValidByFiltroAvanzato
    builder.addCase(fetchAllValidByFiltroAvanzato.pending, (state) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValidByFiltroAvanzato.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggetti = StatusEnum.Failed;
      }
      state.validAnagraficaSoggetti = [];
    })
    builder.addCase(fetchAllValidByFiltroAvanzato.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettoCurrentData[]>) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Succeeded;
      state.validAnagraficaSoggetti = payload ?? [];

      state.lookup = createLookup(payload, 'idAnagraficaSoggetto', ['nome', 'cognome']);
    })

    // fetchAllValidByIdStrutturaInForza
    builder.addCase(fetchAllValidByIdStrutturaInForza.pending, (state) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValidByIdStrutturaInForza.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggetti = StatusEnum.Failed;
      }
      state.validAnagraficaSoggetti = [];
    })
    builder.addCase(fetchAllValidByIdStrutturaInForza.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettoCurrentData[]>) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Succeeded;
      state.validAnagraficaSoggetti = payload ?? [];

      state.lookup = createLookup(payload, 'idAnagraficaSoggetto', ['nome', 'cognome']);
    })

    // findIdStrutturaAndLegge104
    builder.addCase(findIdStrutturaAndLegge104.pending, (state) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
    })
    builder.addCase(findIdStrutturaAndLegge104.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggetti = StatusEnum.Failed;
      }
      state.validAnagraficaSoggetti = [];
    })
    builder.addCase(findIdStrutturaAndLegge104.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettoCurrentData[]>) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Succeeded;
      state.validAnagraficaSoggetti = payload ?? [];
    })

    // fetchAllValidBetweenDatesPresaServizio
    builder.addCase(fetchAllValidBetweenDatesPresaServizio.pending, (state) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValidBetweenDatesPresaServizio.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggetti = StatusEnum.Failed;
      }
      state.validAnagraficaSoggetti = [];
    })
    builder.addCase(fetchAllValidBetweenDatesPresaServizio.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettoCurrentData[]>) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Succeeded;
      state.validAnagraficaSoggetti = payload ?? [];
    })

    // fetchAllValidBetweenDatesFineIncarico
    builder.addCase(fetchAllValidBetweenDatesFineIncarico.pending, (state) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValidBetweenDatesFineIncarico.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggetti = StatusEnum.Failed;
      }
      state.validAnagraficaSoggetti = [];
    })
    builder.addCase(fetchAllValidBetweenDatesFineIncarico.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettoCurrentData[]>) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Succeeded;
      state.validAnagraficaSoggetti = payload ?? [];
    })

    // fetchLookupByToken
    builder.addCase(fetchLookupByToken.pending, (state) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
    })
    builder.addCase(fetchLookupByToken.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggetti = StatusEnum.Failed;
      }
      state.lookup = {}
    })
    builder.addCase(fetchLookupByToken.fulfilled, (state, { payload }: PayloadAction<LookupAnagraficaSoggettoElem[]>) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Succeeded;
      state.lookup = createLookup(payload, 'idAnagraficaSoggetto', ['nome', 'cognome']);
    })

    // fetchAll
    builder.addCase(fetchAll.pending, (state) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggetti = StatusEnum.Failed;
      }
      state.validAnagraficaSoggetti = [];
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettoCurrentData[]>) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Succeeded;
      state.validAnagraficaSoggetti = payload ?? [];
    })

    // fetchAllByAbilitazione
    builder.addCase(fetchAllLookupByAbilitazione.pending, (state) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
    })
    builder.addCase(fetchAllLookupByAbilitazione.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggetti = StatusEnum.Failed;
      }
      state.lookup = {};
    })
    builder.addCase(fetchAllLookupByAbilitazione.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggetto[]>) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Succeeded;
      state.lookup = createLookup(payload, "idAnagraficaSoggetto", ["nome", "cognome"]);
    })

    // allLookup
    builder.addCase(allLookup.pending, (state) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
    })
    builder.addCase(allLookup.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggetti = StatusEnum.Failed;
      }
      state.lookup = {};
    })
    builder.addCase(allLookup.fulfilled, (state, { payload }: PayloadAction<LookupAnagraficaSoggettoElem[]>) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Succeeded;
      state.lookup = createLookup(payload, "idAnagraficaSoggetto", ["nome", "cognome"]);
    })

    // lookupByIds
    builder.addCase(lookupByIds.pending, (state) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
    })
    builder.addCase(lookupByIds.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggetti = StatusEnum.Failed;
      }
      state.lookup = {};
    })
    builder.addCase(lookupByIds.fulfilled, (state, { payload }: PayloadAction<LookupAnagraficaSoggettoElem[]>) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Succeeded;
      state.lookup = createLookup(payload, "idAnagraficaSoggetto", ["nome", "cognome"]);
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggetti = StatusEnum.Failed;
      }
      state.anagraficaSoggetto = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettoCurrentData>) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Succeeded;
      state.anagraficaSoggetto = payload;
      state.validAnagraficaSoggetti = state.validAnagraficaSoggetti.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggetti = StatusEnum.Failed;
      }
      state.anagraficaSoggetto = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<AnagraficaSoggettoCurrentData>) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Succeeded;
      state.validAnagraficaSoggetti = state.validAnagraficaSoggetti.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.anagraficaSoggetto = payload;
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAnagraficaSoggetti = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAnagraficaSoggetti = StatusEnum.Failed;
      }
      state.validAnagraficaSoggetti = [];
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<AnagraficaSoggettoCurrentDataKeys>[] }>) => {
      state.statusValidAnagraficaSoggetti = StatusEnum.Succeeded;
      state.validAnagraficaSoggetti = state.validAnagraficaSoggetti.filter(el => payload.ids.some(idObj => el[idObj.name] !== idObj.id));
    })
  }
});

export const { reset, resetError, resetLegenda, resetLookup } = anagraficaSoggettiSlice.actions;
export default anagraficaSoggettiSlice.reducer;