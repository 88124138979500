import { useEffect, useMemo, useState } from "react";
import { Column } from "@material-table/core";
import { useTranslation } from "react-i18next";
import {
  allEntratiFields,
  allUscitiFields,
  all104Fields,
} from "../../../models/EntratiUsciti104";
import { AnagraficaSoggettoCurrentData } from "../../../models/AnagraficaSoggetti";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
// import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import Paper from "@material-ui/core/Paper";
import {
  AppBar,
  Box,
  createStyles,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
} from "@material-ui/core";
import SimpleIdSelects from "../selects/SimpleIdSelects";
import { lookup as fetchStruttureLookup, reset as resetStrutture } from "../../../store/slices/struttureSlice";
import {
  fetchAllValidBetweenDatesPresaServizio,
  fetchAllValidBetweenDatesFineIncarico,
  findIdStrutturaAndLegge104,
  reset as resetAnagraficaSoggetti
} from '../../../store/slices/anagraficaSoggettiSlice';

import { useHistory, Switch, Route, Redirect } from "react-router-dom";
import { componentTabsPath } from "../../../utils/innerFuncPaths";
import { Fields } from "../../../models/Fields";
import ReadOnlyMaterialTable from '../tables/ReadOnly/ReadOnlyMaterialTable'
import { entratiUsciti104Path, notFoundPath } from "../../../utils/utilconst";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { PDFExtraData } from "../../../models/Utils";
import { getDateDDMMYYYY } from "../../../utils/utilfunctions";
import i18n from "../../../i18n";
import { it, enGB } from "date-fns/locale";
import { FixedProps } from "../../../utils/data.types";
import TabPanel from "../tabs/TabPanel/TabPanel";
import { calculateMTableHeight } from "../../../utils/styleconst";

const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    appbar: {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    },
    indicator: {
      backgroundColor: "#fff",
    },
  }),
);

function isUrlOnUpdate(url: string) {
  return url === entratiUsciti104Path + componentTabsPath;
}

enum TabIndex {
  ENTRATI,
  USCITI,
  LEGGE_104,
};

const EntratiUsciti104W = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const logoUri = useAppSelector((state) => state.authInfo.logoUri);
  // const abilitazione = useAppSelector((state) => getAbilitazione(state, entratiUsciti104Path));
  // const title = t("inOut104Title");
  const classes = useStyles1();

  const strSelectName = useMemo(() => t("structuresTitle"), [t]);
  const strSelectLabel = useMemo(() => t("structureSelect"), [t]);
  const fromSelectName = useMemo(() => t("startDateTitle"), [t]);
  const fromSelectLabel = useMemo(() => t("startDateSelect"), [t]);
  const toSelectName = useMemo(() => t("endDateTitle"), [t]);
  const toSelectLabel = useMemo(() => t("endDateSelect"), [t]);

  const idStrutturaConst: string = "idStruttura";
  const daConst: string = "da";
  const aConst: string = "a";

  const tabsWithDate: number[] = [TabIndex.ENTRATI, TabIndex.USCITI, TabIndex.LEGGE_104];

  const [states, setStates] = useState<{
    [selectName: string]: number | string | null;
  }>({
    [strSelectName]: "",
  });

  const fixedProps = useMemo(() => {
    const tempFixedProps: FixedProps = {
      idStruttura: states[strSelectName],
      da: null,
      a: null,
    };

    if (states[fromSelectName]) tempFixedProps.da = states[fromSelectName];
    if (states[toSelectName]) tempFixedProps.a = states[toSelectName];

    return tempFixedProps;
  }, [fromSelectName, states, strSelectName, toSelectName]);

  const [isFixedPropsChanged, setIsFixedPropsChanged] = useState(false)
  useEffect(() => {
    setIsFixedPropsChanged(true);
  }, [fixedProps.idStruttura, fixedProps.da, fixedProps.a]);

  const lookupStr = useAppSelector((state) => state.strutture.lookup);

  /**
   * Tabs
   */
  const [tabValue, setTabValue] = useState<TabIndex>(TabIndex.ENTRATI);
  const handleTabValueChange = (event: React.ChangeEvent<{}>, newValue: TabIndex) => {
    setTabValue(newValue);
  };
  const handleTabChange = (index: TabIndex) => {
    return {
      id: 'tab-' + index,
      'aria-controls': 'tabpanel-' + index,
    };
  }

  useEffect(() => {
    dispatch(fetchStruttureLookup());
  }, [dispatch]);

  /**
   * Data fetch
   */
  useEffect(() => {
    if (fixedProps[idStrutturaConst] && fixedProps[daConst] && fixedProps[aConst] && tabValue === TabIndex.ENTRATI) {
      isFixedPropsChanged && dispatch(fetchAllValidBetweenDatesPresaServizio({
        idStruttura: Number(fixedProps[idStrutturaConst]),
        from: fixedProps[daConst].toString(),
        to: fixedProps[aConst].toString()
      }));
    } else if (fixedProps[idStrutturaConst] && fixedProps[daConst] && fixedProps[aConst] && tabValue === TabIndex.USCITI) {
      isFixedPropsChanged && dispatch(fetchAllValidBetweenDatesFineIncarico({
        idStruttura: Number(fixedProps[idStrutturaConst]),
        from: fixedProps[daConst].toString(),
        to: fixedProps[aConst].toString()
      }));
    } else if (fixedProps[idStrutturaConst] && (!fixedProps[daConst] && !fixedProps[aConst] && tabValue === TabIndex.LEGGE_104)) {
      isFixedPropsChanged && dispatch(findIdStrutturaAndLegge104({ idStruttura: Number(fixedProps[idStrutturaConst]) }));
    }
  }, [strSelectName, dispatch, fromSelectName, toSelectName, tabValue, fixedProps, isFixedPropsChanged]);

  /**
   * Column creation
   */
  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>([]);
  const [columns, setColumns] = useState<Array<Column<AnagraficaSoggettoCurrentData>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<AnagraficaSoggettoCurrentData> = {
          title: f.titleKey ? t(f.titleKey) : "",
          field: f.field ? f.field : "",
          removable: f.removable ?? !f.required,
          editable: "never",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        };

        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = false;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        /*+++ lookups */

        /*+++*/
        return obj;
      })
    );
  }, [t, allFieldsState, excludedFieldInTable]);

  const errorBE = useAppSelector((state) => state.anagraficaSoggetti.error);
  const validAnagraficheSoggetti = useAppSelector((state) => state.anagraficaSoggetti.validAnagraficaSoggetti);
  const statusValidAnagraficheSoggetti = useAppSelector((state) => state.anagraficaSoggetti.statusValidAnagraficaSoggetti);

  /**
   * Data filter based on selected tab
   */
  const [data, setData] = useState<Array<AnagraficaSoggettoCurrentData>>([]);
  useEffect(() => {
    if (tabValue === TabIndex.LEGGE_104) {
      setData(validAnagraficheSoggetti.filter(AS => {
        return AS.legge104Numero > 0;
      }))
    } else {
      setData(validAnagraficheSoggetti);
    }
  }, [tabValue, validAnagraficheSoggetti]);

  const handleDateChange = (d: Date | null, name?: string) => {
    let fieldDate: string = '';
    if (d) {
      let month = (d.getMonth() + 1).toString().padStart(2, '0');
      let day = d.getDate().toString().padStart(2, '0');
      fieldDate = d.getFullYear() + "-" + month + "-" + day + "T00:00:00";
    }
    if (name)
      setStates(prev => { return { ...prev, [name]: fieldDate } });
  };

  /**
   * update fields based on tabValue
   */
  useEffect(() => {
    switch (tabValue) {
      case TabIndex.ENTRATI:
        setAllFieldsState(allEntratiFields);
        break;
      case TabIndex.USCITI:
        setAllFieldsState(allUscitiFields);
        break;
      case TabIndex.LEGGE_104:
        setAllFieldsState(all104Fields);
        break;
    }
  }, [fromSelectName, tabValue, toSelectName]);

  const [exportDataExtra, setExportDataExtra] = useState<PDFExtraData>();
  useEffect(() => {
    const headTitle: string[] = [];
    const headValue: any[] = [];

    const _idStruttura = fixedProps['idStruttura'] as number;
    const _da = fixedProps[daConst] as string;
    const _a = fixedProps[aConst] as string;

    /**
     * init title table
     */
    switch (tabValue) {
      case TabIndex.LEGGE_104:
        headTitle.push(t("structureTitle"));
        headValue.push(_idStruttura ? lookupStr[_idStruttura] : null);
        break;
      case TabIndex.USCITI:
      case TabIndex.ENTRATI:
        headTitle.push(t("structureTitle"), t("startDateTitle"), t("endDateTitle"));
        headValue.push(
          _idStruttura ? lookupStr[_idStruttura] : null,
          _da ? getDateDDMMYYYY(new Date(_da)) : null,
          _a ? getDateDDMMYYYY(new Date(_a)) : null,
        );
        break;
    }

    setExportDataExtra(() => {
      const _idStruttura = fixedProps['idStruttura'] as number;
      const _da = fixedProps[daConst] as string;
      const _a = fixedProps[aConst] as string;

      return {
        head: {
          title: headTitle,
          value: [
            _idStruttura ? lookupStr[_idStruttura] : null,
            _da ? getDateDDMMYYYY(new Date(_da)) : null,
            _a ? getDateDDMMYYYY(new Date(_a)) : null,
          ]
        }
      }
    });
  }, [fixedProps, lookupStr, t, tabValue]);

  useEffect(() => {
    return () => {
      dispatch(resetStrutture());
      dispatch(resetAnagraficaSoggetti());
    }
  }, [dispatch]);

  return (
    <>
      <Paper elevation={2}>
        <Box p={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <SimpleIdSelects
                selectsArray={[
                  {
                    name: strSelectName,
                    lookup: lookupStr,
                    label: strSelectLabel,
                    disabled: isUrlOnUpdate(history.location.pathname),
                    colsNum: 12,
                  },
                ]}
                states={states}
                setStates={setStates}
              />
            </Grid>
            {(states[strSelectName] && tabsWithDate.includes(tabValue) &&
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === "it-IT" || i18n.language === "it" ? it : enGB}>
                  <DatePicker
                    label={fromSelectLabel}
                    format={"dd/MM/yyyy"}
                    onChange={(e) => handleDateChange(e, fromSelectName)}
                    okLabel={t('insertLabel')}
                    clearLabel={t('clearLabel')}
                    cancelLabel={t('cancelLabel')}
                    clearable={true}
                    disabled={isUrlOnUpdate(history.location.pathname)}
                    value={states[fromSelectName] ? states[fromSelectName] : null}
                    autoOk={true}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            )}
            {(states[strSelectName] && states[fromSelectName] && tabsWithDate.includes(tabValue) &&
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === "it-IT" || i18n.language === "it" ? it : enGB}>
                  <DatePicker
                    minDate={states[fromSelectName] ? states[fromSelectName] : null}
                    label={toSelectLabel}
                    format={"dd/MM/yyyy"}
                    onChange={(e) => handleDateChange(e, toSelectName)}
                    okLabel={t('insertLabel')}
                    clearLabel={t('clearLabel')}
                    cancelLabel={t('cancelLabel')}
                    clearable={true}
                    disabled={isUrlOnUpdate(history.location.pathname)}
                    value={states[toSelectName] ? states[toSelectName] : null}
                    autoOk={true}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper>
      <Box marginTop={2}>
        {
          states[strSelectName] && states[fromSelectName] && states[toSelectName] ? (
            <>
              <Switch>
                <Route path={entratiUsciti104Path} exact>
                  <>
                    <AppBar position="static" className={classes.appbar}>
                      <Tabs value={tabValue} onChange={handleTabValueChange} classes={{ indicator: classes.indicator }}>
                        <Tab label={t("insTabLabel")} {...handleTabChange(TabIndex.ENTRATI)} />
                        <Tab label={t("outsTabLabel")} {...handleTabChange(TabIndex.USCITI)} />
                        <Tab label={t("104TabLabel")} {...handleTabChange(TabIndex.LEGGE_104)} />
                      </Tabs>
                    </AppBar>
                    {/* Entrati */}
                    <TabPanel value={tabValue} index={TabIndex.ENTRATI}>
                      {(!states[fromSelectName] || !states[toSelectName])
                        ? <Box p={4}>
                          <Typography align="center" variant="h5">
                            {!states[fromSelectName]
                              ? fromSelectLabel
                              : toSelectLabel
                            }
                          </Typography>
                        </Box>
                        : <ReadOnlyMaterialTable
                          title={t("insTitle")}
                          columns={columns}
                          data={data}
                          fetchAllValid={fetchAllValidBetweenDatesPresaServizio}
                          statusValid={statusValidAnagraficheSoggetti}
                          errorBE={errorBE}
                          logoUri={logoUri}
                          fixedProps={fixedProps}
                          localizedDatePicker={true}
                          exportDataExtra={exportDataExtra}
                          extraOptions={{
                            minBodyHeight: calculateMTableHeight(0, true, true),
                            maxBodyHeight: calculateMTableHeight(0, true, true),
                          }}
                        />
                      }
                    </TabPanel>
                    {/* Usciti */}
                    <TabPanel value={tabValue} index={TabIndex.USCITI}>
                      {(!states[fromSelectName] || !states[toSelectName])
                        ? <Box p={4}>
                          <Typography align="center" variant="h5">
                            {!states[fromSelectName]
                              ? fromSelectLabel
                              : toSelectLabel
                            }
                          </Typography>
                        </Box>
                        : <ReadOnlyMaterialTable
                          title={t("outsTitle")}
                          columns={columns}
                          data={data}
                          fetchAllValid={fetchAllValidBetweenDatesFineIncarico}
                          statusValid={statusValidAnagraficheSoggetti}
                          errorBE={errorBE}
                          logoUri={logoUri}
                          fixedProps={fixedProps}
                          localizedDatePicker={true}
                          exportDataExtra={exportDataExtra}
                          extraOptions={{
                            minBodyHeight: calculateMTableHeight(0, true, true),
                            maxBodyHeight: calculateMTableHeight(0, true, true),
                          }}
                        />
                      }
                    </TabPanel>
                    {/* Numero Legge 104 */}
                    <TabPanel value={tabValue} index={TabIndex.LEGGE_104}>
                      <ReadOnlyMaterialTable
                        title={t("law104NumberTitle")}
                        columns={columns}
                        data={data}
                        fetchAllValid={findIdStrutturaAndLegge104}
                        statusValid={statusValidAnagraficheSoggetti}
                        errorBE={errorBE}
                        logoUri={logoUri}
                        fixedProps={fixedProps}
                        localizedDatePicker={true}
                        exportDataExtra={exportDataExtra}
                        extraOptions={{
                          minBodyHeight: calculateMTableHeight(0, true, true),
                          maxBodyHeight: calculateMTableHeight(0, true, true),
                        }}
                      />
                    </TabPanel>
                  </>
                </Route>
                <Route>
                  <Redirect to={notFoundPath} />
                </Route>
              </Switch>
            </>
          ) : (
            <Switch>
              <Route path={entratiUsciti104Path} exact>
                <Paper elevation={2}>
                  <Box p={4}>
                    <Typography align="center" variant="h5">
                      {!states[strSelectName]
                        ? strSelectLabel
                        : !states[fromSelectName]
                          ? fromSelectLabel
                          : toSelectLabel}
                    </Typography>
                  </Box>
                </Paper>
              </Route>
              <Route><Redirect to={entratiUsciti104Path} /></Route>
            </Switch>
          )}
      </Box>
    </>
  );
};
export default EntratiUsciti104W;
