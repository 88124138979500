import { Fields } from "./Fields";

export type PresenzeUOKeys = keyof PresenzeUO;

export const elementIdProps: PresenzeUOKeys[] = [];
export const elementRenderProps: PresenzeUOKeys[] = [];

export const allPresenzeUOFields: Fields[] = [
  {
    field: "presenzaData", titleKey: "attendanceDateParam", type: "date", required: false, show: true, sort: 'asc'
  },

  {
    field: "nominativo", titleKey: "registryTitle", required: false, show: true,
    render: (rowData: PresenzeUO) => {
      return rowData.cognome + ' ' + rowData.nome;
    }
  },

  {
    field: "turnoDescrizione", titleKey: "shiftParam", required: false, show: true, defaultValue: '',
  },

  {
    field: "turnoAssenzaDescrizione", titleKey: "absenceShiftParam", required: false, show: true, defaultValue: '',
  },

  {
    field: "unitaOperativaNome", titleKey: "operationalUnitMembershipParam", required: false, show: true,
  },

  {
    field: "qualificaDescrizioneBreve", titleKey: "qualificationParam", required: false, show: true, defaultValue: '', defaultGroupOrder: 0,
  },

  {
    field: "oreTurno", titleKey: "workShiftParam", required: false, show: true,
  },

  {
    field: "motivo", titleKey: "reasonParam", required: false, show: true, defaultValue: ''
  },
];

export const allUOEsternaFields: Fields[] = [
  {
    field: "presenzaData", titleKey: "attendanceDateParam", type: "date", required: false, show: true,
  },

  {
    field: "nominativo", titleKey: "registryTitle", required: false, show: true,
    render: (rowData: PresenzeUO) => {
      return rowData.cognome + ' ' + rowData.nome;
    }
  },

  {
    field: "unitaOperativaNome", titleKey: "operationalUnitMembershipParam", required: false, show: true,
  },

  {
    field: "qualificaDescrizioneBreve", titleKey: "qualificationParam", required: false, show: true, defaultValue: '',
  },

  {
    field: "turnoDescrizione", titleKey: "shiftParam", required: false, show: true, defaultValue: '',
  },

  {
    field: "turnoAssenzaDescrizione", titleKey: "absenceShiftParam", required: false, show: true, defaultValue: '',
  },

  {
    field: "oreTurno", titleKey: "workShiftParam", required: false, show: true,
  },

  {
    field: "motivo", titleKey: "reasonParam", required: false, show: true, defaultValue: ''
  },
];

export const allRiepilogoFields: Fields[] = [
  {
    field: "qualificaDescrizione", titleKey: "qualificationParam", required: false, removable: false, show: true, defaultGroupOrder: 0
  },
  {
    field: "nominativo", titleKey: "nominativeParam", required: false, removable: false, show: true,
  },

  /**
   * Le colonne dei giorni vengono create dinamicamente.
   */
]

export const allPrestazioneEsterneUOFields: Fields[] = [
  {
    field: "presenzaData", titleKey: "attendanceDateParam", type: "date", required: false, show: true,
  },

  {
    field: "nominativo", titleKey: "registryTitle", required: false, show: true,
    render: (rowData: PresenzeUO) => {
      return rowData.cognome + ' ' + rowData.nome;
    }
  },

  {
    field: "turnoDescrizione", titleKey: "shiftParam", required: false, show: true, defaultValue: '',
  },

  {
    field: "turnoAssenzaDescrizione", titleKey: "absenceShiftParam", required: false, show: true, defaultValue: '',
  },

  {
    field: "unitaOperativaNome", titleKey: "operationalUnitExternalParam", required: false, show: true,
  },

  {
    field: "qualificaDescrizioneBreve", titleKey: "qualificationParam", required: false, show: true, defaultValue: '',
  },

  {
    field: "oreTurno", titleKey: "workShiftParam", required: false, show: true,
  },

  {
    field: "approvazioneRichiestaMotivo", titleKey: "reasonParam", required: false, show: true, defaultValue: ''
  },
];

export interface PresenzeUO {
  presenzaData: string;
  nominativo?: string;
  cognome: string;
  nome: string;
  turnoDescrizione: string;
  turnoDescrizioneBreve: string;
  turnoAssenzaDescrizione: string;
  turnoAssenzaDescrizioneBreve: string;
  unitaOperativaNome?: string;
  qualificaDescrizione: string;
  qualificaDescrizioneBreve: string;
  oreTurno: number | string;
  approvazioneRichiestaMotivo?: string;
}
